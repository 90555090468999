//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { FileUploader } from '@/shared/firebase/file-uploader';
import Errors from '@/shared/error/errors';

export default {
  name: 'app-image-upload',
  props: ['path', 'value', 'schema', 'max'],

  data() {
    return {
      // dialogImageUrl: '../../../public/images/defaultAvatar.svg',
      dialogImageUrl: null,
      dialogVisible: false,
      // fileList: (this.value || []).map((item) => ({
      //   ...item,
      //   url: item.publicUrl,
      // })),
      fileList: this.value,
      loading: false,
    };
  },

  computed: {
    isFull() {
      const hasInputReference = !!this.$refs.files;

      return (
        (this.max &&
          (hasInputReference &&
            this.$refs.files.uploadFiles.length >=
              this.max)) ||
        (!hasInputReference &&
          (this.value || []).length >= this.max)
      );
    },

    accept() {
      return 'image/*';
    },
  },

  methods: {
    async uploadFromRequest(request) {
      console.log(request);
      // //debugger
      // this.loading = true;
      // return FileUploader.uploadFromRequest(
      //   this.path,
      //   request,
      //   this.schema,
      // );
    },

    onSuccess(file) {
      if (!file) {
        return;
      }

      this.$emit('input', [...(this.value || []), file]);
      this.loading = false;
    },

    onError(error) {
      Errors.showMessage(error);
      this.loading = false;
    },

    onRemove(file) {
      if (!file) {
        return;
      }

      const id = file.response ? file.response.id : file.id;

      this.$emit(
        'input',
        (this.value || []).filter(
          (item) => item.id !== id,
        ),
      );
    },

    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },


    handleAvatarSuccess(res, file) {
        this.dialogImageUrl = URL.createObjectURL(file.raw);
      },
      beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024 < 2;

        // if (!isJPG) {
        //   // this.$message.error('Avatar picture must be JPG format!');
        // }
        // if (!isLt2M) {
        //   this.$message.error('Avatar picture size can not exceed 2MB!');
        // }
        return isJPG && isLt2M;
      }
  },
};
