import tripListStore from '@/modules/trip/trip-list-store';
import tripViewStore from '@/modules/trip/trip-view-store';
import tripFormStore from '@/modules/trip/trip-form-store';
import tripDestroyStore from '@/modules/trip/trip-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: tripDestroyStore,
    form: tripFormStore,
    list: tripListStore,
    view: tripViewStore,
  },
};
