// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDgrVwgswxqsFsvyNVGC7MAPm2xdBtRTHo",
  authDomain: "rahal-7e911.firebaseapp.com",
  databaseURL: "https://rahal-7e911-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "rahal-7e911",
  storageBucket: "rahal-7e911.appspot.com",
  messagingSenderId: "288521883799",
  appId: "1:288521883799:web:73ce74e2d781965ed319d2",
  measurementId: "G-7ZN6XV11P7"
};
const REGION = 'europe-west1'

// Cloud Functions
const backendUrl = `https://${REGION}-${
  firebaseConfig.projectId
}.cloudfunctions.net/api`;

// const backendUrlAuditLog = `https://${REGION}-${
//   firebaseConfig.projectId
// }.cloudfunctions.net/auditLog`;

const backendUrlAuth = `https://${REGION}-${
  firebaseConfig.projectId
}.cloudfunctions.net/auth`;

const backendUrlIam = `https://${REGION}-${
  firebaseConfig.projectId
}.cloudfunctions.net/iam`;

// const backendUrlBranch = `https://${REGION}-${
//   firebaseConfig.projectId
// }.cloudfunctions.net/branch`;

const backendUrlCity = `https://${REGION}-${
  firebaseConfig.projectId
}.cloudfunctions.net/city`;

const backendUrlClient = `https://${REGION}-${
  firebaseConfig.projectId
}.cloudfunctions.net/client`;

const backendUrlCompany = `https://${REGION}-${
  firebaseConfig.projectId
}.cloudfunctions.net/company`;

const backendUrlGuide = `https://${REGION}-${
  firebaseConfig.projectId
}.cloudfunctions.net/guide`;

const backendUrlNotification = `https://${REGION}-${
  firebaseConfig.projectId
}.cloudfunctions.net/notification`;

const backendUrlPlace = `https://${REGION}-${
  firebaseConfig.projectId
}.cloudfunctions.net/place`;

const backendUrlPlan = `https://${REGION}-${
  firebaseConfig.projectId
}.cloudfunctions.net/plan`;

const backendUrlRegion = `https://${REGION}-${
  firebaseConfig.projectId
}.cloudfunctions.net/region`;

const backendUrlSettings = `https://${REGION}-${
  firebaseConfig.projectId
}.cloudfunctions.net/settings`;

const backendUrlTransaction = `https://${REGION}-${
  firebaseConfig.projectId
}.cloudfunctions.net/transaction`;

const backendUrlTrip = `https://${REGION}-${
  firebaseConfig.projectId
}.cloudfunctions.net/trip`;

const backendUrlOffer = `https://${REGION}-${
  firebaseConfig.projectId
}.cloudfunctions.net/offer`;

const backendUrlWallet = `https://${REGION}-${
  firebaseConfig.projectId
}.cloudfunctions.net/wallet`;

const backendUrlPayment = `https://${REGION}-${
  firebaseConfig.projectId
}.cloudfunctions.net/payment`;

const backendUrlRequest = `https://${REGION}-${
  firebaseConfig.projectId
}.cloudfunctions.net/request`;


// App Engine
// const backendUrl = `<insert app engine url here>`;
// const backendUrl = `https://${REGION}-saryah-31e36.cloudfunctions.net/api`;

export default {
  firebaseConfig,
  backendUrl,
  // backendUrlAuditLog,
  backendUrlAuth,
  backendUrlIam,
  // backendUrlBranch,
  backendUrlCity,
  backendUrlClient,
  backendUrlCompany,
  backendUrlGuide,
  backendUrlNotification,
  backendUrlPlace,
  backendUrlPlan,
  backendUrlRegion,
  backendUrlSettings,
  backendUrlTransaction,
  backendUrlTrip,
  backendUrlOffer,
  backendUrlWallet,
  backendUrlPayment,
  backendUrlRequest
};

