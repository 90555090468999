// import Layout from '@/modules/layout/layout/dashboard/DashboardLayout';
import Layout from '@/modules/layout/components/layout';
// import Permissions from '@/security/permissions';
import { i18n } from '@/i18n';

const TourGuideListPage = () =>
  import('@/modules/tour-guide/components/tour-guide-list-page.vue');


export default [
  {
    name: '',
    path: '/:lang?',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'tourGuide',
        path: 'tour-guide',
        component: TourGuideListPage,
        meta: {
          auth: true,
        //   permission: Permissions.values.cycleView,
        //   title: i18n('metaTitle.cycle')
        // title: 'Saryah | Cycle'
        title: i18n('metaTitle.admins')

        },
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },

    ],
  },
];
