import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';
import { i18n } from '@/vueI18n';

const complaintListPage = () =>
  import('@/modules/complaint/components/complaint-list-page.vue');
export default [
  {
    name: '',
    path: '/:lang?',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'complaint',
        path: 'complaint',
        component: complaintListPage,
        meta: {
          auth: true,
          permission: Permissions.values.customerRead,
        },
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('metaTitle.complaint')
          next();
        },
      },
    ],
  },
];
