// import Layout from '@/modules/layout/layout/dashboard/DashboardLayout';
import Layout from '@/modules/layout/components/layout';
// import Permissions from '@/security/permissions';
// import { i18n } from '@/i18n';

const CycleListPage = () =>
  import('@/modules/cycle/components/cycle-list-page.vue');
// const PlanFormPage = () =>
//   import('@/modules/plan/components/plan-form-page.vue');
// const PlanEditPage = () =>
//   import('@/modules/plan/components/plan-edit-page.vue');
// const PlanReorderPage = () =>
//   import('@/modules/plan/components/plan-reorder-page.vue');
// const PlanViewPage = () =>
//   import('@/modules/plan/components/plan-view-page.vue');

export default [
  {
    name: '',
    path: '/:lang?',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'cycle',
        path: 'cycle',
        component: CycleListPage,
        meta: {
          auth: true,
        //   permission: Permissions.values.cycleView,
        //   title: i18n('metaTitle.cycle')
        title: 'Saryah | Cycle'

        },
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },
    //   {
    //     name: 'planNew',
    //     path: '/plan/new',
    //     component: PlanFormPage,
    //     meta: {
    //       auth: true,
    //       permission: Permissions.values.planCreate,
    //       title: i18n('metaTitle.newPlan') 
    //     },
    //     beforeEnter: (to, from, next) => {
    //       document.title = to.meta.title
    //       next()
    //     }
    //   },
    //   {
    //     name: 'planEdit',
    //     path: '/plan/:id/edit',
    //     component: PlanFormPage,
    //     meta: {
    //       auth: true,
    //       permission: Permissions.values.planEdit,          
    //       title: i18n('metaTitle.editPlan') 
    //     },
    //     props: true,
    //     beforeEnter: (to, from, next) => {
    //       document.title = to.meta.title
    //       next()
    //     }
    //   },
    ],
  },
];
