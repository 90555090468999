import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-city';

export class CityService {
  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CITY_UPDATE(
          $id: String!
          $data: CityInput!
        ) {
          cityUpdate(id: $id, data: $data) {
            id
          }
        }
      `,

      variables: {
        id,
        data,
      },
    });

    return response.data.cityUpdate;
  }

  static async destroyAll(ids) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CITY_DESTROY($ids: [String!]!) {
          cityDestroy(ids: $ids)
        }
      `,

      variables: {
        ids,
      },
    });

    return response.data.cityDestroy;
  }

  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CITY_CREATE($data: CityInput!) {
          cityCreate(data: $data) {
            id
          }
        }
      `,

      variables: {
        data,
      },
    });

    return response.data.cityCreate;
  }

  static async import(values, importHash) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CITY_IMPORT(
          $data: CityInput!
          $importHash: String!
        ) {
          cityImport(data: $data, importHash: $importHash)
        }
      `,

      variables: {
        data: values,
        importHash,
      },
    });

    return response.data.cityImport;
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query CITY_FIND($id: String!) {
          cityFind(id: $id) {
            id
            region_id
            name {
              en
              ar
            }
            center {
              latitude
              longitude
            }

            createdBy
            updatedBy
            createdAt
            updatedAt
          }
        }
      `,

      variables: {
        id,
      },
    });

    return response.data.cityFind;
  }

  static async list(filter, orderBy, limit, offset) {
    const response = await graphqlClient.query({
      query: gql`
        query CITY_LIST(
          $filter: CityFilterInput
          $orderBy: CityOrderByEnum
          $limit: Int
          $offset: Int
        ) {
          cityList(
            filter: $filter
            orderBy: $orderBy
            limit: $limit
            offset: $offset
          ) {
            count
            rows {
              id
              region_id
              name {
                en
                ar
              }
              center {
                latitude
                longitude
              }

              createdBy
              updatedBy
              createdAt
              updatedAt
            }
          }
        }
      `,

      variables: {
        filter,
        orderBy,
        limit,
        offset,
      },
    });
    return response.data.cityList;
  }


  static async listAutocomplete(query, limit) {
    const response = await graphqlClient.query({
      query: gql`
        query CITY_AUTOCOMPLETE(
          $query: String
          $limit: Int
        ) {
          cityAutocomplete(query: $query, limit: $limit) {
            id
            label
          }
        }
      `,

      variables: {
        query,
        limit,
      },
    });

    return response.data.cityAutocomplete;
  }
 
}
