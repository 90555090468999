import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/iam/permission-checker';

export class PlanPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.planRead,
    );
    this.import = permissionChecker.match(
      Permissions.values.planImport,
    );
    this.planAutocomplete = permissionChecker.match(
      Permissions.values.planAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.planCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.planEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.planDestroy,
    );
  }
}
