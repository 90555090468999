// import Layout from '@/modules/layout/layout/dashboard/DashboardLayout';
import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';
import { i18n } from '@/i18n';

// const BranchListPage = () =>
//   import('@/modules/branch/components/branch-list-page.vue');
  const BranchListPage = () =>
  import('@/modules/branch/components/branch-list-page.vue');
// const BranchFormPage = () =>
//   import('@/modules/branch/components/branch-form-page.vue');
// const BranchEditPage = () =>
//   import('@/modules/branch/components/branch-edit-page.vue');
// const BranchReorderPage = () =>
//   import('@/modules/branch/components/branch-reorder-page.vue');
// const BranchViewPage = () =>
//   import('@/modules/branch/components/branch-view-page.vue');
const BranchViewPage = () =>
  import('@/modules/branch/components/branch-view-page.vue');

export default [
  {
    name: '',
    path: '/:lang?',
    component: Layout,
    // meta: { auth: true },
    children: [
      {
        name: 'branch',
        path: 'branch',
        component: BranchListPage,
        meta: {
          auth: true,
          permission: Permissions.values.branchView,
          title: i18n('metaTitle.branch')

        },
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },
      // {
      //   name: 'branchNew',
      //   path: '/branch/new',
      //   component: BranchFormPage,
      //   meta: {
      //     auth: true,
      //     permission: Permissions.values.branchCreate,
      //     title: i18n('metaTitle.newBranch') 
      //   },
      //   beforeEnter: (to, from, next) => {
      //     document.title = to.meta.title
      //     next()
      //   }
      // },
      // {
      //   name: 'branchEdit',
      //   path: '/branch/:id/edit',
      //   component: BranchFormPage,
      //   meta: {
      //     auth: true,
      //     permission: Permissions.values.branchEdit,          
      //     title: i18n('metaTitle.editBranch') 
      //   },
      //   props: true,
      //   beforeEnter: (to, from, next) => {
      //     document.title = to.meta.title
      //     next()
      //   }
      // },
      // {
      //   name: 'branchReorder',
      //   path: '/branch/:id/reorder',
      //   component: BranchReorderPage,
      //   meta: {
      //     auth: true,
      //     permission: Permissions.values.branchEdit,          
      //     title: i18n('metaTitle.branchReorder') 
      //   },
      //   props: true,
      //   beforeEnter: (to, from, next) => {
      //     document.title = to.meta.title
      //     next()
      //   }
      // },
      {
        name: 'branchView',
        path: 'branch/:id',
        component: BranchViewPage,
        meta: {
          auth: true,
          permission: Permissions.values.branchView,
          title: i18n('metaTitle.viewBranch') 
        },
        props: true,
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },
    ],
  },
];
