import { NotificationModel } from '@/modules/notification/notification-model';

const { fields } = NotificationModel;

export default [
  fields.title,
  fields.message,
  fields.image,
  fields.name,
  fields.placeId,
];
