import { PlaceModel } from '@/modules/place/place-model';

const { fields } = PlaceModel;

export default [
  fields.lat,
  fields.lng,
  fields.radius,
  fields.color,
  fields.strokeColor,
  fields.strokeWidth,
  fields.fillColor,
  fields.placeName,
  fields.city,
  fields.country,
  fields.description,
  fields.placePictures,
  fields.placeType,
];
