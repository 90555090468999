import { PlanService } from '@/modules/plan/plan-service';
import Errors from '@/shared/error/errors';
// import planListExporterFields from '@/modules/plan/plan-list-exporter-fields';
// import Exporter from '@/shared/exporter/exporter';
import { firestoreAction, vuexfireMutations } from 'vuexfire';
import firebase from 'firebase/app';


const INITIAL_PAGE_SIZE = 10;

export default {
  namespaced: true,

  state: {
    rows: [],
    saryahPlans: [],
    companyPlans: [],
    count: 0,
    loading: false,
    filter: {},
    pagination: {},
    sorter: {},

    rowsSaryahPlans: [],
    countSaryahPlans: 0,
    saryahPlansFirstPage: true,
    saryahPlansLastPage: true,

    rowsCustomPlans: [],
    countCustomPlans: 0,
    customPlansFirstPage: true,
    customPlansLastPage: true,

    loadingSaryahPlans: false,
    loadingCustomPlans: false,
    lastRecordSaryah: {},
    firstRecordSaryah: {},

    lastRecordCustom: {},
    firstRecordCustom: {},

    disableNextCustom: true,
    disablePreviousCustom: true,
    disableNextSaryah: true,
    disablePreviousSaryah: true,

    table: null,
  },

  getters: {
    loading: (state) => state.loading,

    exportLoading: (state) => state.exportLoading,

    rows: (state) => state.rows || [],

    count: (state) => state.count,

    hasRows: (state, getters) => getters.count > 0,

    orderBy: (state) => {
      const sorter = state.sorter;

      if (!sorter) {
        return null;
      }

      if (!sorter.prop) {
        return null;
      }

      let direction =
        sorter.order === 'descending' ? 'DESC' : 'ASC';

      return `${sorter.prop}_${direction}`;
    },

    filter: (state) => state.filter,

    limit: (state) => {
      const pagination = state.pagination;

      if (!pagination || !pagination.pageSize) {
        return INITIAL_PAGE_SIZE;
      }

      return pagination.pageSize;
    },

    offset: (state) => {
      const pagination = state.pagination;

      if (!pagination || !pagination.pageSize) {
        return 0;
      }

      const currentPage = pagination.currentPage || 1;

      return (currentPage - 1) * pagination.pageSize;
    },

    pagination: (state, getters) => {
      return {
        ...state.pagination,
        total: getters.count,
        showSizeChanger: true,
      };
    },

    selectedRows: (state) => {
      return state.table ? state.table.selection : [];
    },
    saryahPlans: (state) => state.saryahPlans,
    companyPlans: (state) => state.companyPlans,

    rowsSaryahPlans: (state) => state.rowsSaryahPlans,
    countSaryahPlans: (state) => state.countSaryahPlans,
    rowsCustomPlans: (state) => state.rowsCustomPlans,
    countCustomPlans: (state) => state.countCustomPlans,
    loadingSaryahPlans: (state) => state.loadingSaryahPlans,
    loadingCustomPlans: (state) => state.loadingCustomPlans,

    lastRecordSaryah: (state) => state.lastRecordSaryah,
    firstRecordSaryah: (state) => state.firstRecordSaryah,
    lastRecordCustom: (state) => state.lastRecordCustom,
    firstRecordCustom: (state) => state.firstRecordCustom,

    disableNextCustom: (state) => state.disableNextCustom,
    disablePreviousCustom: (state) => state.disablePreviousCustom,
    disableNextSaryah: (state) => state.disableNextSaryah,
    disablePreviousSaryah: (state) => state.disablePreviousSaryah,


    saryahPlansFirstPage: (state) => state.saryahPlansFirstPage,
    saryahPlansLastPage: (state) => state.saryahPlansLastPage,
    customPlansFirstPage: (state) => state.customPlansFirstPage,
    customPlansLastPage: (state) => state.customPlansLastPage,

  },

  mutations: {
    RESETED(state) {
      state.rows = [];
      state.count = 0;
      state.loading = false;
      state.filter = {};
      state.pagination = {};
      state.sorter = {};
      if (state.table) {
        state.table.clearSelection();
      }
    },

    UNSELECT_ALL(state) {
      if (state.table) {
        state.table.clearSelection();
      }
    },

    TABLE_MOUNTED(state, payload) {
      state.table = payload;
    },

    PAGINATION_CHANGED(state, payload) {
      state.pagination = payload || {};
    },

    PAGINATION_CURRENT_PAGE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: payload || 1,
        pageSize:
          previousPagination.pageSize || INITIAL_PAGE_SIZE,
      };
    },

    PAGINATION_PAGE_SIZE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: previousPagination.currentPage || 1,
        pageSize: payload || INITIAL_PAGE_SIZE,
      };
    },

    SORTER_CHANGED(state, payload) {
      state.sorter = payload || {};
    },

    FETCH_STARTED(state) {
      state.loading = true;
    },
    FETCH_STARTED_SARYAH_PLANS(state){
      state.loadingSaryahPlans = true
    },
    FETCH_STARTED_CUSTOM_PLANS(state){
      state.loadingCustomPlans = true
    },

    // FETCH_STARTED(state, payload) {
    //   state.loading = true;
    //   if (state.table) {
    //     state.table.clearSelection();
    //   }

    //   state.filter =
    //     payload && payload.filter ? payload.filter : {};
    //   state.pagination =
    //     payload && payload.keepPagination
    //       ? state.pagination
    //       : {};
    // },

    FETCH_SUCCESS(state, payload) {
      state.loading = false;
      state.rows = payload.rows;
      state.count = payload.count;
    },

    FETCH_SUCCESS_SARYAH_PLANS(state, payload){
      state.rowsSaryahPlans = payload.rowsSaryahPlans;
      state.countSaryahPlans = payload.countSaryahPlans;
      state.saryahPlansFirstPage = payload.saryahPlansFirstPage;
      state.saryahPlansLastPage = payload.saryahPlansLastPage
      state.loadingSaryahPlans = false;
    },
    FINISH_SARYAH_LOADING(state){
      state.loadingSaryahPlans = false;
    },
    FINISH_CUSTOM_LOADING(state){
      state.loadingCustomPlans = false;
    },
    FETCH_SUCCESS_CUSTOM_PLANS(state, payload){
      state.rowsCustomPlans = payload.rowsCustomPlans;
      state.countCustomPlans = payload.countCustomPlans;
      state.customPlansFirstPage = payload.customPlansFirstPage,
      state.customPlansLastPage = payload.customPlansLastPage
      state.loadingCustomPlans = false
    },

    FETCH_ERROR(state) {
      state.loading = false;
      state.rows = [];
      state.count = 0;
    },

    SET_LAST_SARYAH_RECORD(state){
      state.lastRecordSaryah = state.rowsSaryahPlans[state.rowsSaryahPlans.length - 1]
    },
    SET_FIRST_SARYAH_RECORD(state){
      state.firstRecordSaryah = state.rowsSaryahPlans[0]
    },

    SET_LAST_CUSTOM_RECORD(state){
      state.lastRecordCustom = state.rowsCustomPlans[state.rowsCustomPlans.length - 1]
    },
    SET_FIRST_CUSTOM_RECORD(state){
      state.firstRecordCustom = state.rowsCustomPlans[0]      
    },

    DISABLE_NEXT_CUSTOM(state, payload){
      state.disableNextCustom = payload
    },

    DISABLE_PREVIOUS_CUSTOM(state, payload){
      state.disablePreviousCustom = payload
    },

    DISABLE_NEXT_SARYAH(state, payload){
      state.disableNextSaryah = payload
    },

    DISABLE_PREVIOUS_SARYAH(state, payload){
      state.disablePreviousSaryah = payload
    },

    

    EXPORT_STARTED(state) {
      state.exportLoading = true;
    },

    EXPORT_SUCCESS(state) {
      state.exportLoading = false;
    },

    EXPORT_ERROR(state) {
      state.exportLoading = false;
    },


    //#region [ vuexfire ]
    ...vuexfireMutations,
    //#endregion
  },

  actions: {
    doUnselectAll({ commit }) {
      commit('UNSELECT_ALL');
    },

    doMountTable({ commit }, table) {
      commit('TABLE_MOUNTED', table);
    },

    async doReset({ commit, dispatch }) {
      commit('RESETED');
      return dispatch('doFetchGroupbyCity');
      // return dispatch('doFetch');
    },

    // async doExport({ commit, getters }) {
    //   try {
    //     if (!planListExporterFields || !planListExporterFields.length) {
    //       throw new Error('planListExporterFields is required');
    //     }

    //     commit('EXPORT_STARTED');

    //     const filter = getters.filter;

    //     const response = await PlanService.list(
    //       filter,
    //       getters.orderBy,
    //       null,
    //       null,
    //     );

    //     new Exporter(
    //       planListExporterFields,
    //       'plan',
    //     ).transformAndExportAsExcelFile(response.rows);

    //     commit('EXPORT_SUCCESS');
    //   } catch (error) {
    //     Errors.handle(error);

    //     commit('EXPORT_ERROR');
    //   }
    // },

    doChangePagination(
      { commit, getters, dispatch },
      pagination,
    ) {
      commit('PAGINATION_CHANGED', pagination);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },

    doChangePaginationPageSize(
      { commit, getters, dispatch },
      pageSize,
    ) {
      commit('PAGINATION_PAGE_SIZE_CHANGED', pageSize);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },

    doChangePaginationCurrentPage(
      { commit, getters, dispatch },
      currentPage,
    ) {
      commit(
        'PAGINATION_CURRENT_PAGE_CHANGED',
        currentPage,
      );
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },

    doChangeSort({ commit, getters, dispatch }, sorter) {
      commit('SORTER_CHANGED', sorter);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },

    doFetchSaryahPlans:  firestoreAction(async ({ bindFirestoreRef, getters, commit }, payload) => {
        commit('FETCH_STARTED')
        console.log('payload',payload);
        const customSerializer = (doc) => {
          const data = doc.data()
          Object.defineProperty(data, '_doc', { value: doc })
          return data
        }
        const db = firebase.firestore()
        var planCollection = null
        planCollection = db.collection('plan').where('type', '==', 'default')
        // planCollection = getters.loadMoreLastRecord ? 
        //     db.collection('offer')
        //     .limit(payload.limit || 9)
        //     .orderBy('createdAt','desc')
        //     .startAfter(getters.loadMoreLastRecord) :
        //     db.collection('offer')
        //     .orderBy('createdAt','desc')
        //     .limit(payload.limit || 9)

        await bindFirestoreRef(
          'saryahPlans',
          planCollection,
          { serialize: customSerializer }
        );
        console.log('getters.saryahPlans', getters.saryahPlans);
        // debugger

    }),

    doFetchCompanyPlans:  firestoreAction(async ({ bindFirestoreRef, getters, commit }, payload) => {
      commit('FETCH_STARTED')
      console.log('payload',payload);
      const customSerializer = (doc) => {
        const data = doc.data()
        Object.defineProperty(data, '_doc', { value: doc })
        return data
      }
      const db = firebase.firestore()
      var planCollection = null
      planCollection = db.collection('plan').where('type', '==', 'custom')
      // planCollection = getters.loadMoreLastRecord ? 
      //     db.collection('offer')
      //     .limit(payload.limit || 9)
      //     .orderBy('createdAt','desc')
      //     .startAfter(getters.loadMoreLastRecord) :
      //     db.collection('offer')
      //     .orderBy('createdAt','desc')
      //     .limit(payload.limit || 9)

      await bindFirestoreRef(
        'companyPlans',
        planCollection,
        { serialize: customSerializer }
      );
      console.log('getters.companyPlans', getters.companyPlans);
    }),

    async doFetch({ commit }, { 
      filter, 
      orderBy,
      pagination 
    } = {}) {
      try {
        // commit('FETCH_STARTED');
        if(filter.type == "default"){
          commit('FETCH_STARTED_SARYAH_PLANS');
        }
        if(filter.type == 'custom'){
          commit('FETCH_STARTED_CUSTOM_PLANS');
        }
        const response = await PlanService.listPlansWithPagination(
          filter,          
          orderBy,
          pagination
        );
          if(filter.type == "default"){
            // console.log(response);
            // debugger

            commit('FETCH_SUCCESS_SARYAH_PLANS', {
              rowsSaryahPlans: response.rows,
              countSaryahPlans: response.count,
              saryahPlansFirstPage: response.pagination ?  response.pagination.isFirstPage : true,
              saryahPlansLastPage: response.pagination ? response.pagination.isLastPage : true
            });
            commit('SET_LAST_SARYAH_RECORD')
            commit('SET_FIRST_SARYAH_RECORD')
          }
          if(filter.type == 'custom'){
            console.log(response.pagination);
            commit('FETCH_SUCCESS_CUSTOM_PLANS', {
              rowsCustomPlans: response.rows,
              countCustomPlans: response.count,
              customPlansFirstPage: response.pagination ?  response.pagination.isFirstPage : true,
              customPlansLastPage: response.pagination ? response.pagination.isLastPage : true
            });
            commit('SET_LAST_CUSTOM_RECORD')
            commit('SET_FIRST_CUSTOM_RECORD')            
          }
      } catch (error) {
        Errors.handle(error);
        commit('FETCH_ERROR');
      }
    },

    async doFetchAll({commit}){
      commit('FETCH_STARTED');
      try {
        const response = await PlanService.list();
          commit('FETCH_SUCCESS', {
            rows: response.rows,
            count: response.count,
          });
      }
      catch(error){
        Errors.handle(error);
        commit('FETCH_ERROR');
      }
    },

    finishSaryahLoading({commit}){
      commit('FINISH_SARYAH_LOADING')
    },
    finishCustomLoading({commit}){
      commit('FINISH_CUSTOM_LOADING')
    },

    setDisableNextCustom: (async ({getters, commit}, payload) => {
      console.log('getters.lastRecordCustom ==> ', getters.lastRecordCustom);
      const createdAt = new Date(getters.lastRecordCustom.createdAt); 
      const createdAtTimestamp = firebase.firestore.Timestamp.fromDate(createdAt)
      const collection = await firebase.firestore().collection('plan')
        .where('ownerId', '==', payload.ownerId)
        .where('type', '==', 'custom')
        .orderBy('createdAt', 'desc')          
        .startAfter(createdAtTimestamp) 
        .limit(1)
        .get()
        // console.log('next length', collection.docs.length);
        // collection.docs.forEach((doc) => {
        //   console.log('doc after last', doc.data());
        // })
        if(collection.docs.length > 0){       
          commit('DISABLE_NEXT_CUSTOM', false)
        }
        else{
          commit('DISABLE_NEXT_CUSTOM', true)
        }        
    }),

    setDisablePreviousCustom: (async ({getters, commit}, payload) => {
      console.log('getters.firstRecord ==>', getters.firstRecordCustom);      
      const createdAt = new Date(getters.firstRecordCustom.createdAt);      
      const createdAtTimestamp = firebase.firestore.Timestamp.fromDate(createdAt)          
      commit('FETCH_STARTED');
      const collection = await firebase.firestore().collection('plan')
        .where('ownerId', '==', payload.ownerId)
        .where('type', '==', 'custom')
        .orderBy('createdAt', 'desc')          
        .endBefore(createdAtTimestamp)
        .limitToLast(1)
        .get()

        console.log('prev length', collection.docs.length);
        // collection.docs.forEach((doc) => {
        //   console.log('doc before first', doc.data());
        // })
        // debugger

        if(collection.docs.length > 0){
          commit('DISABLE_PREVIOUS_CUSTOM', false)
        }
        else{
          commit('DISABLE_PREVIOUS_CUSTOM', true)
        }
        // commit('FETCH_SUCCESS_DISABLE_PAGINATION')
    }),

    setDisableNextSaryah: (async ({getters, commit}) => {
      console.log('getters.lastRecordSaryah ==> ', getters.lastRecordSaryah);
      const createdAt = new Date(getters.lastRecordSaryah.createdAt); 
      const createdAtTimestamp = firebase.firestore.Timestamp.fromDate(createdAt)
      console.log('createdAt', createdAt);
      console.log('createdAtTimestamp', createdAtTimestamp);
      // debugger
      const collection = await firebase.firestore()
        .collection('plan')
        .where('type', '==', 'default')
        .orderBy('createdAt', 'desc')          
        .startAfter(createdAtTimestamp) 
        .limit(1)
        .get()
      console.log('next length', collection.docs.length);
      collection.docs.forEach((doc) => {
        console.log('doc after last', doc.data());
        console.log('doc createdAt===>', new Date(doc.data().createdAt.seconds * 1000));
      })
      // debugger
      if(collection.docs.length > 0){       
        commit('DISABLE_NEXT_SARYAH', false)
      }
      else{
        commit('DISABLE_NEXT_SARYAH', true)
      }        
    }),

    setDisablePreviousSaryah: (async ({getters, commit}) => {
      console.log('getters.firstRecord ==>', getters.firstRecordSaryah);
      const createdAt = new Date(getters.firstRecordSaryah.createdAt);      
      const createdAtTimestamp = firebase.firestore.Timestamp.fromDate(createdAt) 
      console.log(createdAtTimestamp);
      // debugger
      const collection = await firebase.firestore().collection('plan')
      .where('type', '==', 'default')
      .orderBy('createdAt', 'desc')          
      .endBefore(createdAtTimestamp)
      .limitToLast(1)
      .get()

        console.log('prev length', collection.docs.length);
        collection.docs.forEach((doc) => {
          console.log('doc before first', doc.data());
        })
        // debugger
        if(collection.docs.length > 0){
          commit('DISABLE_PREVIOUS_SARYAH', false)
        }
        else{
          commit('DISABLE_PREVIOUS_SARYAH', true)
        }
    }),


    async doFetchGroupbyCity({ commit, getters }, { 
        filter, 
        keepPagination 
      } = {}) {
      try {
        commit('FETCH_STARTED', { filter, keepPagination });

        const response = await PlanService.list(
          filter,
          getters.orderBy,
          // getters.limit,
          getters.offset,
        );
        console.log(response);
        
        // Group By Start City ID ===================================================== //
        let distinctCityId = [...new Set(response.rows.map(el => el.startCityId))]
        console.log('distinctCityId =>> ', distinctCityId)
        let rows = []
        distinctCityId.forEach(cityId => {
          console.log('response.rows =>> ', response.rows)
          console.log('cityId =>> ', cityId)
          let plans = response.rows.filter(el => el.startCityId == cityId)
          console.log('plans =>> ', plans)
          rows.push({
            startCityId: cityId,
            cityName: plans[0].startCity.name,
            numberOfPlans: plans.length,
            createdAt: plans[0].createdAt,
            plans: plans,
          })
        });        
        console.log('rows =>> ', rows)
        // ============================================================================= //

        commit('FETCH_SUCCESS', {
          rows: rows,
          count: rows.length,
        });

        // commit('FETCH_SUCCESS', {
        //   rows: response.rows,
        //   count: response.count,
        // });
      } catch (error) {
        Errors.handle(error);
        commit('FETCH_ERROR');
      }
    },

  },
};
