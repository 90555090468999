import '@/shared/plugins/element';
import Vue from 'vue';
import Vuex from 'vuex';
import Router from 'vue-router';
import {
  setupComponentsFiltersDirectivesAndMixins,
  storeAsync,
  routerAsync,
} from '@/app-module';
import app from '@/app.vue';
import PortalVue from 'portal-vue';

//#region [ Quasar ]
import Quasar from 'quasar'
import { Notify } from 'quasar'
// import { QRating } from 'quasar'

Vue.use(Quasar, {
  plugins: {
    Notify
  },
  config: {
    notify: {
      /* Notify defaults */
    }
  },
  // components: {
  //   QRating
  // },
})

import langAr from 'quasar/lang/ar'
Vue.use(Quasar, {
  lang: langAr
})
import { LoadingBar } from 'quasar'
LoadingBar.setDefaults({
  color: 'amber-14',
  size: '1px',
  position: 'top'
})
import './quasar'
//#endregion

// import BlackDashboard from "./plugins/blackDashboard";
import * as VueGoogleMaps from "vue2-google-maps";
import BootstrapVue from 'bootstrap-vue'
import VuePageTransition from 'vue-page-transition'
import {
  FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

import { SettingsService } from '@/modules/settings-old/settings-service';
// import ProgressBar from '@/shared/progress-bar/progress-bar';
// import { i18n } from '@/i18n';
import 'bootstrap';
import vueSmoothScroll from 'vue2-smooth-scroll'
// import 'bootstrap/dist/css/bootstrap.min.css'

import vueCountryRegionSelect from 'vue-country-region-select'
// Custom Styles
import './scss/index.scss'

import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css' //Vuesax styles

import draggable from 'vuedraggable'
import VueSelectImage from 'vue-select-image'
import i18n from './vueI18n'
// console.log(vueI18n);
// debugger
import Carousel3d from 'vue-carousel-3d';
import CKEditor from 'ckeditor4-vue';
import { firestorePlugin } from 'vuefire'

Vue.use(firestorePlugin)
Vue.use(CKEditor)

// Custom Styles ==> SASS Theme 
import './assets/scss/index.scss'
import './quasar'
import './quasar'
import './quasar'

(async function () {
  document.title = i18n.t('app.title');
  // ProgressBar.start();
  SettingsService.fetchAndApply();
  // routerAsync().push('/auth/signin');

  // ======================================= New Installer ======================================= //
  //#region [ New Installer ]
  Vue.use(PerfectScrollbar)
  Vue.component('font-awesome-icon', FontAwesomeIcon)
  Vue.component('vue-phone-number-input', VuePhoneNumberInput)
  Vue.component('draggable', draggable);
  
  Vue.use(VueSelectImage)
  require('vue-select-image/dist/vue-select-image.css') // add stylesheet

  Vue.use(vueCountryRegionSelect)
  Vue.use(VuePageTransition)
  Vue.use(BootstrapVue)
  Vue.use(VueGoogleMaps, {
    load: {
      key: "AIzaSyDgrVwgswxqsFsvyNVGC7MAPm2xdBtRTHo",
      libraries: "places" // necessary for places input
    }
  });
  // Vue.use(BlackDashboard);
  Vue.use(vueSmoothScroll)
  Vue.directive('title', {
    inserted: (el, binding) => document.title = binding.value,
    update: (el, binding) => document.title = binding.value
  })
  Vue.use(Carousel3d);
  Vue.use(Vuex);
  Vue.use(Vuesax, {
    // options here
  })
  //#endregion
  // ============================================================================================= //

  Vue.use(Router);
  Vue.use(PortalVue)
  Vue.config.productionTip = process.env.NODE_ENV === 'production';
  setupComponentsFiltersDirectivesAndMixins();

  // eslint-disable-next-line
  new Vue({
    store: storeAsync(),
    router: routerAsync(),
    i18n,
    render: (h) => h(app)
  }).$mount('#app');
})();