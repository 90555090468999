//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
export default {
    name: 'app-delete-item-modal',
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        message: {
            type: String,
            required: true
        },
        id: {
            type: String,
            required: true
        },
        loading: {
            type: Boolean
        }
    },
    computed:{
        ...mapGetters({
            is_screen_xs: 'layout/is_screen_xs'
        }),
        dialogVisible:{
            get(){
                return this.visible
            },
            set(value){
                if(!value){
                    this.$emit('close')
                }

            }
        }
    },
    methods:{
        async doSubmit(){
            await this.$emit('submit', this.id)
        }
    }
}
