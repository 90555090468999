import cityListStore from '@/modules/city/city-list-store';
import cityViewStore from '@/modules/city/city-view-store';
import cityFormStore from '@/modules/city/city-form-store';
import cityDestroyStore from '@/modules/city/city-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: cityDestroyStore,
    form: cityFormStore,
    list: cityListStore,
    view: cityViewStore,
  },
};
