import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-notification';

export class NotificationService {
  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation NOTIFICATION_CREATE($data: NotificationInput!) {
          notificationCreate(data: $data) {
            id
          }
        }
      `,

      variables: {
        data,
      },
    });

    return response.data.notificationCreate;
  }  
}
