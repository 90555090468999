import { BranchService } from '@/modules/branch/branch-service';
import Errors from '@/shared/error/errors';
import { routerAsync } from '@/app-module';

export default {
  namespaced: true,

  state: {
    loading: false,
    record: null,
    rows: []
  },

  getters: {
    record: (state) => state.record,
    loading: (state) => !!state.loading,
    rows: (state) => state.rows,
  },

  mutations: {
    FIND_STARTED(state) {
      state.record = null;
      state.loading = true;
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.loading = false;
    },

    FIND_ERROR(state) {
      state.record = null;
      state.loading = false;
    },
    FETCH_STARTED(state) {
      state.loading = true;
    },

    FETCH_SUCCESS(state, payload) {
      state.loading = false;
      state.rows = payload.rows;
      state.count = payload.count;
    },
  },

  actions: {
    async doFind({ commit }, {id,companyId}) {
      try {
        commit('FIND_STARTED');
        const record = await BranchService.find(id,companyId);
        commit('FIND_SUCCESS', record);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        routerAsync().push('/branch');
      }
    },
    async doFetchBranchGuides({ commit }, branchId) {
      try {
        commit('FETCH_STARTED');
        const response = await BranchService.listBranchGuides(
          branchId
        );
        debugger
        console.log('respone.rows',response);
        commit('FETCH_SUCCESS', {
          rows: response.rows,
          count: response.count,
        });
      } catch (error) {
        Errors.handle(error);
        commit('FETCH_ERROR');
      }
    },
  },
};

