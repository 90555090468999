import Layout from '@/modules/layout/components/layout';
// import Permissions from '@/security/permissions';
import { i18n } from '@/vueI18n';

const ReservationListPage = () =>
  import('@/modules/reservation/components/reservation-list-page.vue');

export default [
  {
    name: '',
    path: '/:lang?',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'reservations',
        path: 'reservations',
        component: ReservationListPage,
        meta: {
          auth: true,
          title: i18n('metaTitle.reservation')

        },
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },
    ],
  },
];
