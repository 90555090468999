// import Layout from '@/modules/layout/layout/dashboard/DashboardLayout';
import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';
import { i18n } from '@/i18n';
const NotificationListPage = () =>
  import('@/modules/notification/components/notification-list-page.vue');
const NotificationFormPage = () =>
  import('@/modules/notification/components/notification-form-page.vue');
const NotificationViewPage = () =>
  import('@/modules/notification/components/notification-view-page.vue');
const NotificationImporterPage = () =>
  import('@/modules/notification/components/notification-importer-page.vue');
  

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'notifications',
        path: '/notification',
        component: NotificationListPage,
        meta: {
          auth: true,
          permission: Permissions.values.notificationView,
        },
      },
      {
        name: 'notificationNew',
        path: '/notification/new',
        component: NotificationFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.notificationCreate,
          title: i18n('metaTitle.newNotification')
      },
      beforeEnter: (to, from, next) => {
        document.title = to.meta.title
        next()
      }
      },
      {
        name: 'notificationImporter',
        path: '/notification/import',
        component: NotificationImporterPage,
        meta: {
          auth: true,
          permission: Permissions.values.notificationImport,
        },
      },
      {
        name: 'notificationEdit',
        path: '/notification/:id/edit',
        component: NotificationFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.notificationEdit,
        },
        props: true,
      },
      {
        name: 'notificationView',
        path: '/notification/:id',
        component: NotificationViewPage,
        meta: {
          auth: true,
          permission: Permissions.values.notificationView,
        },
        props: true,
      },
    ],
  },
];
