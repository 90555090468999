//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import i18n from '@/vueI18n'

export default {
  name: 'app',

  data() {
    return {};
  },
  created() {
    const promises = [
      new Promise(() => this.doInitFirebase()),
      new Promise(() => this.doInit()),
    ];
    Promise.all(promises).then(
      console.log('doInitFirebase Done & doInit Done'),
    );
    // await this.doInit();
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },

  methods: {
    ...mapActions({
      doInitFirebase: 'auth/doInitFirebase',
      doInit: 'auth/doInit',
      resize: 'layout/resize',
    }),

    handleResize() {
      this.resize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    },
    toggleNavOpen() {
      let root = document.getElementsByTagName('html')[0];
      root.classList.toggle('nav-open');
    },
  },
  mounted() {
    this.$watch('$sidebar.showSidebar', this.toggleNavOpen);

    // switch language
    // if (localStorage.getItem('language') == 'ar') {
    //   import('quasar/lang/ar').then((lang) => {
    //     this.$q.lang.set(lang.default);
    //   });
    // }

    // if (localStorage.getItem('language') == 'en') {
    //   import('quasar/lang/en-us').then((lang) => {
    //     this.$q.lang.set(lang.default);
    //   });
    // }



    if (localStorage.getItem("language") == "ar") {
      import(`quasar/lang/ar`).then((lang) => {
        this.$q.lang.set(lang.default);
      });
    } else {
      import(`quasar/lang/en-us`).then((lang) => {
        this.$q.lang.set(lang.default);
      });
    }
  },

   watch:{
    'i18n.locale'(newVal){      
      console.log(newVal)
    },
      lang(newVal,oldVal){
        // debugger
        console.log('lang old value',oldVal)
        console.log('lang new value',newVal)

        if(newVal == 'ar') {
          document.getElementsByTagName("html")[0].setAttribute("dir","rtl")
          this.$q.lang.rtl = true
        }
        if(newVal == 'en') {
          document.getElementsByTagName("html")[0].setAttribute("dir","ltr")
          this.$q.lang.rtl = false
        }
      }
  },

  computed: {
    ...mapGetters({
      loadingInit: 'auth/loadingInit',
    }),
    lang(){
      return i18n.locale
    },
    isRTL() {
      return i18n.locale == 'ar'
      // return localStorage.getItem('language') == 'ar';
    },
  },
};
