import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-iam';

export class AdminService {
    static async createInAuthAndFirestore(data) {
        console.log(data);
        const response = await graphqlClient.mutate({
            mutation: gql`
                mutation IAM_CREATE_IN_AUTH_AND_FIRESTORE($data: IamCreateInput!) {
                    iamCreateInAuthAndFirestore(data: $data){
                      id
                      fullName
                    }
                }`,
            variables: {
                data
            },
        });

        return response.data.iamCreateInAuthAndFirestore;
    }
    static async create(data) {
        console.log(data);
        const response = await graphqlClient.mutate({
          mutation: gql`
            mutation IAM_CREATE($data: IamCreateInput!) {
              iamCreate(data: $data)
            }`,
          variables: {
            data,
          },
        });
    
        return response.data.iamCreate;
      }

      static async list(companyId, orderBy, pagination) {    
        const response = await graphqlClient.query({
          query: gql`
            query IAM_LIST_COMPANY_ADMINS_PAGINATION (
              $companyId: String!
              $orderBy: String
              $pagination: PaginationInput
            ) {
              iamListCompanyAdminsPagination(
                companyId: $companyId
                orderBy: $orderBy
                pagination: $pagination
              ) {
                pagination {
                  isFirstPage
                  isLastPage
                }
                count
                rows {
                  id
                  fullName
                  phoneNumber  
                  email 
                  roles
                  disabled
                  createdAt
                  updatedAt
                  branch{
                    name{
                      en
                      ar
                    }
                  }
                }
              }
            }
          `,
    
          variables: {
            companyId,
            orderBy,
            pagination
          },
        });
    
        return response.data.iamListCompanyAdminsPagination;
      }

      static async edit(data) {
        const response = await graphqlClient.mutate({
          mutation: gql`
            mutation IAM_EDIT($data: IamEditInput!) {
              iamEdit(data: $data)
            }
          `,
    
          variables: {
            data,
          },
        });
    
        return response.data.iamEdit;
      }

      static async disable(ids) {
        return this._changeStatus(ids, true);
      }
    
      static async _changeStatus(ids, disabled) {
        const response = await graphqlClient.mutate({
          mutation: gql`
            mutation IAM_CHANGE_STATUS(
              $ids: [String!]!
              $disabled: Boolean
            ) {
              iamChangeStatus(ids: $ids, disabled: $disabled)
            }
          `,
    
          variables: {
            ids,
            disabled: !!disabled,
          },
        });
    
        return response.data.iamChangeStatus;
      }
}
