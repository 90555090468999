import { storeAsync } from '@/app-module';
import i18n from '@/vueI18n'

export default {
  async beforeRouteEnter(to, from, next) {
    console.log(to);
    // debugger

    let { lang } = to.params
    console.log('lang',lang)
    if (!lang || (lang !== 'ar' && lang !== 'en')){
      lang = localStorage.getItem('language') || 'en'
      i18n.locale = lang
      return next({ path: `/${lang}${to.path}` });
    }
    if(lang !== 'ar' && lang !== 'en') lang = 'en'
    i18n.locale = lang
    localStorage.setItem("language",lang)
    console.log('lang',lang)

    if (!to.meta || !to.meta.auth) {
      next();
      return;
    }
    
    const store = storeAsync();
    let currentUser = localStorage.getItem('currentUser');
    if (currentUser) {
      // next();
      await store.dispatch('auth/doWaitUntilInit');
    }

    if (!store.getters['auth/signedIn']) {
      next({ path: `/${lang}/auth/signin` });
      return;
    }
   
    // to.path !== `/${lang}/auth/email-unverified` &&
    if (
      // to.path !== `/${lang}/auth/signin` &&
      // store.getters['auth/signedIn'] &&
      // !store.getters['auth/currentUser'].emailVerified  &&
      to.path !== `/${lang}/auth/signup` &&
      !store.getters['auth/currentUser'].email // added
    ) {
      debugger
      console.log('>>>>>auth guard-mixin',store.getters['auth/currentUser']);
      // store.getters['auth/currentUser'].authenticationUid==null
      console.log("EMAIL: ",store.getters['auth/currentUser'].email);
      next({ path: `/${lang}/auth/signup` }); // check refresh problem here
      return;
    }
    //----------------------------added----------------------------
    if (
      to.path !== `/${lang}/auth/email-unverified` 
      &&
      !store.getters['auth/currentUser'].emailVerified 
    ) {
      console.log('>>>>>auth guard-mixin',store.getters['auth/currentUser']);
      
      next({ path: `/${lang}/auth/email-unverified` }); // check refresh problem here
      
      return;
    }
    //----------------------------added----------------------------
    
    if (
      to.path !== `/${lang}/auth/complete-profile` &&
      store.getters['auth/currentUser'].emailVerified &&
      !store.getters['auth/currentUser'].companyId
    ) {
      // next({ path: '/auth/empty-permissions' });
      next({ path: `/${lang}/auth/complete-profile` });
      return;
    }



    // //////////////////////////// this should be uncommented
    if (
      to.path !== `/${lang}/auth/empty-permissions` &&
      store.getters['auth/currentUser'].emailVerified &&
      store.getters['auth/currentUser'].companyId &&
      !store.getters['auth/roles'].length
    ) {
      next({ path: `/${lang}/auth/empty-permissions` });      
      return;
    }

    next();
  },
};
