import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';
import { i18n } from '@/i18n';

const ReviewsListPage = () =>
  import('@/modules/reviews/components/reviews-list-page.vue');

export default [
  {
    name: '',
    path: '/:lang?',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'reviews',
        path: 'reviews',
        component: ReviewsListPage,
        meta: {
          auth: true,
          permission: Permissions.values.reviewsView,
          title: i18n('metaTitle.reviews')

        },
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },
    ],
  },
];
