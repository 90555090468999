//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { I18nUtil } from '@/shared/i18n/i18n-util';
import { getLanguages } from '@/i18n';
import i18n from '@/vueI18n'
import { Quasar } from 'quasar'

export default {
  name: 'app-i18n-flags',

  computed: {
    languages() {
      return getLanguages();
    },
  },

  methods: {
    // doChangeLanguage(language) {
    //   if(language != localStorage.getItem('language'))
    //   {
    //       I18nUtil.doChangeLanguage(language);
    //   }
    
    // },
      doChangeLanguage(language) {
        console.log(language)
      i18n.locale = language
      localStorage.setItem('language',language)

      const {lang} = this.$route.params
      let route = ''
      if(lang && (lang === 'ar' || lang === 'en')){
        route = `/${language}${this.$route.fullPath.substring(3)}`
      }
      else{
        route = `/${language}${this.$route.fullPath}`
      }
      if(language === 'ar'){
        // document.getElementsByTagName("html")[0].setAttribute("dir","rtl")
        // this.$q.lang.rtl = true
        // this.ar = true
        import(`quasar/lang/ar`).then((lang) => {
          // eslint-disable-next-line
          Quasar.lang.set(lang.default);
        });
      }
      else {
        // document.getElementsByTagName("html")[0].setAttribute("dir","ltr")
        // this.$q.lang.rtl = false
        // this.ar = false
        import(`quasar/lang/en-us`).then((lang) => {
          // eslint-disable-next-line
          Quasar.lang.set(lang.default);
        });
      }
      console.log(route)
      this.$router.push(route)
      // I18nUtil.doChangeLanguage(language);
    },
  },
};
