import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-plan';
import { storeAsync } from '@/app-module';
import firebase from 'firebase/app'
import 'firebase/firestore';

export class PlanService {
  static async enable(id) {
    return this._changeStatus(id, false);
  }

  static async disable(id) {
    return this._changeStatus(id, true);
  }

  static async _changeStatus(id, disabled) {
    let currentUser = storeAsync().getters['auth/currentUser']

    let batch = firebase.firestore().batch();
    
    let docRef = firebase.firestore().doc(`plan/${id}`) 
    batch.update(docRef, {
      disabled,
      updatedBy: currentUser.id,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
    
    return await batch.commit()
  }

  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation PLAN_CREATE($data: PlanInput!) {
          planCreate(data: $data) {
            id
          }
        }
      `,

      variables: {
        data,
      },
    });

    return response.data.planCreate;
  }

  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation PLAN_UPDATE($id: String!, $data: PlanInput!) {
          planUpdate(id: $id, data: $data) {
            id
          }
        }
      `,

      variables: {
        id,
        data,
      },
    });

    return response.data.planUpdate;
  }

  static async togglePlanStatus(id, enabled) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation planUpdateStatus($id: String!, $enabled: Boolean) {
            planUpdateStatus(id: $id, enabled: $enabled)
        }
      `,

      variables: {
        id,
        enabled,
      },
    });
    debugger
    return response.data.planUpdateStatus;
  }

  static async destroyAll(ids) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation PLAN_DESTROY($ids: [String!]!) {
          planDestroy(ids: $ids)
        }
      `,

      variables: {
        ids,
      },
    });

    return response.data.planDestroy;
  }

  static async import(values, importHash) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation PLAN_IMPORT(
          $data: PlanInput!
          $importHash: String!
        ) {
          planImport(data: $data, importHash: $importHash)
        }
      `,

      variables: {
        data: values,
        importHash,
      },
    });

    return response.data.planImport;
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query PLAN_FIND($id: String!) {
          planFind(id: $id) {
            id
            name {
              en
              ar
            }
            description {
              en
              ar
            }
            type
            numberOfDays
            enabled
            programs {
              name
              places {
                place {
                  id
                  placeName {
                    en
                    ar
                  }
                  placePictures {
                    id
                    name
                    publicUrl
                    privateUrl
                  }
                  country {
                    en
                    ar
                  }
                  city {
                    id
                    name {
                      en
                      ar
                    }
                  }
                }
                id
                selectedImage
                averageTime
              }
            }
        
            createdBy
            updatedBy
            createdAt
            updatedAt
          }
        }
      `,

      variables: {
        id,
      },
    });

    return response.data.planFind;
  }

  static async listPlansWithPagination(filter, orderBy, pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query PLAN_LIST_WITH_PAGINATION(
          $filter: PlanFilterInput, 
          $orderBy: String, 
          $pagination: PaginationInput
        ) {
          listMyPlansWithPagination(
            filter: $filter, 
            orderBy: $orderBy, 
            pagination: $pagination
          ) {
            count
            pagination {
              isFirstPage
              isLastPage
            }
            rows {
              images
              startPoint {
                  placeName {
                              en
                              ar
                          }
              }
              endPoint {
                  placeName {
                              en
                              ar
                          }
              }
              id
              ownerId
              name {
                  en
                  ar
              }
              description {
                  en
                  ar
              }
              type
              numberOfDays
              enabled
              programs {
                  places {
                      place {
                          id
                          placeName {
                              en
                              ar
                          }
                          description {
                              en
                              ar
                          }
                          country {
                              en
                              ar
                          }
                          cityId
                          city {
                              id
                              region_id
                              name  {
                                  en
                                  ar
                              }
                          }
                          placeType
                          placePictures {
                              name
                              publicUrl
                          }
                          visitCount
                          address {
                              en
                              ar
                          }
                          lat
                          lng
                          strokeWidth
                          radius
                          fillColor
                          strokeColor
                          notifications
                          status
                          rejectionReason
                          createdBy
                          updatedBy
                          createdAt
                          updatedAt
                      }
                      id
                      selectedImage
                      averageTime
                  }
              }
  
              createdBy
              updatedBy
              createdAt
              updatedAt
          }
          }
        }
      `,

      variables: {
        filter, 
        orderBy, 
        pagination
      },
    });
    debugger
    return response.data.listMyPlansWithPagination;
  }


  static async list(filter, orderBy, limit, offset) {
    const response = await graphqlClient.query({
      query: gql`
        query PLAN_LIST(
          $filter: PlanFilterInput
          $orderBy: PlanOrderByEnum
          $limit: Int
          $offset: Int
        ) {
          planList(
            filter: $filter
            orderBy: $orderBy
            limit: $limit
            offset: $offset
          ) {
            count
            rows {
              id
              name {
                en
                ar
              }
              description {
                en
                ar
              }
              type
              numberOfDays
              enabled
              programs {
                name 
                places {
                  place {
                    id
                    placeName {
                      en
                      ar
                    }
                    placePictures {
                      id
                      name
                      publicUrl
                      privateUrl
                    }
                    country {
                      en
                      ar
                    }
                    city {
                      id
                      name {
                        en
                        ar
                      }
                 		}
                  }
                  id
                  selectedImage
                  averageTime
                }
              }

              createdBy
              updatedBy
              createdAt
              updatedAt
            }
          }
        }
      `,

      variables: {
        filter,
        orderBy,
        limit,
        offset,
      },
    });

    return response.data.planList;
  }

  static async listAutocomplete(query, limit) {
    const response = await graphqlClient.query({
      query: gql`
        query PLAN_AUTOCOMPLETE(
          $query: String
          $limit: Int
        ) {
          planAutocomplete(query: $query, limit: $limit) {
            id
            label
          }
        }
      `,

      variables: {
        query,
        limit,
      },
    });

    return response.data.planAutocomplete;
  }

}
