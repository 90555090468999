// import Layout from '@/modules/layout/layout/dashboard/DashboardLayout';
import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';
import { i18n } from '@/i18n';

const ActiveTripListPage = () =>
  import('@/modules/active-trip/components/active-trip-list-page.vue');
// const TripFormPage = () =>
//   import('@/modules/trip/components/trip-form-page.vue');
// const TripFormPage = () =>
//   import('@/modules/trip/components/trip-form-page.vue');
// const TripEditPage = () =>
//   import('@/modules/trip/components/trip-edit-page.vue');
// const TripReorderPage = () =>
//   import('@/modules/trip/components/trip-reorder-page.vue');
// const TripViewPage = () =>
//   import('@/modules/trip/components/trip-view-page.vue');

export default [
  {
    name: '',
    path: '/:lang?',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'activeTrip',
        path: 'active-trip',
        component: ActiveTripListPage,
        meta: {
          auth: true,
          permission: Permissions.values.tripView,
          title: i18n('metaTitle.trip')

        },
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },


    //   {
    //     name: 'tripNew',
    //     path: '/trip/new',
    //     component: TripFormPage,
    //     meta: {
    //       auth: true,
    //       permission: Permissions.values.tripCreate,
    //       title: i18n('metaTitle.newTrip') 
    //     },
    //     beforeEnter: (to, from, next) => {
    //       document.title = to.meta.title
    //       next()
    //     }
    //   },







    ],
  },
];
