import Layout from '@/modules/layout/components/layout';
import { i18n } from '@/vueI18n';

const CompanyProfile = () =>
  import('@/modules/owner-profile/components/owner-profile.vue');

export default [
  {
    name: '',
    path: '/:lang?',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'ownerProfile',
        path: 'owner',
        component: CompanyProfile,
        meta: {
          auth: true,
          title: i18n('metaTitle.owner')

        },
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },
    ],
  },
];
