import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/iam/permission-checker';

export class NotificationPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.notificationRead,
    );
    this.import = permissionChecker.match(
      Permissions.values.notificationImport,
    );
    this.notificationAutocomplete = permissionChecker.match(
      Permissions.values.notificationAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.notificationCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.notificationEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.notificationDestroy,
    );
  }
}
