import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-trip';
import { storeAsync } from '@/app-module';
import firebase from 'firebase/app'
import 'firebase/firestore';

export class TripService {
  static async enable(id) {
    return this._changeStatus(id, false);
  }

  static async disable(id) {
    return this._changeStatus(id, true);
  }

  static async _changeStatus(id, disabled) {
    let currentUser = storeAsync().getters['auth/currentUser']

    let batch = firebase.firestore().batch();
    
    let docRef = firebase.firestore().doc(`trip/${id}`) 
    batch.update(docRef, {
      disabled,
      updatedBy: currentUser.id,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
    
    return await batch.commit()
  }

  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation PLAN_CREATE($data: TripInput!) {
          tripCreate(data: $data) 
        }
      `,

      variables: {
        data,
      },
    });
    
    debugger
    return response.data.tripCreate;
  }

  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation PLAN_UPDATE($id: String!, $data: TripInput!) {
          tripUpdate(id: $id, data: $data) {
            id
          }
        }
      `,

      variables: {
        id,
        data,
      },
    });

    return response.data.tripUpdate;
  }

  static async destroyAll(ids) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation PLAN_DESTROY($ids: [String!]!) {
          tripDestroy(ids: $ids)
        }
      `,

      variables: {
        ids,
      },
    });

    return response.data.tripDestroy;
  }

  static async startTrip(id) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation START_TRIP($id: String!) {
          tripStart(id: $id)
        }
      `,

      variables: {
        id,
      },
    });
    
    return response.data.tripStart;
  }

  static async fetchCancellationFees(tripId,type) {
    const response = await graphqlClient.query({
      query: gql`
        query FIND_CANCELLATION_AMOUNT($tripId: String!, $reservationId: String, $type: CancellationType!) {
          findCancellationAmount(tripId: $tripId, reservationId: $reservationId, type: $type) 
        }
      `,

      variables: {
        tripId,
        type
      },
    });
    return response.data.findCancellationAmount;
  }
  static async cancelTrip(id,reason) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation tripCancel ($id: String!, $reason: String) {
            tripCancel(id: $id, reason: $reason) 
        }
      `,
      variables: {
        id,
        reason
      },
    });
    debugger
    return response.data.tripCancel;
  }

  static async import(values, importHash) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation PLAN_IMPORT(
          $data: TripInput!
          $importHash: String!
        ) {
          tripImport(data: $data, importHash: $importHash)
        }
      `,

      variables: {
        data: values,
        importHash,
      },
    });

    return response.data.tripImport;
  }

  static async find(id, includeCount) {
    const response = await graphqlClient.query({
      query: gql`
        query TRIP_FIND($id: String!, $includeCount: Boolean) {
          tripFind(id: $id, includeCount: $includeCount) {
            id
            name {
                en
                ar
            }
            planId
            tripTime
            status
            ownerType
            type
            reservationType
            capacity {
                min
                max
            }
            price {
                private
                group
                extra
            }
            options {
                details {
                    en
                    ar
                }
                price 
            }
            cancelationReason
            guidesId
            companyId
            startCityId
            location {
                latitude
                longitude
            }
            reservationCount
            reservation {
                id
                clientId
                tourGuideId
                tripId
                optionIndex
                qrCode
                isArrived
                numberOfPeople
                paymentMethod
                members {
                    name
                    phoneNumber
                }
                client {
                    id
                    fullName
                    avatar{
                      publicUrl
                    }
                    phoneNumber
                }
                createdBy
                updatedBy
                createdAt
                updatedAt
            }
            plan {
                id
                name {
                    en
                    ar
                }
                description {
                    en
                    ar
                }
                type
                numberOfDays
                enabled
                programs {
                    places {
                        place {
                            id
                            placeName {
                                en
                                ar
                            }
                            description {
                                en
                                ar
                            }
                            country {
                                en
                                ar
                            }
                            cityId
                            city {
                                id
                                region_id
                                name  {
                                    en
                                    ar
                                }
                            }
                            placeType
                            placePictures {
                                name
                                publicUrl
                            }
                            visitCount
                            address {
                                en
                                ar
                            }
                            lat
                            lng
                            strokeWidth
                            radius
                            fillColor
                            strokeColor
                            notifications
                            status
                            rejectionReason
                            createdBy
                            updatedBy
                            createdAt
                            updatedAt
                        }
                        id
                        selectedImage
                        averageTime
                    }
                }
                
                createdBy
                updatedBy
                createdAt
                updatedAt
            }
            startCity {
                id
                region_id
                name {
                    en
                    ar
                }
                center {
                    latitude
                    longitude
                }
            }
            owner {
                id 
                avatar 
                name {
                    en
                    ar
                }
                rate 
            }
            guides {
                id
                fullName
                avatar{
                  publicUrl
                }
            }

            createdBy
            updatedBy
            createdAt
            updatedAt
          }
        }
      `,

      variables: {
        id,
        includeCount
      },
    });

    return response.data.tripFind;
  }
  static async listOwnTrips(ownerId, filter, orderBy, pagination){
    const response = await graphqlClient.query({
      query: gql`
        query LIST_OWN_TRIPS(
          $ownerId: String!
          $filter: TripFilterInput,
          $orderBy: String,
          $pagination: PaginationInput
        ) {
          listOwnTrips(ownerId: $ownerId, filter: $filter, orderBy: $orderBy, pagination: $pagination) {
            pagination {
              isFirstPage
              isLastPage
            }
            rows {
              id
              name {
                  en
                  ar
              }
              planId
              tripTime
              status
              ownerType
              type
              reservationType
              capacity {
                  min
                  max
              }
              price {
                  private
                  group
                  extra
              }
              options {
                  details {
                      en
                      ar
                  }
                  price 
              }
              cancelationReason
              paymentMethod
              captainId
              guidesId
              companyId
              startCityId
              location {
                  latitude
                  longitude
              }
  
              reservationCount
              reservation {
                  id
                  clientId
                  tourGuideId
                  tripId
                  optionIndex
                  qrCode
                  isArrived
                  numberOfPeople
                  status
                  members {
                      name
                      phoneNumber
                  }
                  
                  createdBy
                  updatedBy
                  createdAt
                  updatedAt
              }
              plan {
                  id
                  name {
                      en
                      ar
                  }
                  description {
                      en
                      ar
                  }
                  type
                  numberOfDays
                  enabled
                  programs {
                      places {
                          place {
                              id
                              placeName {
                                  en
                                  ar
                              }
                              description {
                                  en
                                  ar
                              }
                              country {
                                  en
                                  ar
                              }
                              cityId
                              city {
                                  id
                                  region_id
                                  name  {
                                      en
                                      ar
                                  }
                              }
                              placeType
                              placePictures {
                                  name
                                  publicUrl
                              }
                              visitCount
                              address {
                                  en
                                  ar
                              }
                              lat
                              lng
                              strokeWidth
                              radius
                              fillColor
                              strokeColor
                              notifications
                              status
                              rejectionReason
                              createdBy
                              updatedBy
                              createdAt
                              updatedAt
                          }
                          id
                          selectedImage
                          averageTime
                      }
                  }
                  
                  createdBy
                  updatedBy
                  createdAt
                  updatedAt
              }
              startCity {
                  id
                  region_id
                  name {
                      en
                      ar
                  }
                  center {
                      latitude
                      longitude
                  }
              }
              owner {
                  id 
                  avatar 
                  name {
                      en
                      ar
                  }
                  rate 
              }
              guides {
                  id
                  fullName
                  rate
                  avatar {
                      name
                      publicUrl
                  }
              }
              company {
                  id
                  name {
                      en
                      ar
                  }
                  logo {
                      name
                      publicUrl
                  }
                  rate
              }
              createdBy
              updatedBy
              createdAt
              updatedAt
          }
          count
          }
        }
      `,

      variables: {
        ownerId,
        filter,
        orderBy,
        pagination
      },
    });

    return response.data.listOwnTrips;

  }
  // static async list(filter, orderBy, limit, offset) {
  //   const response = await graphqlClient.query({
  //     query: gql`
  //       query PLAN_LIST(
  //         $filter: TripFilterInput
  //         $orderBy: TripOrderByEnum
  //         $limit: Int
  //         $offset: Int
  //       ) {
  //         tripList(
  //           filter: $filter
  //           orderBy: $orderBy
  //           limit: $limit
  //           offset: $offset
  //         ) {
  //           count
  //           rows {
  //             id
  //             name {
  //               en
  //               ar
  //             }
  //             description {
  //               en
  //               ar
  //             }
  //             startCityId
  //             type
  //             numberOfDays
  //             disabled
  //             startCity {
  //               id
  //               name {
  //                 en
  //                 ar
  //               }
  //             }
              
  //             programs {
  //               name 
  //               places {
  //                 place {
  //                   id
  //                   placeName {
  //                     en
  //                     ar
  //                   }
  //                   placePictures {
  //                     id
  //                     name
  //                     publicUrl
  //                     privateUrl
  //                   }
  //                   country {
  //                     en
  //                     ar
  //                   }
  //                   city {
  //                     id
  //                     name {
  //                       en
  //                       ar
  //                     }
  //                		}
  //                 }
  //                 id
  //                 selectedImage
  //                 averageTime
  //               }
  //             }

  //             createdBy
  //             updatedBy
  //             createdAt
  //             updatedAt
  //           }
  //         }
  //       }
  //     `,

  //     variables: {
  //       filter,
  //       orderBy,
  //       limit,
  //       offset,
  //     },
  //   });

  //   return response.data.tripList;
  // }

  static async listAutocomplete(query, limit) {
    const response = await graphqlClient.query({
      query: gql`
        query PLAN_AUTOCOMPLETE(
          $query: String
          $limit: Int
        ) {
          tripAutocomplete(query: $query, limit: $limit) {
            id
            label
          }
        }
      `,

      variables: {
        query,
        limit,
      },
    });

    return response.data.tripAutocomplete;
  }

}
