import Layout from '@/modules/layout/components/layout';
// import Permissions from '@/security/permissions';
import { i18n } from '@/vueI18n';

const NotificationsPage = () =>
  import('@/modules/notifications/components/notifications-page.vue');

export default [
  {
    name: '',
    path: '/:lang?',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'notifications',
        path: 'notifications',
        component: NotificationsPage,
        meta: {
          auth: true,
          title: i18n('metaTitle.notifications')
        },
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },
    ],
  },
];
