import { InvoiceService } from '@/modules/invoice/invoice-service';
import Errors from '@/shared/error/errors';
import firebase from 'firebase/app';


const INITIAL_PAGE_SIZE = 10;

export default {
  namespaced: true,

  state: {
    rows: [],
    count: 0,
    loading: false,
    filter: {},
    pagination: {},
    sorter: {},
    lastRecord: {},
    firstRecord: {},
    disableNext: true,
    disablePrevious: true, 
    table: null,

    invoicesFirstPage: true,
    invoicesLastPage: true,
  },

  getters: {
    loading: (state) => state.loading,

    exportLoading: (state) => state.exportLoading,

    rows: (state) => state.rows || [],

    count: (state) => state.count,

    hasRows: (state, getters) => getters.count > 0,

    orderBy: (state) => {
      const sorter = state.sorter;

      if (!sorter) {
        return null;
      }

      if (!sorter.prop) {
        return null;
      }

      let direction =
        sorter.order === 'descending' ? 'DESC' : 'ASC';

      return `${sorter.prop}_${direction}`;
    },

    filter: (state) => state.filter,

    limit: (state) => {
      const pagination = state.pagination;

      if (!pagination || !pagination.pageSize) {
        return INITIAL_PAGE_SIZE;
      }

      return pagination.pageSize;
    },

    offset: (state) => {
      const pagination = state.pagination;

      if (!pagination || !pagination.pageSize) {
        return 0;
      }

      const currentPage = pagination.currentPage || 1;

      return (currentPage - 1) * pagination.pageSize;
    },

    pagination: (state, getters) => {
      return {
        ...state.pagination,
        total: getters.count,
        showSizeChanger: true,
      };
    },

    selectedRows: (state) => {
      return state.table ? state.table.selection : [];
    },

    lastRecord: (state) => state.lastRecord,
    firstRecord: (state) => state.firstRecord,
    disableNext: (state) => state.disableNext,
    disablePrevious: (state) => state.disablePrevious,

    invoicesFirstPage: (state) => state.invoicesFirstPage,
    invoicesLastPage: (state) => state.invoicesLastPage,

  },

  mutations: {
    RESETED(state) {
      state.rows = [];
      state.count = 0;
      state.loading = false;
      state.filter = {};
      state.pagination = {};
      state.sorter = {};
      if (state.table) {
        state.table.clearSelection();
      }
    },

    UNSELECT_ALL(state) {
      if (state.table) {
        state.table.clearSelection();
      }
    },

    TABLE_MOUNTED(state, payload) {
      state.table = payload;
    },

    PAGINATION_CHANGED(state, payload) {
      state.pagination = payload || {};
    },

    PAGINATION_CURRENT_PAGE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: payload || 1,
        pageSize:
          previousPagination.pageSize || INITIAL_PAGE_SIZE,
      };
    },

    PAGINATION_PAGE_SIZE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: previousPagination.currentPage || 1,
        pageSize: payload || INITIAL_PAGE_SIZE,
      };
    },

    SORTER_CHANGED(state, payload) {
      state.sorter = payload || {};
    },

    // FETCH_STARTED(state, payload) {
    //   state.loading = true;
    //   if (state.table) {
    //     state.table.clearSelection();
    //   }

    //   state.filter =
    //     payload && payload.filter ? payload.filter : {};
    //   state.pagination =
    //     payload && payload.keepPagination
    //       ? state.pagination
    //       : {};
    // },
    FETCH_STARTED(state) {
      state.loading = true;
    },
    FETCH_SUCCESS(state, payload) {
      state.loading = false;
      state.rows = payload.rows;
      state.count = payload.count;
    },

    FETCH_ERROR(state) {
      state.loading = false;
      state.rows = [];
      state.count = 0;
    },

    EXPORT_STARTED(state) {
      state.exportLoading = true;
    },

    EXPORT_SUCCESS(state) {
      state.exportLoading = false;
    },

    EXPORT_ERROR(state) {
      state.exportLoading = false;
    },
    SET_LAST_RECORD(state){
      state.lastRecord = state.rows[state.rows.length - 1]
    },
    SET_FIRST_RECORD(state){
      state.firstRecord = state.rows[0]
    },
    DISABLE_NEXT(state, payload){
      state.disableNext = payload
    },
    DISABLE_PREVIOUS(state, payload){
      state.disablePrevious = payload
    },
    FETCH_SUCCESS_DISABLE_PAGINATION(state){
      state.loading = false
    },
    FINISH_LOADING(state){
      state.loading = false
    },
    FETCH_TRIPS_TRANSACTIONS_SUCCESS(state, payload){
      state.tripsTransactions = payload.tripsTransactions;
      state.countTripsTransactions = payload.countTripsTransactions;
      state.tripsTransactionsFirstPage = payload.tripsTransactionsFirstPage,
      state.tripsTransactionsLastPage = payload.tripsTransactionsLastPage        
    },
    FETCH_WALLET_TRANSACTIONS_SUCCESS(state, payload){
      state.walletTransactions = payload.walletTransactions;
      state.countWalletTransactions = payload.countWalletTransactions,
      state.walletTransactionsFirstPage = payload.walletTransactionsFirstPage
      state.walletTransactionsLastPage = payload.walletTransactionsLastPage
    },
    FETCH_RECENT_TRANSACTIONS_SUCCESS(state, payload){
      state.recentTransactions = payload
    },
    FETCH_TRIPS_TRANSACTIONS_STARTED(state){
        state.loadingTripsTransactions = true
    },
    FETCH_WALLET_TRANSACTIONS_STARTED(state){
      state.loadingWalletTransactions = true
    },
    FINISH_WALLET_TRANSACTIONS(state){
      state.loadingWalletTransactions = false
    },
    FINISH_TRIPS_TRANSACTIONS(state){
      state.loadingTripsTransactions = false
    },
  },

  actions: {
    doUnselectAll({ commit }) {
      commit('UNSELECT_ALL');
    },

    doMountTable({ commit }, table) {
      commit('TABLE_MOUNTED', table);
    },

    async doReset({ commit, dispatch }) {
      commit('RESETED');
      return dispatch('doFetch');
    },

  

    doChangePagination(
      { commit, getters, dispatch },
      pagination,
    ) {
      commit('PAGINATION_CHANGED', pagination);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },

    doChangePaginationPageSize(
      { commit, getters, dispatch },
      pageSize,
    ) {
      commit('PAGINATION_PAGE_SIZE_CHANGED', pageSize);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },

    doChangePaginationCurrentPage(
      { commit, getters, dispatch },
      currentPage,
    ) {
      commit(
        'PAGINATION_CURRENT_PAGE_CHANGED',
        currentPage,
      );
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },

    doChangeSort({ commit, getters, dispatch }, sorter) {
      commit('SORTER_CHANGED', sorter);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },

    async doFetch(
      { commit },
      {
        filter, 
        orderBy,
        pagination
      }
      ) {
      try {
        commit('FETCH_STARTED');
        const response = await InvoiceService.list(
            filter, 
            orderBy, 
            pagination)

        debugger
        commit('FETCH_SUCCESS', {
          rows: response.rows,
          count: response.count,
        });

        commit('SET_LAST_RECORD')
        commit('SET_FIRST_RECORD')
      } catch (error) {
        Errors.handle(error);
        commit('FETCH_ERROR');
      }
    },

    setDisableNext: (async ({getters, commit}, payload) => {
      console.log('payload', payload);
      const createdAt = new Date(getters.lastRecord.createdAt); 
      const createdAtTimestamp = firebase.firestore.Timestamp.fromDate(createdAt)
      console.log(createdAt);
      console.log(createdAtTimestamp);

      commit('FETCH_STARTED');
      const collection = 
      await firebase.firestore().collection('place')
        .where('status', '==', payload.status)
        .orderBy('createdAt', 'desc')          
        .startAfter(createdAtTimestamp) 
        .limit(1)
        .get()
        // console.log('next length', collection.docs.length);
        // collection.docs.forEach((doc) => {
        //   console.log('doc after last', doc.data());
        // })


        if(collection.docs.length > 0){          
          commit('DISABLE_NEXT', false)
        }
        else{
          commit('DISABLE_NEXT', true)
        }
        commit('FETCH_SUCCESS_DISABLE_PAGINATION')
    }),

    setDisablePrevious: (async ({getters, commit}, payload) => {
      console.log('getters.firstRecord ==>', getters.firstRecord);
      const createdAt = new Date(getters.firstRecord.createdAt);      
      const createdAtTimestamp = firebase.firestore.Timestamp.fromDate(createdAt)          
      commit('FETCH_STARTED');
      const collection = await firebase.firestore().collection('place')
        .where('status', '==', payload.status)
        .orderBy('createdAt', 'desc')          
        .endBefore(createdAtTimestamp)
        .limitToLast(1).get()

        if(collection.docs.length > 0){
          commit('DISABLE_PREVIOUS', false)
        }
        else{
          commit('DISABLE_PREVIOUS', true)
        }
        commit('FETCH_SUCCESS_DISABLE_PAGINATION')
    }),
    finishLoading({commit}){
      commit('FINISH_LOADING')
    },
  },
};
