import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-branch';
import graphqlGuide from '@/shared/graphql/client-guide';
import { storeAsync } from '@/app-module';
import firebase from 'firebase/app'
import 'firebase/firestore';

export class BranchService {
  static async enable(id) {
    return this._changeStatus(id, false);
  }

  static async disable(id) {
    return this._changeStatus(id, true);
  }

  static async _changeStatus(id, disabled) {
    let currentUser = storeAsync().getters['auth/currentUser']

    let batch = firebase.firestore().batch();
    
    let docRef = firebase.firestore().doc(`branch/${id}`) 
    batch.update(docRef, {
      disabled,
      updatedBy: currentUser.id,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
    
    return await batch.commit()
  }

  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation BRANCH_CREATE($data: BranchInput!) {
          branchCreate(data: $data) {
            id
          }
        }
      `,

      variables: {
        data,
      },
    });

    return response.data.branchCreate;
  }

  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation PLAN_UPDATE($id: String!, $data: BranchInput!) {
          branchUpdate(id: $id, data: $data) {
            id
          }
        }
      `,

      variables: {
        id,
        data,
      },
    });

    return response.data.branchUpdate;
  }

  static async destroyAll(ids) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation PLAN_DESTROY($ids: [String!]!) {
          branchDestroy(ids: $ids)
        }
      `,

      variables: {
        ids,
      },
    });

    return response.data.branchDestroy;
  }

  static async import(values, importHash) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation PLAN_IMPORT(
          $data: BranchInput!
          $importHash: String!
        ) {
          branchImport(data: $data, importHash: $importHash)
        }
      `,

      variables: {
        data: values,
        importHash,
      },
    });

    return response.data.branchImport;
  }

  static async find(id,companyId) {
    const response = await graphqlClient.query({
      query: gql`
        query branchFind($companyId: String!, $id: String!) {
          branchFind(companyId: $companyId, id: $id) {
            id 
        name {
            en
            ar
        }
        phoneNumber
        address {
            en
            ar
        }
        cityId
        guides {
            id 
            fullName
            avatar {
                name
                publicUrl
            }
            phoneNumber
        }
        
            createdBy
            updatedBy
            createdAt
            updatedAt
          }
        }
      `,

      variables: {
        id,
        companyId
      },
    });

    return response.data.branchFind;
  }

  static async listBranchGuides(branchId,orderBy,sortBy) {
    const response = await graphqlGuide.query({
      query: gql`
        query LIST_BRANCH_GUIDES($branchId: String!, $orderBy: String, $sortBy: SortByType) {
          listBranchGuides(branchId: $branchId, orderBy: $orderBy, sortBy: $sortBy) {
            count
        rows {
           id
            fullName
            avatar {
                name
                publicUrl
            }
            phoneNumber
            createdAt
        }
          }
        }
      `,

      variables: {
        branchId,
        orderBy,
        sortBy
      },
    });
    return response.data.listBranchGuides;
  }

  static async list(companyId) {
    const response = await graphqlClient.query({
      query: gql`
        query BRANCH_LIST(
          $companyId: String!         
        ) {
          branchList(
            companyId: $companyId            
          ) {
            count
            rows {
              id    
              name {
                en
              }   
              createdAt
              address{
                en
              }    
              updatedAt   

            }
          }
        }
      `,
// # name {
//               #   en
//               #   ar
//               # }
      variables: {
        companyId,
       
      },
    });

    return response.data.branchList;
  }

  static async listAutocomplete(query, limit) {
    const response = await graphqlClient.query({
      query: gql`
        query PLAN_AUTOCOMPLETE(
          $query: String
          $limit: Int
        ) {
          branchAutocomplete(query: $query, limit: $limit) {
            id
            label
          }
        }
      `,

      variables: {
        query,
        limit,
      },
    });

    return response.data.branchAutocomplete;
  }

}
