const ar = {
    common: {
      or: 'أو',
      cancel: 'إلغاء',
      reset: 'إلغاء',
      save: 'حفظ',
      search: 'بحث',
      edit: 'تعديل',
      remove: 'إزالة',
      new: 'جديد',
      export: 'تصدير إلى Excel',
      noDataToExport: 'لا توجد بيانات للتصدير',
      import: 'استيراد',
      discard: 'تجاهل',
      yes: 'نعم',
      no: 'لا',
      pause: 'توقف',
      areYouSure: 'هل أنت متأكد للإزالة ؟',
      view: 'عرض',
      destroy: 'حذف',
      mustSelectARow: 'يجب إختيار صف',
      confirm: 'تأكيد ',
      submit:'تأكيد',
      verify: 'تحقق',
      phoneNumber: 'رقم الهاتف',
      emailAddress: 'البريد الإلكتروني',
      create: 'إنشاء',
      next : 'التالى' ,
      add: 'إضافة',
      done: 'Done',
    },

    tables:{
      admins:{
        firstName:'الأسم الأول ',
        lastName:'الأسم الأخير',
        phoneNumber:'رقم الجوال',
        email:'البريد الإلكتروني',
        role:'الدور',
        createdAt:'إنشاء فى',
        status:'الحالة',
        action:'الامر ',
        placeName:'إسم الموقع',
        search:'بحث' ,
        submit:'تأكيد'
      },
      users:{
  
      }
  
    },


    companyProfile : {

      branches : {
        title :'الفروع الخاصة بي' ,
        addNewBranch: 'اضف فرع جديد',
        branchName : 'اسم الفرع ' ,
        onlineTour : 'المرشديين  السياحيين النشيطين  ' ,
        offlineTour : ' المرشديين السياحيين الغير نشيطين' ,
        CompanyProgrames : 'برامج الشركة' ,
        createNewProgram : 'إنشاء برنامج جديد' ,
        ViewPrograme:'عرض تفاصيل البرنامج' ,
        editProgram :'تعديل البرنامج',
        ProgrameDetials :'تفاصيل البرنامج' ,
        ReorderPlaces : 'إعادة ترتيب المواقع' ,
        AddNewPlaces: 'إضافة مواقع جديدة' ,
        SaveChanges : 'حفظ التعديلات ' , 
        areSureDelete : 'هل انت متاكد انك تريد إزالة  هذا البرنامج ؟' ,
        yesDelete : 'نعم , إزالة' ,
        noCancel : 'لا , إلغاء' ,
        city : 'المدينة' ,
        PleaseSelectcity : 'من فضلك إختر مدينة' ,
        date : 'التاريخ' , 
        PleaseSelectDate : 'من فضلك إختر تاريخ' ,
        DorepeatTrip : 'هل تريد إعادة هذه الرحلة ؟' ,
        repeatTrip : 'إعادة الرحلة ' ,
        Next : 'التالى' ,
        Save : 'حفظ' ,
        GREAT : 'تهانينا !' ,
        duration: 'المدة الزمنية' ,
        TripDuration: 'المدة الزمنية للرحلة : ' ,
        DateAndTime: 'التاريخ والوقت :' ,
        Successfullycreated : 'تم إنشاء برنامج بنجاح ',
        branchNumber:'رقم الفرع'
      }
  
   
    },

    metaTitle: {
      mainTitle: ' لوحة التحكم ',

      signIn:'سارية | تسجيل الدخول ',
      signUp:' سارية | إنشاء حساب ',
      forgetPass: ' سارية | نسيت كلمة المرور ',
      emailUnverified:' سارية | تأكيد الحساب الشخصي',
      emptyPermissions:' سارية | إذن الدخول للحساب ',



      authDahsboard:'سارية | لوحة التحكم ' ,
      authEdit: ' سارية | تعديل الملف الشخصي ' ,
      authSetting: ' سارية | الإعدادات  ' , 
  
      admins:' سارية | قاعدة البيانات | المشرفين ',
      newAdmins:' سارية | قاعدة البيانات | المشرفين | مشرف  جديد ',
      editAdmin:' سارية | قاعدة البيانات | المشرفين | تعديل ',
      viewAdmin:' سارية | قاعدة البيانات | المشرفين | عرض  ',
  
      users:' سارية | قاعدة البيانات | العملاء ',
      viewUser:' سارية | قاعدة البيانات | العملاء | عرض  ',
  
      place:'سارية | موقع  ' ,
      newPlace: ' سارية | موقع | إضافة موقع ' ,
      editPlace:' سارية | موقع | تعديل '  ,
      viewPlace:' سارية | موقع | عرض ' ,
  
      newNotification:' سارية | موقع | الإشعارات '  ,

      transaction: 'سارية | عملية تجارية'
  
    },
  
  
    app: {
      title: 'سارية',
      ConfirmEmailAddress : 'تأكيد عنوان البريد الإلكترونى'
    },
  
    entities: {
        sideMenu:{
            dashboard:'لوحة التحكم',
            database:'قاعدة البيانات',
            users:'العملاء',
            companyProfile:'الملف الشخصي ',
            TourGuides:'المرشدين السائحين',
            admins:'المشرفين',
            addLocationZone:'إضافة موقع ',
            RegisterPlaces:'المواقع المسجلة',
            Setting:'الإعدادات',
            changeLanguage:'تغيير اللغة',
            changeTheme:'تغيير لون الإطار ',
            trips: 'الرحلات',
            plans: 'الخطط',
            branches: 'الفروع'

        },
        dashboard:{
            projection:'تخطيط',
            visitors:'المرشدين السياحيين',
            AdminsChart:'المشرفين' ,
            usersTable:'جدول العملاء',
            adminsTable:'جدول المشرفين',
            addNewAdmin:'إضافة مشرف جديد',
            tourGuideTable:'جدول المرشدين السياحيين',
            addNewtourGuide:'إضافة مرشد جديد',
            registerPlaces:'المواقع المسجلة',
            chooseLanguage:'إختار اللغه',
            profile:'الملف الشخصي',
            Fname:'الإسم الاول',
            Lname:'الإسم الأخير',
            phoneNumber:'رقم الجوال',
            email:'البريد الإلكتروني'
            },
            splash:{
                welcome:'مرحبا بك فى لوحة التحكم الخاصة بسارية',
                getStarted:'هيا بنا نبدأ',
                LOGIN:'تسجيل الدخول',

                verifyPhone:'تأكيد رقم الجوال',
                enterPin:'من فضلك أدخل الكود المرسل إليك',
                signIn: 'يرجى تسجيل الدخول إلى حسابك عن طريق رقم الهاتف أو عنوان البريد الإلكتروني',
                phoneSign:'من فضلك قم بإدخال رقم الجوال لتسجيل الدخول ',
                emailSign: 'يرجى إدخال عنوان بريدك الإلكتروني لتسجيل الدخول إلى حسابك',
                welcBack:'مرحبا بالرجوع  ',
                loginAccount:'تسجيل الدخول الى حسابك الشخصي',
                pleaseValid:'من فضلك ادخل البريد الإلكتروني ورقم المرور لستجيل الدخول',
                orLoginWith:'أو قم بتسجيل الدخول من خلال ',
                dontHaveAccount:' ليس لديك حساب شخصي ؟',
              },
              signup:{
                linkeEmail:'إنشاء بريد إلكتروني وكلمة مرور' ,
                createNew:'إنشاء حساب جديد',
                pleaseEnter:'ما هو عنوان بريدك الإلكترونى؟' ,
                signUpUp:'إنشاء حساب جديد',
                enterphoneUp:'من فضلك قم بإدخال رقم الجوال لإنشاء حساب ',
                loginAccount:'تسجيل الدخول لحسابك',
                signUp:'تسجل ',
                alreadyMember:' بالفعل عضو ؟',
                signIn:' تسجيل دخول',
                join: 'تسجل',
                password:"كلمة السر",
                passwordHint:"أدخل كلمة السر"
              },
      place: {
        name: 'place',
        label: 'المواقع',
        menu: 'المواقع',
        ADD:'إضافة',
        Next:'التالي',
        placeholder:"أدخل موقعك الحالى " ,
        exporterFileName: 'place_export',
        list: {
          menu: 'المواقع',
          title: 'المواقع',
        },
        create: {
          success: 'تم حفظ الموقع بنجاح ',
        },
        update: {
          success: 'تم حفظ الموقع بنجاح',
        },
        destroy: {
          success: 'تم مسح الموقع بنجاح',
        },
        destroyAll: {
          success: 'تم حذف المكان (المواقع) بنجاح',
        },
        edit: {
          title: 'تعديل الموقع',
        },
        fields: {
          "id": 'رقم المكان',
          'lat': 'خط الطول',
          'lng': 'خط العرض ',
          'radiusRange': 'نصف القطر',
          'radius': 'نصف القطر ',
          'color': 'لون',
          'strokeColor': 'لون الدائرة',
          'strokeWidthRange': 'عرض الإطار',
          'strokeWidth': 'سمك الدائرة',
          'fillColor': 'اللون السائد',
          "createdAt": 'إنشاء فى',
          "updatedAt": 'تعديل فى ',
          "createdAtRange": 'أنشئت في',
          'placeName':'إسم الموقع',
          'city':'المدينة',
          'country':'البلد'
        },
        enumerators: {
  
        },
        new: {
          title: 'إضافة موقع',
        },
        view: {
          title: 'عرض الموقع',
        },
        importer: {
          title: 'استيراد الأماكن',
          fileName: 'place_import_template',
          hint:
            'Files/Images columns must be the URLs of the files separated by space.',
        },
      },
  
      notification: {
        name: 'notification',
        label: 'Notifications',
        menu: 'Notifications',
        exporterFileName: 'notification_export',
        list: {
          menu: 'Notifications',
          title: 'Notifications',
        },
        create: {
          success: 'تم حفظ الإشعار بنجاح',
        },
        update: {
          success: 'تم حفظ الإشعار بنجاح',
        },
        destroy: {
          success: 'تم مسح الإشعار بنجاح',
        },
        destroyAll: {
          success: 'Notification(s) deleted successfully',
        },
        edit: {
          title: 'تعديل الإشعار',
        },
        fields: {
          id: 'رقم الإشعار',
          'title': 'عنوان الإشعار',
          'message': 'محتوى الإشعار',
          'image': 'صوره الإشعار (إختياري)',
          'name': 'اسم الإشعار (إختياري)',
          'placeId': 'PlaceId',
          createdAt: 'انشاء فى ',
          updatedAt: 'تعديل فى ',
          createdAtRange: 'Created at',
        },
        enumerators: {
  
        },
        new: {
          title: ' الإشعارات',
        },
        view: {
          title: 'عرض الإشعار',
        },
        importer: {
          title: 'Import Notifications',
          fileName: 'notification_import_template',
          hint:
            'Files/Images columns must be the URLs of the files separated by space.',
        },
      },

      plan: {
        name: 'خطة',
        label: 'الخطط',
        menu: 'الخطط',
        exporterFileName: 'plan_export',
        list: {
          title: 'الخطط',
          menu: 'الخطط',
          hint: 'تقدم سارية خطتي A و B يمكنك اختيارهما أو يمكنك تخصيص خطتك الخاصة.',
          saryahPlans: 'خطط سارية',
          myPlans: 'خططي',
        },
        days: {
          dayOne: 'اليوم الأول',
          dayTwo: 'اليوم الثاني',
          dayThree: 'اليوم الرابع',
          dayFour: 'اليوم الرابع',
          dayFive: 'اليوم الخامس',
          daySix: 'اليوم السادس',
          daySeven: 'اليوم السابع',
        },
        tripDays: {
          oneDay: 'يوم واحد',
          twoDays: 'يومين',
          ThreeDays: 'ثلاثة أيام',
          FourDays: 'أربعة أيام',
          FiveDays: 'خمسة أيام',
          SixDays: 'ستة أيام',
          SevenDays: 'سبعة أيام',
          moreThanDay: 'أكثر من يوم',
        },
        form: {
          enterInformation: 'أدخل المعلومات التالية لإنشاء خطتك',
          durationTitle: 'يرجى تحديد مدة الرحلة',
          pleaseDragThePlaces: 'الرجاء سحب الأماكن التي تريد إضافتها في خطتك',
          placesInThisCity: 'أماكن في هذه المنطقة',
          yourPlan: 'خطتك',
          dragHere: 'اسحب الأماكن هنا',
          pleaseSelectPhotoToEachPlace: 'الرجاء اختيار الصور والوقت لكل مكان',
          selectPhotoToThisPlace: 'حدد صور لهذا المكان',
          editPlan: 'تعديل هذه الخطة',
          deletePlan: 'حذف هذه الخطة',
          reorderPlan: 'إعادة ترتيب الأماكن',
          disablePlan: 'تعطيل هذه الخطة',
          placesInThisPlan: 'الأماكن في هذه الخطة',
          reordersPlaces: 'إعادة ترتيب الأماكن في هذه الخطة',
          setTimeToThisPlace: 'حدد الوقت لهذا المكان',
          pleaseSetTimeToThisPlace: 'يرجى تحديد الوقت بالدقائق لهذا المكان',
        },
        disable: {
          success: 'تم تعطيل الخطة بنجاح',
        },
        enable: {
          success: 'تم تفعيل الخطة بنجاح',
        },
        create: {
          success: 'تم حفظ الخطة بنجاح',
        },
        update: {
          success: 'تم حفظ الخطة بنجاح',
        },
        destroy: {
          success: 'تم حذف الخطة بنجاح',
        },
        destroyAll: {
          success: 'تم حذف الخطط بنجاح',
        },
        edit: {
          title: 'تعديل الخطة',
        },
        fields: {
          id: 'Id',
          region: 'المنطقة',
          city: 'المدينة',
          numberOfPlans: 'عدد الخطط',
          dateOfCreation: 'تاريخ الإنشاء',
          createdAt: 'إنشاء في',
          updatedAt: 'تحديث في',
          createdAtRange: 'إنشاء في',

          name: 'اسم الخطة',
          description: 'الوصف',
          startCityId: 'بداية المدينة',
          startCity: 'بداية المدينة',
          type: 'نوع الخطة',
          programs: 'البرامج',
          numberOfDays: 'عدد الإيام',
        },
        enumerators: {
          planType: {
            default: 'خطة افتراضية',
            custom: 'خطة مخصصة',
          },
        },
        placeholder: {
          chooseRegions: 'أختر المناطق',
          chooseRegion: 'أختر المنطقة',
          chooseCities: 'أختر المدن',
          chooseCity: 'أختر المدينة',
        },
        new: {
          title: 'إضافة خطة جديدة',
          shortTitle: 'إضافة خطة',
        },
        view: {
          title: 'عرض الخطة',
          title2: 'يمكنك تعديل خطتك وحذفها وإعادة ترتيبها',
          moreThan:'أكثر من +4 أماكن في هذه الخطة',
          visitorStayMins: 'Visitors typically stay {0} mins',
          visitorStayHour: 'Visitors typically stay {0} hour',
          visitorStayHourMins: 'Visitors typically stay {0} hour and {1} mins',
        },
      },
    },
  
    auth: {
      profile: {
        title: 'تعديل الملف الشخصي',
        success: 'تم تعديل الملف الشخصي بنجاح',
      },
      fields:{
        confirmPassword: "أكد رقمك السري"
      },

      viewTour:{
        Completed:'اكتمل',
        NotCompleted:'لم يكتمل' ,
        Total:'الإجمالي',
        Accepted:'موافق',
        Missed:'غير موافق'
      },

      completeProfile:{
        title:'إكمال ملفك الشخصي',
        subTitle:'من فضلك قم بإكمال الملف الشخصي',
        CompanyName:'اسم الشركة',
        Languages:'اللغات',
        Description:'الوصف',
        Authority:'سلطة توقيع الاتفاقيات القانونية',
        confirmAuthority:'تأكد من أن لديك الصلاحية لتوقيع اتفاقيات قانونية نيابة عن شركتك',
        firstchoose:'أنا المالك ولدي السلطة اللازمة لإلزام شركتي بالاتفاقيات القانونية',
        secondchoose:'لقد منحتني شركتي سلطة ربطها بالاتفاقيات القانونية',
        Country:'الدولة',
        verificationContact:'الرجاء إخبارنا المزيد عن جهة إتصال التحقق',
        City:'المدينة',
        HeadquatersPhone:'رقم الجوال الرئيسي',
        WebsiteLink:'رابط Website',
        Yourworkemailaddress:'عنوان بريدك الإلكتروني',
        FacebookLink:'رابط Facebook',
        TwitterLink:'رابط Twitter',
        InstagramLink:'رابط Instagram',
        NEXT:'التالي',
        chooseCountry: 'Choose Country',
        chooseCity: 'Choose City',
        chooseLanguages: 'Choose Languages',
        companyNamePlaceholder: 'Enter your company name',
        descriptionPlaceholder: 'Tell us about your company',
        FirstName:'Fist name',
      LastName: 'Last name'
      },
      companyBranch:{
        title:'إكمال ملفك الشخصي',
        subTitle:'من فضلك أدخل فروع شركتك',
        branchMap:'ضبط موقع الشركة على الخريطة',
        verificationContact:'الرجاء إخبارنا المزيد عن جهة إتصال التحقق',
        Country:'الدولة',
        City:'المدينة',
        Accept:'تأكيد',
        Decline:'إلغاء',
        BranchNumber:'رقم جوال الفرع',
        BranchSize:'حجم الفرع',
        BranchFile:'إدخال ملفات الفرع',
        saveAddNew:'حفظ وإضافة فرع جديد',
        NEXT:'التالى '
      },
      createAnAccount: 'إنشاء حساب جديد',
      rememberMe: 'تذكرنى',
      forgotPassword: {
        label: 'هل نسيت كلمة المرور؟',
        title: 'نسيت كلمة المرور؟',
        message: `أدخل عنوان بريدك الإلكتروني الذي تستخدمه لحسابك ، وسنرسل لك رابطًا لإعادة تعيين كلمة المرور`,
        emailAddress: 'أدخل عنوان بريدك الإلكتروني هنا',
      },
      signin: 'تسجيل الدخول',
      signinPhone:'أدخل رقم الجوال',
      signinEmailPlaceholder:'الرجاء إدخال عنوان البريد الإلكتروني الخاص بك',
      passwordPlaceholder:'أدخل كلمة المرور',
      signup: 'إنشاء حساب ',
      signout: 'تسجيل الخروج' ,
      alreadyHaveAnAccount:
        'بالفعل لدى حساب ؟ تسجيل دخول',
      signinWithAnotherAccount:
        'تسجيل دخول بحساب اخر ',
      emailUnverified: {
        message: `قم بتاكيد بريد الإلكتروني  <strong>{0}</strong> للاستكمال`,
        submit: `إعادة إرسال بريد الكترونى`,
        verifyAccount:'تأكيد حسابك الشخصي'
      },
      emptyPermissions: {
        message: `ليس لديك صلاحية الان يرجى الانتظار لحين موافقة الادمن`,
        message2: 'من فضلك انتظر التأكيد من مشرف ساريه',
      },
      AccountPermission: 'طلب معلق' ,
      forgetPassword:'نسيت كلمة المرور؟',
      forgetText:'أدخل عنوان بريدك الإلكتروني الذي تستخدمه لحسابك ، وسنرسل لك رابط إعادة تعيين كلمة المرور' ,
      cancel:'إالغاء',
      passwordResetEmail: {
        message: 'إرسال كلمة المرور ',
        error: `البريد الإلكتروني غير معروف `,
      },
      passwordReset: {
        message: 'إعادة تعين كلمة المرور',
      },
      emailAddressVerificationEmail: {
        error: `البريد الإلكتروني غير معروف `,
      },
      verificationEmailSuccess: `تم إرسال البريد الاكترونى التعرفى `,
      passwordResetEmailSuccess: `تم إرسال كلمة المرور بنجاح`,
      passwordResetSuccess: `تم تعديل كلمة المرور بنجاح`,
      verifyEmail: {
        success: 'تم تاكيد البريد الإلكتروني بنجاح',
        message:
          'لحظة ، يتم التحقق من بريدك الإلكتروني ...',
      },
    },
  
    roles: {
      owner: {
        label: 'مالك',
        description: 'Full access to all resources',
      },
      editor: {
        label: 'محرر',
        description: 'Edit access to all resources',
      },
      viewer: {
        label: 'مشاهد',
        description: 'View access to all resources',
      },
      auditLogViewer: {
        label: 'مشاهدة سجل التدقيق',
        description: 'Access to view audit logs',
      },
      iamSecurityReviewer: {
        label: 'مشاهدة الصلاحيات',
        description: `Full access to manage users roles`,
      },
      entityEditor: {
        label: 'Entity Editor',
        description: 'Edit access to all entities',
      },
      entityViewer: {
        label: 'Entity Viewer',
        description: 'View access to all entities',
      },
      placeEditor: {
        label: 'محرر مواقع',
        description: 'Edit access to Places',
      },
      placeViewer: {
        label: 'مشاهد مواقع',
        description: 'View access to Places',
      },
      notificationEditor: {
        label: 'محرر إشعارات',
        description: 'Edit access to Notifications',
      },
      notificationViewer: {
        label: 'مشاهد إشعارات',
        description: 'View access to Notifications',
      },
    },
  
    iam: {
      title: 'إدارة الهوية والوصول',
      menu: 'IAM',
      disable: 'تعطيل',
      disabled: 'عطًل',
      enabled: 'فعًل',
      enable: 'تفعيل',
      doEnableSuccess: 'تم تفعيل المستخدم بنجاح',
      doDisableSuccess: 'تم تعطيل المستخدم بنجاح',
      doDisableAllSuccess: 'تم تعطيل المستخدمين بنجاح',
      doEnableAllSuccess: 'تم تفعيل المستخدمين بنجاح',
      doAddSuccess: 'تم حفظ المستخدم (المستخدمين) بنجاح',
      doUpdateSuccess: 'تم حفظ المستخدم بنجاح',
      viewBy: 'عرض بواسطة',
      users: {
        name: 'users',
        label: 'المستخدمين',
        exporterFileName: 'users_export',
        doRemoveAllSelectedSuccess:
          'تمت إزالة الأذونات بنجاح',
      },
      roles: {
        label: 'الأدوار',
        doRemoveAllSelectedSuccess:
          'تمت إزالة الأذونات بنجاح',
      },
      edit: {
        title: 'تعديل المستخدم',
        titleGuide:'تعديل ملف المرشد السياحي',
      },
      new: {
        title: 'مشرف جديد',
        titleGuide: 'مرشد سياحى جديد',
        titleUser: 'مستخدم جديد',
        emailsHint:
          'إفصل بين عناوين البريد الإلكتروني المتعددة باستخدام الحرف الفاصلة',
      },
      view: {
        title: 'عرض ملف المستخدم',
        titleGuide:'عرض ملف المرشد السياحي',
        activity: 'نشاطات',
      },
      importer: {
        title: 'استيراد المستخدمين',
        fileName: 'users_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space. Relationships must be the ID of the referenced records separated by space. Roles must be the role ids separated by space.',
      },
      errors: {
        userAlreadyExists:
          'لمستخدم بهذا البريد الإلكتروني موجود بالفعل',
        userNotFound: 'المستخدم ليس موجود',
        disablingHimself: `لا يمكنك تعطيل نفسك`,
        revokingOwnPermission: `لا يمكنك إلغاء إذن مالكك الخاص`,
      },
    },
  
    user: {
      fields: {
        id: 'رقم المستخدم',
        authenticationUid: 'مصادقة Uid',
        avatars: 'الصورة',
        email: 'البريد الإلكتروني',
        emails: 'البريد الإلكتروني',
        fullName: 'الإسم كامل',
        firstName: 'الإسم الاول',
        lastName: 'الإسم الاخير',
        status: 'الحالة',
        disabled: 'معطل',
        phoneNumber: 'رقم الجوال',
        countryCode: 'كود البلد',
        lang:'اللغة',
        role: 'الدور',
        createdAt: 'انشاء فى ',
          updatedAt: 'تعديل فى ',
        roleUser: 'دور / المستخدم',
        roles: 'الصلاحيات',
        accountType: 'نوع المستخدم',
        createdAtRange: 'أنشئت في',
        password: 'كلمة المرور',
        rememberMe: 'تذكرني',
      },
      enabled: 'مفعل',
      disabled: 'معطل',
      validations: {
        // eslint-disable-next-line
        email: 'البريد الإلكتروني $ {value} غير صالح',
      },
    },
  
    auditLog: {
      menu: 'سجلات التدقيق',
      title: 'سجلات التدقيق',
      exporterFileName: 'audit_log_export',
      entityNamesHint:
        'افصل بين الكيانات المتعددة باستخدام الحرف الفاصلة',
      fields: {
        id: 'رقم السجل',
        timestampRange: 'فترة',
        entityName: 'كيان',
        entityNames: 'كيانات',
        entityId: 'Entity ID',
        action: ' الأوامر',
        values: 'قيم',
        timestamp: 'تاريخ',
        createdByEmail: 'البريد الإلكتروني للمستخدم',
      },
    },
    settings: {
      title: 'الإعدادات',
      menu: 'الإعدادات',
      save: {
        success:
          'تم حفظ الإعدادات بنجاح. ستتم إعادة تحميل الصفحة في {0} ثانية لتصبح التغييرات نافذة المفعول.',
      },
      fields: {
        theme: 'Theme',
      },
      colors: {
        default: 'Default',
        cyan: 'Cyan',
        'geek-blue': 'Geek Blue',
        gold: 'Gold',
        lime: 'Lime',
        magenta: 'Magenta',
        orange: 'Orange',
        'polar-green': 'Polar Green',
        purple: 'Purple',
        red: 'Red',
        volcano: 'Volcano',
        yellow: 'Yellow',
      },
    },

    errors: {
      required: 'هذا الحقل مطلوب',
      emailRequired: 'البريد الإلكتروني مطلوب',
      passwordRequired: 'كلمة المرور مطلوبة',
      invalidEmail: 'الرجاء إدخال بريد إلكتروني صحيح',
  
      rolesRequired: 'الصلاحية مطلوبة',
      vehicleTypeRequired: 'نوع المركبة مطلوب',
      vehicleNumberRequired: 'رقم المركبة مطلوب',
      vehicleNumberIsInteger:
        'رقم المركبة يجب ان يكون عددً صحيحاً',
      SeatsNumberRequired: 'عدد المقاعد مطلوب',
      SeatsNumberIsInteger:
        'عدد المقاعد يجب ان يكون عددً صحيحاً',
      vehiclePlateRequired: 'لوحة المركبة مطلوبة',
      threeEntriesMustBeText: 'يجب ان يكون اول ٣ مدخلات حروف',
      phoneRequired: 'رقم الجوال مطلوب',
      regionRequired: 'المنطقة مطلوبة',
      busRequired: 'الحافلة مطلوبة',
      nameRequired: 'المسمي مطلوب',
      numOfPointsRequired: 'عدد النقاط مطلوب',
      numOfTripsRequired: 'عدد الرحلات مطلوب',
      percentageOfTripsRequired:
        'نسبة الخصم على الرحلة مطلوب',
      maxNumIs_1: 'يرجى استخدام أقصى عدد هو 1',
      expirationDateRequired: 'تاريخ إنتهاء الصلاحية مطلوب',
      lngRequired: 'خط الطول مطلوب',
      latRequired: 'خط العرض مطلوب',
      lngMustBeNum: 'يجب أن يكون خط الطول رقماً فقط',
      latMustBeNum: 'يجب أن يكون خط العرض رقماً فقط',
      startDateRequired: 'تاريخ البداية مطلوب',
      endDateRequired: 'تاريخ الإنتهاء مطلوب',
      publishDateRequired: 'تاريخ النشر مطلوب',

      backToHome: 'العودة إلى الرئيسية',
      403: `آسف ، لا يمكنك الوصول إلى هذه الصفحة`,
      404: 'عفوًا ، الصفحة التي قمت بزيارتها غير موجودة',
      500: 'عذرًا ، يبلغ الخادم عن خطأ',
      forbidden: {
        message: 'ممنوع',
      },
      validation: {
        message: 'حدث خطأ',
      },
      defaultErrorMessage: 'معذرة حدث خطأ',
    },
    // See https://github.com/jquense/yup#using-a-custom-locale-dictionary
    /* eslint-disable */
    validation: {
      mixed: {
        default: '$ {path} غير صالح',
        required: '$ {path} مطلوب',
        oneOf:
          'يجب أن يكون $ {path} إحدى القيم التالية: $ {values}',
        notOneOf:
          'يجب ألا يكون $ {path} إحدى القيم التالية: $ {values}',
        notType: ({ path, type, value, originalValue }) => {
          return `يجب أن يكون $ {path} $ {type}`;
        },
      },
      string: {
        length:
          'يجب أن يكون $ {path} أحرف $ {length} تمامًا',
        min: 'يجب أن يكون $ {path} على الأقل $ {min} حرفًا',
        max: 'يجب أن يكون $ {path} بحد أقصى $ {max} حرفًا',
        matches:
          'يجب أن يتطابق $ {path} مع ما يلي: "$ {regex}"',
        email: 'يجب أن يكون $ {path} بريدًا إلكترونيًا صالحًا',
        url: 'يجب أن يكون $ {path} عنوان URL صالحًا',
        trim: 'يجب أن يكون $ {path} عبارة عن جملة مقطوعة',
        lowercase: 'يجب أن يكون $ {path} سلسلة أحرف صغيرة',
        uppercase: 'يجب أن يكون $ {path} سلسلة أحرف كبيرة',
        selected: 'يجب تحديد $ {path}',
      },
      number: {
        min:
          'يجب أن يكون $ {path} أكبر من أو يساوي $ {min}',
        max: 'يجب أن يكون $ {path} أقل من أو يساوي $ {max}',
        lessThan: 'يجب أن يكون $ {path} أقل من $ {less}',
        moreThan: 'يجب أن يكون $ {path} أكبر من $ {more}',
        notEqual: 'يجب أن لا يكون $ {path} مساويًا لـ $ {notEqual}',
        positive: 'يجب أن يكون $ {path} رقمًا موجبًا',
        negative: 'يجب أن يكون $ {path} رقمًا سالبًا',
        integer: 'يجب أن يكون $ {path} عددًا صحيحًا',
        invalid: 'يجب أن يكون $ {path} رقمًا',
      },
      date: {
        min: 'يجب أن يكون حقل $ {path} أكبر من $ {min}',
        max: 'يجب أن يكون حقل $ {path} في وقت سابق عن $ {max}',
      },
      boolean: {},
      object: {
        noUnknown:
          'لا يمكن أن يحتوي حقل $ {path} على مفاتيح غير محددة في شكل الكائن',
      },
      array: {
        min: 'يجب أن يحتوي حقل $ {path} على عناصر $ {min} على الأقل',
        max:
          'يجب أن يحتوي حقل $ {path} على أقل من أو يساوي عناصر $ {max}',
      },
    },
    /* eslint-disable */
    fileUploader: {
      upload: 'رفع',
      image: 'يجب عليك تحميل صورة',
      size: 'الملف كبير جدا. الحجم الأقصى المسموح به هو {0',
      formats: `تنسيق غير صالح. يجب أن يكون '{0}'.`,
    },
    importer: {
      line: 'خط',
      status: 'الحالة',
      pending: 'قيد الانتظار',
      imported: 'مستورد',
      error: 'خطأ',
      total: `{0} مستورد ، {1} معلق و {2} مع وجود خطأ`,
      importedMessage: `تمت المعالجة {0} من {1}.`,
      noNavigateAwayMessage:
        'لا تنتقل بعيدًا عن هذه الصفحة أو سيتم إيقاف الاستيراد.',
      completed: {
        success:
          'اكتمل الاستيراد. تم استيراد جميع الصفوف بنجاح.',
        someErrors:
          'اكتملت المعالجة ، لكن تعذر استيراد بعض الصفوف.',
        allErrors: 'فشل الاستيراد. لا توجد صفوف صالحة.',
      },
      form: {
        downloadTemplate: 'قم بتنزيل القالب',
        hint:
          'أنقر أو إسحب الملف إلى هذه المنطقة للمتابعة',
      },
      list: {
        discardConfirm:
          'هل أنت واثق؟ سيتم فقدان البيانات غير المستوردة.',
      },
      errors: {
        invalidFileEmpty: 'الملف فارغ',
        invalidFileExcel:
          'يُسمح فقط بملفات excel (.xlsx)',
        invalidFileUpload:
          'ملف غير صالح. تأكد من أنك تستخدم الإصدار الأخير من القالب.',
        importHashRequired: 'استيراد التجزئة هو مطلوب',
        importHashExistent: 'تم بالفعل استيراد البيانات',
      },
    },
  
    autocomplete: {
      loading: 'جار التحميل..',
    },
  
    imagesViewer: {
      noImage: 'لا توجد صورة',
    },
  
    firebaseErrors: {
      'auth/user-disabled': 'حسابك معطل',
      'auth/user-not-found': `آسف ، نحن لا نتعرف على بيانات الاعتماد الخاصة بك`,
      'auth/wrong-password': `آسف ، نحن لا نتعرف على بيانات الاعتماد الخاصة بك`,
      'auth/weak-password': 'كلمة المرور هذه ضعيفة جدًا',
      'auth/email-already-in-use': 'البريد الإلكتروني قيد الاستخدام بالفعل',
      'auth/invalid-email': 'يرجى تقديم عنوان بريد إلكتروني صالح',
      'auth/account-exists-with-different-credential':
        'البريد الإلكتروني مستخدم بالفعل لطريقة مصادقة مختلفة.',
      'auth/credential-already-in-use':
        'بيانات الاعتماد قيد الاستخدام بالفعل',
    },
  };
  
  export default ar;
  