// import Layout from '@/modules/layout/layout/dashboard/DashboardLayout';
import Layout from '@/modules/layout/components/layout';
import { i18n } from '@/i18n';

const HomePage = () =>
  import('@/modules/home/components/home-page.vue');
// const Dashboard = () =>
// import('@/modules/home/components/Dashboard.vue');
  
// const Dashboard= () => import(/* webpackChunkName: "dashboard" */"../layout/pages/Dashboard.vue");

export default [
  {
    path: '/:lang?',
    exact: true,
    component: Layout,
    // redirect:'/home',
    // redirect:'/dashboard',
    // meta: { title: i18n('metaTitle.mainTitle') },
    // beforeEnter: (to, from, next) => {
    //   document.title = to.meta.title
    //   next()
    // },
    children: [
      {
        name: 'home',
        // path: '/home',
        path: '',
        component: HomePage,
        exact: true,
        meta: { 
          auth: true ,  
          title: i18n('metaTitle.mainTitle') 
        },
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },
      // {
      //   name: 'dashboard',
      //   path: '/dashboard',
      //   component: Dashboard,
      //   // exact: true,
      //   meta: { auth: true ,  title: i18n('metaTitle.mainTitle') },
       
      //   beforeEnter: (to, from, next) => {
      //     document.title = to.meta.title
      //     next()
      //   }
      // },
    ],
  },
];
