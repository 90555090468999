import paymentListStore from '@/modules/payment/payment-list-store';
import paymentViewStore from '@/modules/payment/payment-view-store';
// import placeImporterStore from '@/modules/place/place-importer-store';
import paymentFormStore from '@/modules/payment/payment-form-store';
// import placeDestroyStore from '@/modules/place/place-destroy-store';

export default {
  namespaced: true,

  modules: {
    // destroy: placeDestroyStore,
    form: paymentFormStore,
    list: paymentListStore,
    view: paymentViewStore,
    // importer: placeImporterStore,
  },
};
