import { render, staticRenderFns } from "./app.vue?vue&type=template&id=8d744d3a&"
import script from "./app.vue?vue&type=script&lang=js&"
export * from "./app.vue?vue&type=script&lang=js&"
import style0 from "./app.vue?vue&type=style&index=0&lang=css&"
import style1 from "./app.vue?vue&type=style&index=1&lang=scss&"
import style2 from "./app.vue?vue&type=style&index=2&lang=scss&"
import style3 from "./app.vue?vue&type=style&index=3&lang=scss&"
import style4 from "./app.vue?vue&type=style&index=4&lang=scss&"
import style5 from "./app.vue?vue&type=style&index=5&lang=scss&"
import style6 from "./app.vue?vue&type=style&index=6&lang=scss&"
import style7 from "./app.vue?vue&type=style&index=7&lang=scss&"
import style8 from "./app.vue?vue&type=style&index=8&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QTable,QField,QList,QItem,QSelect,QBtn,QIcon,QSpinner,QRating} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QTable,QField,QList,QItem,QSelect,QBtn,QIcon,QSpinner,QRating})
