// import branchListStore from '@/modules/branch/branch-list-store';
import companyViewStore from '@/modules/company/company-view-store';
import companyFormStore from '@/modules/company/company-form-store';

// import branchFormStore from '@/modules/branch/branch-form-store';
// import branchDestroyStore from '@/modules/branch/branch-destroy-store';

export default {
  namespaced: true,

  modules: {
    // destroy: branchDestroyStore,
    // form: branchFormStore,
    // list: branchListStore,
    view: companyViewStore,
    form: companyFormStore
  },
};
