//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

export default {
  name: 'app-upload-image-new',
    props: {
    type: {
      type: String,
      required: true,
    } 
  },

  data() {
    return {
    };
  },
  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      is_screen_xs: 'layout/is_screen_xs',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    // hasPermissionToCreate() {
    //   return new TripPermissions(this.currentUser).create;
    // },
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args);
    },
    editCompanyProfile() {
        
    }
  },
};
