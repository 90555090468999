// import Layout from '@/modules/layout/layout/dashboard/DashboardLayout';
import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';
import { i18n } from '@/i18n';

const PlaceListPage = () =>
  import('@/modules/place/components/place-list-page.vue');
const PlaceFormPage = () =>
  import('@/modules/place/components/place-form-page.vue');
const PlaceViewPage = () =>
  import('@/modules/place/components/place-view-page.vue');
// const PlaceImporterPage = () =>
  // import('@/modules/place/components/place-importer-page.vue');
// const NotificationFormPage = () =>
  // import('@/modules/notification/components/notification-form-page.vue');
// const PlaceNotificationFormPage = () =>
//   import('@/modules/place/components/place-notification-form-page.vue');
const PlaceNotificationFormPage = () =>
  import('@/modules/place/components/place-form-step-2.vue');
export default [
  {
    name: '',
    path: '/:lang?',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'place',
        path: 'place',
        component: PlaceListPage,
        meta: {
          auth: true,
          permission: Permissions.values.placeView,
          title: i18n('metaTitle.place')

        },
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },
      {
        name: 'placeNew',
        path: 'place/new',
        component: PlaceFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.placeCreate,
          title: i18n('metaTitle.newPlace') 
        },
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },
      {
        name: 'placeView',
        path: 'place/:id',
        component: PlaceViewPage,
        meta: {
          auth: true,
          permission: Permissions.values.placeView,
          title: i18n('metaTitle.viewPlace') 
        },
        props: true,
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },
      {
        name: 'placeNotificationNew',
        path: 'place/notification',
        props: true,
        component: PlaceNotificationFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.placeCreate,
          title: i18n('metaTitle.newPlaceNotification')
        },
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        },
      },
      // {
      //   name: 'placeNotificationEdit',
      //   path: '/place/notification/:id/edit',
      //   props: true,
      //   component: PlaceNotificationFormPage,
      //   meta: {
      //     auth: true,
      //     permission: Permissions.values.placeEdit,
      //     // permission: Permissions.values.placeNotificationEdit,
      //     title: i18n('metaTitle.newPlaceNotification')
      //   },
      //   beforeEnter: (to, from, next) => {
      //     document.title = to.meta.title
      //     next()
      //   },
      // },
    ],
  },
];
