import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-notification';

export class NotificationService {
  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation NOTIFICATION_UPDATE(
          $id: String!
          $data: NotificationInput!
        ) {
          notificationUpdate(id: $id, data: $data) {
            id
          }
        }
      `,

      variables: {
        id,
        data,
      },
    });

    return response.data.notificationUpdate;
  }

  static async destroyAll(ids) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation NOTIFICATION_DESTROY($ids: [String!]!) {
          notificationDestroy(ids: $ids)
        }
      `,

      variables: {
        ids,
      },
    });

    return response.data.notificationDestroy;
  }

  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation NOTIFICATION_CREATE($data: NotificationInput!) {
          notificationCreate(data: $data) {
            id
          }
        }
      `,

      variables: {
        data,
      },
    });

    return response.data.notificationCreate;
  }

  static async import(values, importHash) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation NOTIFICATION_IMPORT(
          $data: NotificationInput!
          $importHash: String!
        ) {
          notificationImport(data: $data, importHash: $importHash)
        }
      `,

      variables: {
        data: values,
        importHash,
      },
    });

    return response.data.notificationImport;
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query NOTIFICATION_FIND($id: String!) {
          notificationFind(id: $id) {
            id
            title
            message
            image {
              id
              name
              sizeInBytes
              publicUrl
              privateUrl
            }
            name
            placeId
            createdAt
            updatedAt
          }
        }
      `,

      variables: {
        id,
      },
    });

    return response.data.notificationFind;
  }

  static async list(filter, orderBy, limit, offset) {
    const response = await graphqlClient.query({
      query: gql`
        query NOTIFICATION_LIST(
          $filter: NotificationFilterInput
          $orderBy: NotificationOrderByEnum
          $limit: Int
          $offset: Int
        ) {
          notificationList(
            filter: $filter
            orderBy: $orderBy
            limit: $limit
            offset: $offset
          ) {
            count
            rows {
              id

              updatedAt
              createdAt
            }
          }
        }
      `,

      variables: {
        filter,
        orderBy,
        limit,
        offset,
      },
    });

    return response.data.notificationList;
  }

  static async listAutocomplete(query, limit) {
    const response = await graphqlClient.query({
      query: gql`
        query NOTIFICATION_AUTOCOMPLETE(
          $query: String
          $limit: Int
        ) {
          notificationAutocomplete(query: $query, limit: $limit) {
            id
            label
          }
        }
      `,

      variables: {
        query,
        limit,
      },
    });

    return response.data.notificationAutocomplete;
  }
}
