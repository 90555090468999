// import { complaintService } from '@/modules/complaint/complaint-service';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { firestoreAction } from 'vuexfire';

export default {
  namespaced: true,
  state: {
    complaintsList: {},
    complaintsArr: [],
    messages: [],
    selectedId: null,
    selectedMessages: [],
    complaintUser: {},
    loading: false,
  },
  getters: {
    complaintsList: (state) => state.complaintsList,
    complaintsArr: (state) => state.complaintsArr,
    messages: (state) => state.messages,
    test: (state) => state.test,
    selectedMessages: (state) => state.selectedMessages,
    complaintUser: (state) => state.complaintUser,
    loading: (state) => state.loading,
  },
  mutations: {
    CLEAR_COMPLAINT_LIST(state) {
      state.complaintsList = {};
    },
    SET_ID(state, payload) {
      state.selectedId = payload;
    },
    ADD_USER(state, index) {
      state.complaintsArr[index].createdBy =
        state.complaintUser;
    },
    ADD_MESSAGE_USER(state, index) {
      state.messagesNEW[index].senderId = state.messageUser;
    },
    RESET_MESSAGES_AND_COMPLAINTS(state) {
      state.messagesNEW = [];
      state.complaintsArr = [];
    },
    START_LOADING(state) {
      state.loading = true;
    },
    FINISH_LOADING(state) {
      state.loading = false;
    },
  },
  actions: {
    setComplaintListener : firestoreAction( async ({bindFirestoreRef,commit,rootGetters,dispatch},payload) => {
        const currentUser = rootGetters['auth/currentUser']
        commit('RESET_MESSAGES_AND_COMPLAINTS')
        commit('START_LOADING')
        const queryComplaintsArray= firebase.firestore().collection('complaint').orderBy("updatedAt", "desc").where('status','==',payload.status).where('createdBy' , '==' , currentUser.id)
        await bindFirestoreRef('complaintsArr', queryComplaintsArray);
        dispatch('doFetchComplaintUser')
        commit('FINISH_LOADING')
    }),

    doFetchComplaintUser : firestoreAction( async ({getters}) => {
      for (const complaint of getters.complaintsArr) {
        const createdBy = await firebase
          .firestore()
          .collection('user')
          .doc(complaint.createdBy)
          .get();
        complaint['createdBy'] = createdBy.data();
      }
}),

    setMessagesListener: firestoreAction( async (
        { bindFirestoreRef, commit, getters },
        complaintId,
      ) => {
        const customSerializer = (doc) => {
          const data = doc.data()
          Object.defineProperty(data, '_doc', { value: doc })
          return data
        }
        commit('START_LOADING');
        const queryComplaintMessage = firebase
          .firestore()
          .collection('complaint')
          .doc(complaintId)
          .collection('message')
          .orderBy('sentAt','asc');
        
        await bindFirestoreRef(
          'messages',
          queryComplaintMessage,
          {serialize: customSerializer }
        );
        commit('FINISH_LOADING');
        console.log(getters.messages);
      },
    ),
    doCreateComplaint: firestoreAction(
      async (
        { rootGetters },
        data,
      ) => {
          console.log(data);
          const currentUser = rootGetters['auth/currentUser']
        var complaintId = firebase
          .firestore()
          .collection('ids')
          .doc().id;
        var complaint = {
          createdBy: currentUser.id,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          id: complaintId,
          status:'pending',
          description: data.complaint,
          tripId: data.trip,
        };
        
        firebase
          .firestore()
          .collection('complaint')
          .doc(complaintId)
          .set({
            ...complaint
          });
         var messageId = firebase
          .firestore()
          .collection('ids')
          .doc().id;
        var message = {
            senderId:currentUser.id,
            message: data.complaint,
            sentAt:firebase.firestore.FieldValue.serverTimestamp(),
            type:"text"
        };
        firebase
          .firestore()
          .collection('complaint')
          .doc(complaintId)
          .collection('message')
          .doc(messageId)
          .set({
            ...message
          });
          console.log(data);
          console.log("DONE");
      },
    ),
    doReply: firestoreAction(
      ({ rootGetters }, { data }) => {
        const currentUser = rootGetters['auth/currentUser']
        var id = firebase
          .firestore()
          .collection('ids')
          .doc().id;
          console.log(data);
        var obj = {
          id: data.id,
          message: {
            senderId: currentUser.id,
            sentAt: firebase.firestore.FieldValue.serverTimestamp(),
            ...data
          },
        };
        firebase
          .firestore()
          .collection('complaint')
          .doc(data.id)
          .collection('message')
          .doc(id)
          .set({
            ...obj.message,
          });
        console.log(obj);
      },
    ),
    setId({ commit }, id) {
      commit('SET_ID', id);
    },
  },
};
