import { PaymentService } from '@/modules/payment/payment-service';
import Errors from '@/shared/error/errors';
// import { routerAsync } from '@/app-module';

export default {
  namespaced: true,

  state: {
    loading: false,
    record: null,
    wallet: null, 
    decryptedBalance: null,
    loadingPendingRequests: false,
    emptyPendingRequests: null
  },

  getters: {
    record: (state) => state.record,
    loading: (state) => !!state.loading,
    wallet: (state) => state.wallet,
    decryptedBalance: (state) => state.decryptedBalance,
    loadingPendingRequests: (state) => state.loadingPendingRequests,
    emptyPendingRequests: (state) => state.emptyPendingRequests
  },

  mutations: {
    FIND_WALLET_STARTED(state) {
      // state.record = null;
      state.loading = true;
    },

    FIND_WALLET_SUCCESS(state, payload) {
      state.wallet = payload;
      // state.decryptedBalance = payload.decryptedBalance
      state.loading = false;
    },

    FIND_WALLET_ERROR(state) {
      state.wallet = null;
      state.decryptedBalance = null
      state.loading = false;
    },

    FIND_PENDING_REQUEST_STARTED(state){
      state.loadingPendingRequests = true
    },

    FIND_PENDING_REQUEST_SUCCESS(state, payload){
      state.emptyPendingRequests = payload
      state.loadingPendingRequests = false
    },

    FIND_PENDING_REQUEST_ERROR(state){
      state.loadingPendingRequests = false
    },

  },

  actions: {
    async doFindWallet({ commit }, id) {
      try {
        commit('FIND_WALLET_STARTED');
        const wallet = await PaymentService.findWallet(id);
        console.log(wallet);
        // debugger
        // const decryptedBalance = await PaymentService.decryptData(wallet.balance)
        // console.log(decryptedBalance);
        // debugger
        commit('FIND_WALLET_SUCCESS',  wallet);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_WALLET_ERROR');
      }
    },
    async doFindPendingRequest({ commit }, walletId) {
      try {
        commit('FIND_PENDING_REQUEST_STARTED');
        const emptyPendingRequests = await PaymentService.requestFindPending(walletId);
        console.log(emptyPendingRequests);
        debugger
        commit('FIND_PENDING_REQUEST_SUCCESS', emptyPendingRequests);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_PENDING_REQUEST_ERROR');
      }
    },
  },
};

