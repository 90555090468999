import Layout from '@/modules/layout/components/layout';
import { i18n } from '@/i18n';

const InvoiceListPage = () =>
  import('@/modules/invoice/components/invoice-list-page.vue');
const InvoiceViewPage = () =>
  import('@/modules/invoice/components/invoice-view-page.vue');



export default [
  {
    name: '',
    path: '/:lang?',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'invoice',
        path: 'invoice',
        component: InvoiceListPage,
        meta: {
          auth: true,
          title: i18n('metaTitle.invoice')

        },
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },
      {
        name: 'invoice/:id',
        path: 'invoice/:id',
        component: InvoiceViewPage,
        meta: {
          auth: true,
          title: i18n('metaTitle.invoice')
        },
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },
    ],
  },
];
