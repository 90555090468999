//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import  i18n  from '@/vueI18n';
import moment from 'moment';

export default {
  name: 'app-header',

  data() {
    return {
      showM: false,
      showNotificationMenu: false
    };
  },
  computed: {
    ...mapGetters({
      currentUserNameOrEmailPrefix: 'auth/currentUserNameOrEmailPrefix',
      currentUserAvatar: 'auth/currentUserAvatar',
      currentUser: 'auth/currentUser',
      notifications: 'home/notifications',
      isMobile: 'layout/isMobile',
    }),
    lang(){
      return i18n.locale
    },
    newNotifications(){
      return this.notifications.filter(notification => {
        return notification.isNew==true
      })
    }
  },
  methods: {
    ...mapActions({
      toggleMenu: 'layout/toggleMenu',
      doSignout: 'auth/doSignout',
      doFetchNotifications: 'home/doFetchNotifications',
    }),
    presenterDate(date){
      if (this.lang == 'ar') {
              return date
                ? moment.unix(date.seconds)
                    .locale('ar')
                    .format('D MMM, YYYY')
                : 'ـــــــ';
            }
            return date
              ? moment.unix(date.seconds)
                  .locale('en')
                  .format('D MMM, YYYY')
              : 'ـــــــ';
    },
    i18n(key, args) {
      return this.$t(key, args);
    },

    handleDropdownCommand(command) {
      if (command === 'doSignout') {
        this.doSignout();
      }

      if (command === 'doEditProfile') {
        this.doEditProfile();
      }
    },

    doViewProfile() {
      return this.$router.push('/auth/profile');
    },

    doEditProfile() {
      // return this.$router.push('/auth/edit-profile');
      if (this.$route.name !== 'owner') 
        return this.$router.push({ name: 'ownerProfile' });
    },

    viewNotifications() {
      if (this.$route.name !== 'notifications') 
      this.$router.push(`/${this.lang}/notifications`)
    },
    goToSettings(){
      console.log(this.lang);
      if (this.$route.name !== 'settings') 
        return this.$router.push({
          name: 'settings'
        })
    },

    // display drop down menu in top navbar that appear profile and log out option
    showMenu() {
      if (this.showM == false) {
        this.showM = true;
      } else {
        this.showM = false;
      }
    },
    notificationMenuToggle() {
      if (this.showNotificationMenu == false) {
        this.showNotificationMenu = true;
      } else {
        this.showNotificationMenu = false;
      }
    },
    // toggle menu condition if open or close
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
  },
  async mounted(){    
    await this.doFetchNotifications(this.currentUser.companyId)
  }
};
