import { TripService } from '@/modules/trip/trip-service';
import Errors from '@/shared/error/errors';
import { routerAsync } from '@/app-module';

export default {
  namespaced: true,

  state: {
    loading: false,
    record: null,
    response: null,
    cancellationFee: null
  },

  getters: {
    record: (state) => state.record,
    loading: (state) => !!state.loading,
    response: (state) => state.response,
    cancellationFee: (state) => state.cancellationFee,
  },

  mutations: {
    FIND_STARTED(state) {
      state.record = null;
      state.loading = true;
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.loading = false;
    },

    FIND_ERROR(state) {
      state.record = null;
      state.loading = false;
    },
    SET_RESPONSE(state,response) {
      state.response = response;
    },
    SET_CANCELLATION_FEE(state,fee) {
      state.cancellationFee = fee;
    },
  },

  actions: {
    async doFind({ commit }, {id, includeCount}) {
      try {
        commit('FIND_STARTED');
        const record = await TripService.find(id, includeCount);
        commit('FIND_SUCCESS', record);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        routerAsync().push('/trip');
      }
    },
    async doStartTrip({ commit }, id) {
      try {
        // commit('FIND_STARTED');
        const response = await TripService.startTrip(id);
        debugger
        commit("SET_RESPONSE",response)
        // commit('FIND_SUCCESS', record);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        routerAsync().push('/trip');
      }
    },
    async doFetchCancellationFees({commit},{id,type}){
      try {
        const response = await TripService.fetchCancellationFees(id,type);
        commit("SET_CANCELLATION_FEE",response)
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
      }
    },
    async doCancelTrip({commit},{id,reason}){
      try {
        const response = await TripService.cancelTrip(id,reason);
        commit("SET_RESPONSE",response)
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
      }
    }
  },
};

