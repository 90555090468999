// import Layout from '@/modules/layout/layout/dashboard/DashboardLayout';
import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';
import { i18n } from '@/i18n';

const CityListPage = () =>
  import('@/modules/city/components/city-list-page.vue');
const CityFormPage = () =>
  import('@/modules/city/components/city-form-page.vue');
// const CityEditPage = () =>
//   import('@/modules/city/components/city-edit-page.vue');
// const CityReorderPage = () =>
//   import('@/modules/city/components/city-reorder-page.vue');

// const CityViewPage = () =>
//   import('@/modules/city/components/city-view-page.vue');

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'city',
        path: '/city',
        component: CityListPage,
        meta: {
          auth: true,
          permission: Permissions.values.cityView,
          title: i18n('metaTitle.city')

        },
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },
      {
        name: 'cityNew',
        path: '/city/new',
        component: CityFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.cityCreate,
          title: i18n('metaTitle.newCity') 
        },
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },
      {
        name: 'cityEdit',
        path: '/city/:id/edit',
        component: CityFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.cityEdit,          
          title: i18n('metaTitle.editCity') 
        },
        props: true,
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },
      // {
      //   name: 'cityView',
      //   path: '/city/:id',
      //   component: CityViewPage,
      //   meta: {
      //     auth: true,
      //     permission: Permissions.values.cityView,
      //     title: i18n('metaTitle.viewCity') 
      //   },
      //   props: true,
      //   beforeEnter: (to, from, next) => {
      //     document.title = to.meta.title
      //     next()
      //   }
      // },
    ],
  },
];
