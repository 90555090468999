//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import { EventBus } from '@/event-bus.js'


// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
);
export default {
    props: ['message', 'maxFiles', 'id', 'files'],
    name: 'app-form-uploader',
    components:{
        FilePond,
    },
    data(){
        return{ 
            myFiles:[],
            server:{
                load: (source, load) => {                    
                    var myRequest = new Request(source);
                    debugger
                    fetch(myRequest).then(function(response) {
                    response.blob().then(function(myBlob) {
                        load(myBlob)
                    }).catch(err => {
                        console.log('err ==> ',err);
                    })
                    });         
                },
            },
            notFound: true,
            imageId: null
        }
    },
    created(){        
        EventBus.$on('formImageIsRequired', (imageId) =>{
            this.notFound = true
            this.imageId = imageId
        })
    },
    methods:{
        handleFilePondInit: function () {
            console.log("FilePond has initialized");
            // FilePond instance methods are available on `this.$refs.pond`
        },
        addNewFile(error, file){
            console.log('add new file', file);
        },
        onActivateFile(value){
            console.log('onActivateFile',value);
        },
        removeFile(error, file){
            console.log('file removed', file);
        },
        fileUploaded () {
            this.emitChangedEvent()
        },
        fileReverted () {
            this.emitChangedEvent()
        },
        reorderFiles () {
            this.emitChangedEvent()
        },
        emitChangedEvent (files) {
            console.log('files', files);  
            let processingFiles = this.$refs.pond.getFiles()
            console.log('processingFiles', processingFiles);
            let isFilesUploaded = processingFiles.every((file) => file.status === 5 || file.status === 2);
            console.log(processingFiles);
            console.log(isFilesUploaded);
            this.notFound = false
            this.$emit('change', processingFiles)
        },
        i18n(key, args) {
            return this.$t(key, args);
        },
    },
    watch: {
        files(newval){
            this.myFiles.push( 
                {
                    source: newval,
                    options: {
                        type: 'local'
                    }
                })
            
        }
    },
    
}
