import Errors from '@/shared/error/errors';
// import { routerAsync } from '@/app-module';
// import Message from '@/shared/message/message';
// import { i18n } from '@/i18n';
import { AdminService } from '@/modules/admins/admin-service';
import { firestoreAction,vuexfireMutations } from 'vuexfire'
import firebase from 'firebase/app';

export default {
  namespaced: true,

  state: {
    findLoading: false,
    saveLoading: false,
    record: null,
    branchesRows: [],
    loadingBranches: false
  },

  getters: {
    record: (state) => state.record,
    findLoading: (state) => !!state.findLoading,
    saveLoading: (state) => !!state.saveLoading,
    branchesRows: (state) => state.branchesRows,
    loadingBranches: (state) => !!state.loadingBranches
  },

  mutations: {
    RESET(state) {
      state.findLoading = false;
      state.saveLoading = false;
      state.record = null;
    },

    FIND_STARTED(state) {
      state.record = null;
      state.findLoading = true;
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.findLoading = false;
    },

    FIND_ERROR(state) {
      state.record = null;
      state.findLoading = false;
    },

    ADD_STARTED(state) {
      state.saveLoading = true;
    },

    ADD_SUCCESS(state) {
      state.saveLoading = false;
    },

    ADD_ERROR(state) {
      state.saveLoading = false;
    },

    UPDATE_STARTED(state) {
      state.saveLoading = true;
    },

    UPDATE_SUCCESS(state) {
      state.saveLoading = false;
    },

    UPDATE_ERROR(state) {
      state.saveLoading = false;
    },

    //#region [ VuexfireMutations ]
    ...vuexfireMutations,
    FETCH_BRANCHES_STARTED(state) {
      state.loadingBranches = true
    },
    FETCH_BRANCHES_SUCCESS(state) {
      state.loadingBranches = false
    },
    //#endregion
  },

  actions: {
    doNew({ commit }) {
      commit('RESET');
    },

    // async doFind({ commit }, id) {
    //   try {
    //     commit('FIND_STARTED');
    //     const record = await IamService.find(id);
    //     commit('FIND_SUCCESS', record);
    //   } catch (error) {
    //     Errors.handle(error);
    //     commit('FIND_ERROR');
    //     routerAsync().push('/iam');
    //   }
    // },

    async doAdd({ commit }, values) {
        try {
            // console.log('email', values.email);
            // console.log('password', values.password);
            console.log(values);
            commit('ADD_STARTED');
            const record = await AdminService.createInAuthAndFirestore(values);
            // console.log(record);
            // debugger
            // console.log(authenticationUid);
            // delete values.password
            // values['authenticationUid'] = authenticationUid
            // await AdminService.create(values)
            commit('ADD_SUCCESS');
            return record
            // Message.success(i18n('iam.doAddSuccess'));
            // routerAsync().push('/Admins');
        } catch (error) {
            Errors.handle(error);
            commit('ADD_ERROR');
        }
    },

    doFetchCompanyBranches: firestoreAction(async ({bindFirestoreRef, commit},payload) => {
      console.log(payload);
      const db = firebase.firestore()
      const branchCollection = db.collection('company').doc(payload.companyId).collection('branch')
      commit('FETCH_BRANCHES_STARTED')
      await bindFirestoreRef(
        'branchesRows',
        branchCollection,
        // { serialize: customSerializer }
      );
      commit('FETCH_BRANCHES_SUCCESS')

        // console.log('branches', getters.branches);

    }),

    async doUpdate(
      { commit },
      values,
    ) {
      try {
        commit('UPDATE_STARTED');
        console.log('values', values);
        debugger
        await AdminService.edit(values);
        commit('UPDATE_SUCCESS');

        // const currentUser = rootGetters['auth/currentUser'];
        // if (currentUser.id === values.id) {
        //   dispatch('auth/doRefreshCurrentUser');
        // }

        // Message.success(i18n('iam.doUpdateSuccess'));
        // routerAsync().push('/Admins');
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },

  
  },
};
