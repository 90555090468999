//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'app-image-preview',
  props: ['visible', 'src'],

  data () {
    return {
      dialog: false,
      maximizedToggle: true,
    }
  },
  computed: {
    dialogVisible: {
      get: function () {
        return this.visible;
      },

      set: function (value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    isBlank() {
      return !this.src 
    },
    value() {
      return this.src ? this.src : ''
    }
  },
  methods: {
    doClose() {
      this.$emit('close')
    },
  },
};
