import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-reviews';
import { storeAsync } from '@/app-module';
import firebase from 'firebase/app'
import 'firebase/firestore';

export class ReviewsService {
  static async enable(id) {
    return this._changeStatus(id, false);
  }

  static async disable(id) {
    return this._changeStatus(id, true);
  }

  static async _changeStatus(id, disabled) {
    let currentUser = storeAsync().getters['auth/currentUser']

    let batch = firebase.firestore().batch();
    
    let docRef = firebase.firestore().doc(`reviews/${id}`) 
    batch.update(docRef, {
      disabled,
      updatedBy: currentUser.id,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
    
    return await batch.commit()
  }

  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation PLAN_CREATE($data: ReviewsInput!) {
          reviewsCreate(data: $data) {
            id
          }
        }
      `,

      variables: {
        data,
      },
    });

    return response.data.reviewsCreate;
  }

  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation PLAN_UPDATE($id: String!, $data: ReviewsInput!) {
          reviewsUpdate(id: $id, data: $data) {
            id
          }
        }
      `,

      variables: {
        id,
        data,
      },
    });

    return response.data.reviewsUpdate;
  }

  static async destroyAll(ids) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation PLAN_DESTROY($ids: [String!]!) {
          reviewsDestroy(ids: $ids)
        }
      `,

      variables: {
        ids,
      },
    });

    return response.data.reviewsDestroy;
  }

  static async import(values, importHash) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation PLAN_IMPORT(
          $data: ReviewsInput!
          $importHash: String!
        ) {
          reviewsImport(data: $data, importHash: $importHash)
        }
      `,

      variables: {
        data: values,
        importHash,
      },
    });

    return response.data.reviewsImport;
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query PLAN_FIND($id: String!) {
          reviewsFind(id: $id) {
            id
            name {
              en
              ar
            }
            description {
              en
              ar
            }
            startCityId
            type
            numberOfDays
            disabled
            startCity {
              id
              name {
                en
                ar
              }
            }
            programs {
              name
              places {
                place {
                  id
                  placeName {
                    en
                    ar
                  }
                  placePictures {
                    id
                    name
                    publicUrl
                    privateUrl
                  }
                  country {
                    en
                    ar
                  }
                  city {
                    id
                    name {
                      en
                      ar
                    }
                  }
                }
                id
                selectedImage
                averageTime
              }
            }
        
            createdBy
            updatedBy
            createdAt
            updatedAt
          }
        }
      `,

      variables: {
        id,
      },
    });

    return response.data.reviewsFind;
  }

  static async list(userId, type, filter, orderBy, pagination){
debugger
    const response = await graphqlClient.query({
          query: gql`
          query REVIEW_LIST_PAGINATION(
            $userId: String!
            $type: ReviewTypeEnum!
            $filter: ReviewFilterInput
            $orderBy: String
            $pagination: PaginationInput
          ){
            reviewListPagination(
              userId: $userId
              type: $type
              filter: $filter
              orderBy: $orderBy
              pagination: $pagination
            ){
              count
              pagination{
                isFirstPage
                isLastPage
              }
              rows{
                id
                rate
                description 
                reviewer{
                  fullName
                  avatar{
                    publicUrl
                  }
                }
                createdBy
                createdAt
              }
          }
        }
          `,
          variables: {
            userId,
            type,
            filter,
            orderBy,
            pagination
          }
        
    })
    debugger

    return response.data.reviewListPagination;
    // reviewListPagination(userId: String!, type: ReviewTypeEnum!, filter: ReviewFilterInput, orderBy: String, pagination: PaginationInput): ReviewPage!
  }

  // static async list(filter, orderBy, limit, offset) {
  //   const response = await graphqlClient.query({
  //     query: gql`
  //       query PLAN_LIST(
  //         $filter: ReviewsFilterInput
  //         $orderBy: ReviewsOrderByEnum
  //         $limit: Int
  //         $offset: Int
  //       ) {
  //         reviewsList(
  //           filter: $filter
  //           orderBy: $orderBy
  //           limit: $limit
  //           offset: $offset
  //         ) {
  //           count
  //           rows {
  //             id
  //             name {
  //               en
  //               ar
  //             }
  //             description {
  //               en
  //               ar
  //             }
  //             startCityId
  //             type
  //             numberOfDays
  //             disabled
  //             startCity {
  //               id
  //               name {
  //                 en
  //                 ar
  //               }
  //             }
              
  //             programs {
  //               name 
  //               places {
  //                 place {
  //                   id
  //                   placeName {
  //                     en
  //                     ar
  //                   }
  //                   placePictures {
  //                     id
  //                     name
  //                     publicUrl
  //                     privateUrl
  //                   }
  //                   country {
  //                     en
  //                     ar
  //                   }
  //                   city {
  //                     id
  //                     name {
  //                       en
  //                       ar
  //                     }
  //                		}
  //                 }
  //                 id
  //                 selectedImage
  //                 averageTime
  //               }
  //             }

  //             createdBy
  //             updatedBy
  //             createdAt
  //             updatedAt
  //           }
  //         }
  //       }
  //     `,

  //     variables: {
  //       filter,
  //       orderBy,
  //       limit,
  //       offset,
  //     },
  //   });

  //   return response.data.reviewsList;
  // }

  static async listAutocomplete(query, limit) {
    const response = await graphqlClient.query({
      query: gql`
        query PLAN_AUTOCOMPLETE(
          $query: String
          $limit: Int
        ) {
          reviewsAutocomplete(query: $query, limit: $limit) {
            id
            label
          }
        }
      `,

      variables: {
        query,
        limit,
      },
    });

    return response.data.reviewsAutocomplete;
  }

}
