import planListStore from '@/modules/plan/plan-list-store';
import planViewStore from '@/modules/plan/plan-view-store';
import planFormStore from '@/modules/plan/plan-form-store';
import planDestroyStore from '@/modules/plan/plan-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: planDestroyStore,
    form: planFormStore,
    list: planListStore,
    view: planViewStore,
  },
};
