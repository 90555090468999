import reviewsListStore from '@/modules/reviews/reviews-list-store';
import reviewsViewStore from '@/modules/reviews/reviews-view-store';
import reviewsFormStore from '@/modules/reviews/reviews-form-store';
import reviewsDestroyStore from '@/modules/reviews/reviews-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: reviewsDestroyStore,
    form: reviewsFormStore,
    list: reviewsListStore,
    view: reviewsViewStore,
  },
};
