import { ReviewsService } from '@/modules/reviews/reviews-service';
import Errors from '@/shared/error/errors';
// import reviewsListExporterFields from '@/modules/reviews/reviews-list-exporter-fields';
// import Exporter from '@/shared/exporter/exporter';

const INITIAL_PAGE_SIZE = 10;

export default {
  namespaced: true,

  state: {
    rows: [],
    count: 0,
    loading: false,
    filter: {},
    pagination: {},
    sorter: {},

    table: null,
    isFirstPage: true,
    isLastPage: true
  },

  getters: {
    loading: (state) => state.loading,

    exportLoading: (state) => state.exportLoading,

    rows: (state) => state.rows || [],

    count: (state) => state.count,

    hasRows: (state, getters) => getters.count > 0,

    orderBy: (state) => {
      const sorter = state.sorter;

      if (!sorter) {
        return null;
      }

      if (!sorter.prop) {
        return null;
      }

      let direction =
        sorter.order === 'descending' ? 'DESC' : 'ASC';

      return `${sorter.prop}_${direction}`;
    },

    filter: (state) => state.filter,

    limit: (state) => {
      const pagination = state.pagination;

      if (!pagination || !pagination.pageSize) {
        return INITIAL_PAGE_SIZE;
      }

      return pagination.pageSize;
    },

    offset: (state) => {
      const pagination = state.pagination;

      if (!pagination || !pagination.pageSize) {
        return 0;
      }

      const currentPage = pagination.currentPage || 1;

      return (currentPage - 1) * pagination.pageSize;
    },

    pagination: (state, getters) => {
      return {
        ...state.pagination,
        total: getters.count,
        showSizeChanger: true,
      };
    },

    selectedRows: (state) => {
      return state.table ? state.table.selection : [];
    },
    isFirstPage: (state) => state.isFirstPage,
    isLastPage: (state) => state.isLastPage
  },

  mutations: {
    RESETED(state) {
      state.rows = [];
      state.count = 0;
      state.loading = false;
      state.filter = {};
      state.pagination = {};
      state.sorter = {};
      if (state.table) {
        state.table.clearSelection();
      }
    },

    UNSELECT_ALL(state) {
      if (state.table) {
        state.table.clearSelection();
      }
    },

    TABLE_MOUNTED(state, payload) {
      state.table = payload;
    },

    PAGINATION_CHANGED(state, payload) {
      state.pagination = payload || {};
    },

    PAGINATION_CURRENT_PAGE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: payload || 1,
        pageSize:
          previousPagination.pageSize || INITIAL_PAGE_SIZE,
      };
    },

    PAGINATION_PAGE_SIZE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: previousPagination.currentPage || 1,
        pageSize: payload || INITIAL_PAGE_SIZE,
      };
    },

    SORTER_CHANGED(state, payload) {
      state.sorter = payload || {};
    },

    FETCH_STARTED(state) {
      state.loading = true;
      // if (state.table) {
      //   state.table.clearSelection();
      // }

      // state.filter =
      //   payload && payload.filter ? payload.filter : {};
      // state.pagination =
      //   payload && payload.keepPagination
      //     ? state.pagination
      //     : {};
    },

    FETCH_SUCCESS(state, payload) {
      state.loading = false;
      state.rows = payload.rows;
      state.count = payload.count;
      state.isFirstPage = payload.isFirstPage;
      state.isLastPage = payload.isLastPage;
    },

    FETCH_ERROR(state) {
      state.loading = false;
      state.rows = [];
      state.count = 0;
    },

    EXPORT_STARTED(state) {
      state.exportLoading = true;
    },

    EXPORT_SUCCESS(state) {
      state.exportLoading = false;
    },

    EXPORT_ERROR(state) {
      state.exportLoading = false;
    },
  },

  actions: {
    doUnselectAll({ commit }) {
      commit('UNSELECT_ALL');
    },

    doMountTable({ commit }, table) {
      commit('TABLE_MOUNTED', table);
    },

    async doReset({ commit, dispatch }) {
      commit('RESETED');
      return dispatch('doFetchGroupbyCity');
      // return dispatch('doFetch');
    },

    // async doExport({ commit, getters }) {
    //   try {
    //     if (!reviewsListExporterFields || !reviewsListExporterFields.length) {
    //       throw new Error('reviewsListExporterFields is required');
    //     }

    //     commit('EXPORT_STARTED');

    //     const filter = getters.filter;

    //     const response = await ReviewsService.list(
    //       filter,
    //       getters.orderBy,
    //       null,
    //       null,
    //     );

    //     new Exporter(
    //       reviewsListExporterFields,
    //       'reviews',
    //     ).transformAndExportAsExcelFile(response.rows);

    //     commit('EXPORT_SUCCESS');
    //   } catch (error) {
    //     Errors.handle(error);

    //     commit('EXPORT_ERROR');
    //   }
    // },

    doChangePagination(
      { commit, getters, dispatch },
      pagination,
    ) {
      commit('PAGINATION_CHANGED', pagination);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },

    doChangePaginationPageSize(
      { commit, getters, dispatch },
      pageSize,
    ) {
      commit('PAGINATION_PAGE_SIZE_CHANGED', pageSize);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },

    doChangePaginationCurrentPage(
      { commit, getters, dispatch },
      currentPage,
    ) {
      commit(
        'PAGINATION_CURRENT_PAGE_CHANGED',
        currentPage,
      );
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },

    doChangeSort({ commit, getters, dispatch }, sorter) {
      commit('SORTER_CHANGED', sorter);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },

    async doFetch({ commit }, { 
      userId,
      type,
      filter, 
      orderBy,
      pagination
    } = {}) {
      try {
        commit('FETCH_STARTED');
        debugger
        const response = await ReviewsService.list(
          userId,
          type,
          filter, 
          orderBy,
          pagination
        );

        // console.log(response.count);
        // console.log(response.rows);
        commit('FETCH_SUCCESS', {
          rows: response.rows,
          count: response.count,
          isFirstPage: response.pagination ?  response.pagination.isFirstPage : true,
          isLastPage: response.pagination ? response.pagination.isLastPage : true
        });
      } catch (error) {
        Errors.handle(error);
        commit('FETCH_ERROR');
      }
    },

    async doFetchGroupbyCity({ commit, getters }, { 
        filter, 
        keepPagination 
      } = {}) {
      try {
        commit('FETCH_STARTED', { filter, keepPagination });

        const response = await ReviewsService.list(
          filter,
          getters.orderBy,
          // getters.limit,
          getters.offset,
        );
        
        // Group By Start City ID ===================================================== //
        let distinctCityId = [...new Set(response.rows.map(el => el.startCityId))]
        console.log('distinctCityId =>> ', distinctCityId)
        let rows = []
        distinctCityId.forEach(cityId => {
          console.log('response.rows =>> ', response.rows)
          console.log('cityId =>> ', cityId)
          let reviewss = response.rows.filter(el => el.startCityId == cityId)
          console.log('reviewss =>> ', reviewss)
          rows.push({
            startCityId: cityId,
            cityName: reviewss[0].startCity.name,
            numberOfReviewss: reviewss.length,
            createdAt: reviewss[0].createdAt,
            reviewss: reviewss,
          })
        });        
        console.log('rows =>> ', rows)
        // ============================================================================= //

        commit('FETCH_SUCCESS', {
          rows: rows,
          count: rows.length,
        });

        // commit('FETCH_SUCCESS', {
        //   rows: response.rows,
        //   count: response.count,
        // });
      } catch (error) {
        Errors.handle(error);
        commit('FETCH_ERROR');
      }
    },

  },
};
