//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { I18nUtil } from '@/shared/i18n/i18n-util';
import { getLanguages, getLanguageCode } from '@/i18n';

export default {
  name: 'app-i18n-select',

  data() {
    return {
      value: getLanguageCode(),
      objValues:{}
  
    };
  },

  computed: {
    // languages() {
    //   return getLanguages();
    // },

    languages() {
     const languages = getLanguages().map((lang) => {
       let obj = {
         key: lang.id,
         label: lang.label,
         value: lang.id,
         icon:lang.flag,
       };
       return obj;
     });
     return languages;
   },


  },

  methods: {
  
      doChangeLanguage(language) {
      I18nUtil.doChangeLanguage(language);
    
    },
  },
};
