import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-invoice';
import 'firebase/firestore';

export class InvoiceService {

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query invoiceFind ($id: String!) {
            invoiceFind(id: $id)  
        }
      `,

      variables: {
        id
    },
    });

    return response.data.branchFind;
  }

  static async list(filter,orderBy,pagination) {
    const response = await graphqlClient.query({
      query: gql`
        query LIST_INVOICES_WITH_PAGINATION(
            $filter: InvoiceFilterInput, 
            $orderBy: String,
            $pagination: PaginationInput      
        ) {
            listInvoicesWithPagination(
            filter: $filter, orderBy: $orderBy, pagination: $pagination           
          ) {
            pagination {
            isFirstPage
            isLastPage
        }
        count
        rows {
            id
            receiptNum
            total
            transactionId
            paymentMethod
            guide {
                id
                fullName
                avatar {
                    name
                    publicUrl
                }
                phoneNumber
            }
            client {
                id
                fullName
                avatar {
                    name
                    publicUrl
                }
                phoneNumber
            }
            tripOwner {
                id
                name {
                    en
                    ar
                }
                avatar
                email
                phoneNumber
                rate
                type
            }
            createdAt
        }
          }
        }
      `,
      variables: {
        filter,
        orderBy,
        pagination
       
      },
    });

    return response.data.listInvoicesWithPagination;
  }


}
