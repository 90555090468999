import { render, staticRenderFns } from "./image-preview.vue?vue&type=template&id=79d7dd67&scoped=true&"
import script from "./image-preview.vue?vue&type=script&lang=js&"
export * from "./image-preview.vue?vue&type=script&lang=js&"
import style0 from "./image-preview.vue?vue&type=style&index=0&id=79d7dd67&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79d7dd67",
  null
  
)

export default component.exports
import {QDialog,QCard,QIcon,QCardSection,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QDialog,QCard,QIcon,QCardSection})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
