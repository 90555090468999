import service from '@/modules/auth/auth-service';
import Message from '@/shared/message/message';
import { i18n } from '@/i18n';
import Errors from '@/shared/error/errors';
import { routerAsync } from '@/app-module';

import * as firebase from 'firebase/app';
import 'firebase/auth';

import ProgressBar from '@/shared/progress-bar/progress-bar';

export default {
  namespaced: true,

  state: {
    authenticationUser: null,
    currentUser: null,
    currentUserSocial: null,
    loadingInit: true,
    loadingEmailConfirmation: false,
    loadingPasswordResetEmail: false,
    loadingUpdateProfile: false,
    loading: false,
    loadingVerify: false,
    record: null,
    company: null,
    loadingCompany: false,
    companyId: null
  },

  getters: {
    authenticationUser: (state) => state.authenticationUser,
    currentUser: (state) => state.currentUser,
    currentUserSocial: (state) => state.currentUserSocial,

    currentUserDetails: (state, getters) =>
      getters.currentUser ? getters.currentUser : null,

    currentUserFirstName: (state, getters) =>
      getters.currentUser
        ? getters.currentUser.fullName.split(' ')[0]
        : null,

    currentUserLastName: (state, getters) =>
      getters.currentUser
        ? getters.currentUser.fullName.split(' ')[1]
        : null,

    currentUserEmail: (state, getters) =>
      getters.currentUser
        ? getters.currentUser.email
        : null,

    currentUserFullName: (state, getters) =>
      getters.currentUser
        ? getters.currentUser.fullName
        : '',

    signedIn: (state, getters) =>
      !!getters.currentUser && !!getters.currentUser.id,

    roles: (state, getters) =>
      getters.currentUser
        ? getters.currentUser.roles || []
        : [],

    emptyPermissions: (state, getters) =>
      !getters.roles || !getters.roles.length,

    loading: (state) => !!state.loading,
    loadingVerify: (state) => !!state.loadingVerify,
    loadingInit: (state) => !!state.loadingInit,

    loadingEmailConfirmation: (state) =>
      !!state.loadingEmailConfirmation,

    loadingPasswordResetEmail: (state) =>
      !!state.loadingPasswordResetEmail,

    loadingUpdateProfile: (state) =>
      !!state.loadingUpdateProfile,

    currentUserNameOrEmailPrefix: (state, getters) => {
      if (!getters.currentUser) {
        return null;
      }

      if (
        getters.currentUserFullName &&
        getters.currentUserFullName.length < 25
      ) {
        return getters.currentUserFullName;
      }

      if (getters.currentUser.firstName) {
        return getters.currentUser.firstName;
      }

      return getters.currentUser.email.split('@')[0];
    },

    currentUserAvatar: (state, getters) => {
      if (
        !getters.currentUser ||
        !getters.currentUser.avatars ||
        !getters.currentUser.avatars.length ||
        !getters.currentUser.avatars[0].publicUrl
      ) {
        return null;
      }

      return getters.currentUser.avatars[0].publicUrl;
    },

    cuurentUserSocialAvatar: (state, getters) => {
      return getters.currentUserSocial;
    },

    record: (state) => state.record,
    company: (state) => state.company,
    loadingCompany: (state) => !!state.loadingCompany,
    companyId: (state) => state.companyId
  },

  mutations: {
    CURRENT_USER_REFRESH_SUCCESS(state, payload) {
      state.currentUser = payload.currentUser || null;
    },

    VERIFY_START(state) {
      state.loadingVerify = true;
    },
    VERIFY_SUCCESS(state) {
      state.loadingVerify = false;
    },
    VERIFY_ERROR(state) {
      state.loadingVerify = false;
    },

    AUTH_START(state) {
      state.loading = true;
    },

    AUTH_END(state) {
      state.loading = false;
    },

    AUTH_SUCCESS(state, payload) {
      state.authenticationUser =
        payload.authenticationUser || null;
      state.currentUser = payload.currentUser || null;
      state.currentUserSocial =
        payload.currentUserSocial || null;
      state.loading = false;
    },

    AUTH_SUCCESS_LINKED(state, payload) {
      state.currentUser = payload.currentUser || null;
      state.loading = false;
    },

    AUTH_ERROR(state) {
      state.authenticationUser = null;
      state.currentUser = null;
      state.loading = false;
    },

    EMAIL_CONFIRMATION_START(state) {
      state.loadingEmailConfirmation = true;
    },

    EMAIL_CONFIRMATION_SUCCESS(state) {
      state.loadingEmailConfirmation = false;
    },

    EMAIL_CONFIRMATION_ERROR(state) {
      state.loadingEmailConfirmation = false;
    },

    PASSWORD_RESET_START(state) {
      state.loadingPasswordResetEmail = true;
    },

    PASSWORD_RESET_SUCCESS(state) {
      state.loadingPasswordResetEmail = false;
    },

    PASSWORD_RESET_ERROR(state) {
      state.loadingPasswordResetEmail = false;
    },

    UPDATE_PROFILE_START(state) {
      state.loadingUpdateProfile = true;
    },

    UPDATE_PROFILE_SUCCESS(state) {
      state.loadingUpdateProfile = false;
    },

    UPDATE_PROFILE_ERROR(state) {
      state.loadingUpdateProfile = false;
    },

    AUTH_INIT_SUCCESS(state, payload) {
      state.authenticationUser = payload.authenticationUser || null;
      state.currentUser = payload.currentUser || null;
      state.loadingInit = false;
    },

    AUTH_INIT_ERROR(state) {
      state.authenticationUser = null;
      state.currentUser = null;
      state.loadingInit = false;
    },
    CREATE_COMPANY_STARTED(state){
      state.loadingCompany = true
    },
    CREATE_COMPANY_SUCCESS(state, payload){
      state.loadingCompany = false
      state.companyId = payload
    },
    UPDATE_COMPANY_STARTED(state){
      state.loadingCompany = true
    },
    UPDATE_COMPANY_SUCCESS(state){      
      state.loadingCompany = false
    },
  },

  actions: {
//#region [ Init ]
    doInitFirebase() {
      service.init();
    },
    async doInit({ commit, dispatch }) {
      // await service.init();
      let currentUser = localStorage.getItem('currentUser');
      if (!currentUser) {
        commit('AUTH_INIT_SUCCESS', {
          currentUser: null,
          authenticationUser: null,
        });
        ProgressBar.done();
        return;
      }

      const unsubscribe = service.onAuthStateChanged(
        (authenticationUser) => {
          dispatch(
            'doSigninFromAuthChange',
            authenticationUser,
          );
          unsubscribe();
        },
        (error) => {
          console.error(error);
          commit('AUTH_INIT_ERROR');
        },
      );
    },
    async doWaitUntilInit({ getters }) {
      if (!getters.loadingInit) {
        return Promise.resolve();
      }

      return new Promise((resolve) => {
        const waitUntilInitInterval = setInterval(() => {
          if (!getters.loadingInit) {
            clearInterval(waitUntilInitInterval);
            resolve();
          }
        }, 500);
      });
    },
//#endregion   

//#region [ Send Email ]
    async doSendEmailConfirmation({ commit, getters }) {
      try {
        commit('EMAIL_CONFIRMATION_START');
        console.log('authenticationUser', getters.authenticationUser)
        await service.sendEmailVerification(getters.authenticationUser);
        Message.success(i18n('auth.verificationEmailSuccess'));
        commit('EMAIL_CONFIRMATION_SUCCESS');
      } catch (error) {
        Errors.handle(error);
        commit('EMAIL_CONFIRMATION_ERROR');
      }
    },

    async doSendPasswordResetEmail({ commit }, email) {
      try {
        commit('PASSWORD_RESET_START');
        firebase.auth().languageCode = localStorage.getItem('language');
        await service.sendPasswordResetEmail(email);
        Message.success(i18n('auth.passwordResetEmailSuccess'));
        commit('PASSWORD_RESET_SUCCESS');
      } catch (error) {
        Errors.handle(error);
        commit('PASSWORD_RESET_ERROR');
      }
    },
//#endregion

//#region [ Authentication With Phone Number ]
    async signPhoneNumber({ commit }, { appverifier, phoneNum }) {
      commit('AUTH_START');

      await firebase.auth().signInWithPhoneNumber(phoneNum, appverifier)
        .then(function(confirmationResult) {
          // console.log(confirmationResult)
          localStorage.setItem('verfId', confirmationResult.verificationId);
          localStorage.setItem('phoneNum', phoneNum);

          commit('AUTH_END');
          routerAsync().push('/auth/phone-unverified');

          window.confirmationResult = confirmationResult;
        })
        .catch(function(error) {
          console.log(error)
        });
    },

    async signUpPhoneNumberCredential({ commit }, code) {
      try {
        commit('AUTH_START');
  
        let currentUser;
        let authenticationUser;
        let verId = localStorage.getItem('verfId');
        var credential = await firebase.auth.PhoneAuthProvider.credential(verId, code);
        const credentials = await firebase.auth().signInWithCredential(credential);
        // debugger
        console.log('credentials',credentials)
        if (credentials.additionalUserInfo.isNewUser) {
          authenticationUser = credentials.user;
          currentUser = await service.fetchMe();
          console.log('current user',currentUser)
          currentUser.emailVerified = authenticationUser.emailVerified;
          currentUser.lang = localStorage.getItem('language');
          currentUser.accountType = 'company';
          currentUser.phoneNumber = authenticationUser.phoneNumber
          //#region [old]
          // update profile
          // await service.updateProfile(
          //   currentUser.firstName,
          //   currentUser.lastName,
          //   currentUser.phoneNumber,
          //   currentUser.countryCode,
          //   currentUser.lang,
          //   currentUser.avatars,
          //   currentUser.accountType,
          // );
          //#endregion

          console.log(currentUser);

          // await service.updateProfile(
          //   // currentUser.firstName + currentUser.lastName,
          //   null,
          //   currentUser.phoneNumber,
          //   currentUser.countryCode,
          //   currentUser.lang,
          //   currentUser.avatars,
          //   'company',
          //   null,
          //   null,
          //   null
          // )
          // debugger




          // if (currentUser.accountType == 'company') {
          //   routerAsync().push('/dashboard');
          // } else {
          //   await service.signout();
          //   Errors.handle('User permission denied ');
          // }


          service.reauthenticateWithStorageToken();
    
          commit('AUTH_SUCCESS', {
            currentUser,
            authenticationUser,
          });
          console.log(currentUser);
          localStorage.setItem('currentUser', service.encryption(currentUser, 'secret-c-u'));
          console.log('currentUSer after', localStorage.getItem('currentUser'));
          // routerAsync().push('/dashboard');

        } else {
          service.reauthenticateWithStorageToken();    
          commit('AUTH_SUCCESS', {
            currentUser,
            authenticationUser,
          });
          localStorage.setItem('currentUser', service.encryption(currentUser, 'secret-c-u'));
        }
        console.log(">>>> pin verified");
        return "verified"

      } catch (error) {
        console.log("error in (auth-store) "+ error);
        console.log(error);
        // await service.signout();
        Errors.handle(error);
        commit('AUTH_ERROR');
        return 'error'
      }
    },

    async signPhoneNumberCredential({ commit }, code) {
      try {
        commit('AUTH_START');
  
        let currentUser;
        let authenticationUser;
        let verId = localStorage.getItem('verfId');
        var credential = await firebase.auth.PhoneAuthProvider.credential(verId, code);
        const credentials = await firebase.auth().signInWithCredential(credential);
        console.log('credentials',credentials.additionalUserInfo.isNewUser)
        if (!credentials.additionalUserInfo.isNewUser) {
          console.log('no addi info',credentials.user)
          authenticationUser = credentials.user;
          currentUser = await service.fetchMe();
          console.log('currentUser',currentUser)
          currentUser.emailVerified = authenticationUser.emailVerified;

          if (currentUser.accountType != 'company') {
            throw {code: "auth/user-not-found", message: "There is no user record corresponding to this identifier. The user may have been deleted.", a: null}
          }
        } else {
          console.log('with add info')
          await service.deleteCurrentUserAccount()
          throw {code: "auth/user-not-found", message: "There is no user record corresponding to this identifier. The user may have been deleted.", a: null}
        }

        service.reauthenticateWithStorageToken();
  
        commit('AUTH_SUCCESS', {
          currentUser,
          authenticationUser,
        });
        localStorage.setItem('currentUser', service.encryption(currentUser, 'secret-c-u'));
        const language = localStorage.getItem('language')
        routerAsync().push(`/${language}`);
      } catch (error) {
        await service.signout();
        Errors.handle(error);
        commit('AUTH_ERROR');
      }
    },
//#endregion

//#region [ Authentication With Social ]
async doSigninSocial({ commit }, { provider, rememberMe }) {
  try {
    commit('AUTH_START');
    let authenticationUser = null;
    let currentUser = null;

    const credentials = await service.signinWithSocial(
      provider,
      rememberMe,
    );

    if (credentials && credentials.user) {
      authenticationUser = credentials.user;
      currentUser = await service.fetchMe();
      currentUser.emailVerified = true;
      currentUser.lang = localStorage.getItem('language') || 'en'
      console.log("is new user  ?", credentials.additionalUserInfo.isNewUser);

      if (credentials.additionalUserInfo.isNewUser) {
        let avatar = [];
        let firstName;
        let lastName;
        let countryCode = '';
        let lang = localStorage.getItem('language')
        currentUser.accountType = 'company';

        //  google 
        if (provider == "google") {
          firstName = credentials.additionalUserInfo.profile.given_name
          lastName = credentials.additionalUserInfo.profile.family_name

          if (credentials.additionalUserInfo.profile.picture) {
            avatar = [{
              id: '',
              name: 'photo.jpg',
              privateUrl: '',
              publicUrl: credentials.additionalUserInfo.profile.picture,
            }, ];
          }
        }


        // facebook 
        if (provider == "facebook") {
          firstName = credentials.additionalUserInfo.profile.first_name
          lastName = credentials.additionalUserInfo.profile.last_name

          if (credentials.additionalUserInfo.profile.picture.data.url) {
            avatar = [{
              id: '',
              name: 'photo.jpg',
              privateUrl: '',
              publicUrl: credentials.additionalUserInfo.profile.picture.data.url,
            }, ];
          }
        }

        //  twitter
        if (provider == "twitter") {
          firstName = credentials.user.displayName.split(' ')[0]
          lastName = credentials.user.displayName.split(' ')[1]

          if (credentials.user.photoURL) {
            avatar = [{
              id: '',
              name: 'photo.jpg',
              privateUrl: '',
              publicUrl: credentials.user.photoURL,
            }, ];
          }
        }

        await service.updateProfile(
          firstName,
          lastName,
          credentials.user.phoneNumber,
          countryCode,
          lang,
          avatar,
          currentUser.accountType
        );
        currentUser.avatars = avatar;
        currentUser.accountType = 'company';
      } else {
        if (currentUser.accountType != 'company') {
          throw {code: "auth/user-not-found", message: "There is no user record corresponding to this identifier. The user may have been deleted.", a: null}
        }
      }
    }

    // in background
    service.reauthenticateWithStorageToken();

    commit('AUTH_SUCCESS', {
      currentUser,
      authenticationUser,
    });
    localStorage.setItem('currentUser', service.encryption(currentUser, 'secret-c-u'));
    routerAsync().push('/');
  } catch (error) {
    // console.log('error ==>> ' ,error );
    await service.signout();
    Errors.handle(error);
    commit('AUTH_ERROR');
  }
},
//#endregion

//#region [ Authentication With Email and Password ]
    async doRegisterEmailLinkedPhone({ commit }, { email, password, countryCode }) {
      try {
        commit('AUTH_START');

        let currentUser;
        let authenticationUser;
        console.log(firebase.auth().currentUser);
        

        const credential = await firebase.auth.EmailAuthProvider.credential(
          email,
          password,
        );

        console.log(credential);
        

        await firebase.auth().currentUser.linkWithCredential(credential).then(function(userCredential) {
          var user = userCredential.user;
          currentUser = user;
          console.log("Account linking success", user);
          
        },
        function(error) {
          console.log("Account linking error", error);
          
        });

        const credentials = await firebase.auth().signInWithCredential(credential);
        await service.sendEmailVerification(credentials.user);

        if (credentials && credentials.user) {
          authenticationUser = credentials.user;
          currentUser = await service.fetchMe();
          currentUser.emailVerified = authenticationUser.emailVerified;
          currentUser.phoneNumber = authenticationUser.phoneNumber;
          currentUser.accountType = 'company';
          currentUser.lang = localStorage.getItem('language') || 'en';
          currentUser.countryCode = countryCode
          console.log(currentUser);
          //#region [old]
          // await service.updateProfile(
          //   currentUser.firstName,
          //   currentUser.lastName,
          //   currentUser.phoneNumber,
          //   currentUser.countryCode,
          //   currentUser.lang,
          //   currentUser.avatars,
          //   currentUser.accountType,
          // );
          //#endregion


          // await service.updateProfile(
          //   // currentUser.firstName + currentUser.lastName,
          //   currentUser.firstName,
          //   currentUser.phoneNumber,
          //   currentUser.countryCode,
          //   currentUser.lang,
          //   currentUser.avatars,
          //   'company',
          //   null,
          //   null,
          //   null
          // )
        }

        // in background
        service.reauthenticateWithStorageToken();

        commit('AUTH_SUCCESS', {
          currentUser,
          authenticationUser,
        });

        // encrypt cerrentUser and Save currentUser in localStorage
        localStorage.setItem(
          'currentUser',
          service.encryption(currentUser, 'secret-c-u'),
        );

        routerAsync().push('/auth/complete-profile');
      } catch (error) {
        await service.signout();
        Errors.handle(error);
        commit('AUTH_ERROR');
        console.log('auth-store doregisteremail >>', error);
      }
    },

    async doRegisterEmailAndPassword({ commit }, { email, password }) {
      try {
        commit('AUTH_START');
        const authenticationUser = await service.registerWithEmailAndPassword(
          email,
          password,
        );
          console.log('authenticationUser',authenticationUser);
        // let currentUser = await service.fetchMe();
        // currentUser.emailVerified = authenticationUser.emailVerified;
        // currentUser.lang = localStorage.getItem('language');
        // currentUser.accountType = 'company';

        // firebase.auth().languageCode = localStorage.getItem('language');

        // // update profile
        // await service.updateProfile(
        //   currentUser.firstName,
        //   currentUser.lastName,
        //   currentUser.phoneNumber,
        //   currentUser.countryCode,
        //   currentUser.lang,
        //   currentUser.avatars,
        //   currentUser.accountType,
        // );

        // // in background
        // service.reauthenticateWithStorageToken();

        // commit('AUTH_SUCCESS', {
        //   currentUser,
        //   authenticationUser,
        // });

        // routerAsync().push('/dashboard');
      } catch (error) {
        await service.signout();
        Errors.handle(error);
        commit('AUTH_ERROR');
      }
    },

    async doSigninWithEmailAndPassword({ commit }, { email, password, rememberMe }) {
      try {
        commit('AUTH_START');

        let authenticationUser = null;
        let currentUser = null;
        const credentials = await service.signinWithEmailAndPassword(
          email,
          password,
          rememberMe,
        );
          console.log(credentials);
        if (credentials && credentials.user) {
          authenticationUser = credentials.user;
          currentUser = await service.fetchMe();
          currentUser.emailVerified = authenticationUser.emailVerified;
          if (currentUser.accountType != 'company') {
            throw {code: "auth/user-not-found", message: "There is no user record corresponding to this identifier. The user may have been deleted.", a: null}
          }
        }

        service.reauthenticateWithStorageToken();

        commit('AUTH_SUCCESS', {
          currentUser,
          authenticationUser,
        });
        localStorage.setItem('currentUser', service.encryption(currentUser, 'secret-c-u'));
        const language = localStorage.getItem('language')
        routerAsync().push(`/${language}`);
      } catch (error) {        
        console.log(error);
        await service.signout();
        Errors.handle(error);
        commit('AUTH_ERROR');
      }
    },

    async doEmailVerified({ commit }) {
      try {
        //debugger;
        // await service.checkEmailVerified();
        commit('VERIFY_START');
        await firebase.auth().currentUser.reload();
        await firebase
          .auth()
          .onAuthStateChanged((authenticationUser) => {
            // authenticationUser.emailVerified = true;
            // console.log(authenticationUser.emailVerified)

            if (authenticationUser.emailVerified) {
              commit('VERIFY_SUCCESS');
              document.location.reload();
              // routerAsync().push('/');
            } else {
              commit('VERIFY_SUCCESS');
              Message.error(i18n('Email not verified yet'));
            }
          });
      } catch (error) {
        service.signout();
        Errors.handle(error);
        commit('VERIFY_ERROR');
      }
    },
//#endregion

    

    async doSignout({ commit }) {
      try {
        commit('AUTH_START');
        await service.signout();
        // const lang = localStorage.getItem('language');
        // localStorage.clear();
        // localStorage.setItem('language', lang);

        commit('AUTH_SUCCESS', {
          authenticationUser: null,
          currentUser: null,
        });

        routerAsync().push('/auth/signin');
      } catch (error) {
        Errors.handle(error);
        commit('AUTH_ERROR');
      }
    },

    async doSigninFromAuthChange(
      { commit },
      authenticationUser,
    ) {
      try {
        let currentUser = localStorage.getItem(
          'currentUser',
        );
        // let currentUser = JSON.parse(userStorge);
        // //debugger;
        if (authenticationUser) {
          // moment.locale(arg);
          // localStorage.setItem('language', arg);
          if (!currentUser) {
            currentUser = await service.fetchMe();
            localStorage.setItem(
              'currentUser',
              service.encryption(currentUser, 'secret-c-u'),
            );
            // localStorage.setItem('currentUser', JSON.stringify(service.encryptObject(currentUser, 'C-U')));
          } else {
            // currentUser = service.decryptObject(currentUser, 'C-U');
            currentUser = service.decryption(
              currentUser,
              'secret-c-u',
            );
          }
          // console.log(currentUser);
          // await service.updateProfile(
          //   currentUser.firstName,
          //   currentUser.lastName,
          //   currentUser.phoneNumber,
          //   currentUser.countryCode ,
          //   currentUser.lang,
          //   currentUser.avatars,
          // );

          // in background
          // service.reauthenticateWithStorageToken();
          currentUser.emailVerified =
            authenticationUser.emailVerified;
        }
        commit('AUTH_INIT_SUCCESS', {
          currentUser,
          authenticationUser: authenticationUser,
        });
        ProgressBar.done();
      } catch (error) {
        service.signout();
        Errors.handle(error);
        commit('AUTH_INIT_ERROR', error);
        ProgressBar.done();
      }
    },

    async doRefreshCurrentUser({ commit, getters }) {
      try {
        const authenticationUser =
          getters.authenticationUser;

        // const currentUser = await service.fetchMe();

        let currentUser = localStorage.getItem(
          'currentUser',
        );
        if (!currentUser) {
          currentUser = await service.fetchMe();
          localStorage.setItem(
            'currentUser',
            service.encryption(currentUser, 'secret-c-u'),
          );
        } else {
          currentUser = service.decryption(
            currentUser,
            'secret-c-u',
          );
        }

        currentUser.emailVerified =
          authenticationUser.emailVerified;

        // in background
        // service.reauthenticateWithStorageToken();

        commit('CURRENT_USER_REFRESH_SUCCESS', {
          currentUser,
        });
      } catch (error) {
        service.signout();
        Errors.handle(error);

        commit('CURRENT_USER_REFRESH_ERROR', error);
      }
    },

    async doUpdateProfile(
      { commit, dispatch },
      {
        fullName,
        firstName,
        lastName,
        phoneNumber,
        countryCode,
        avatars,
        lang,
        accountType,
        workingEmail,
        alternativePhoneNumber,
        companyId,
        email
      },
    ) {
      try {
        commit('UPDATE_PROFILE_START');
        // console.log(companyId);
        // console.log(fullName);
        // console.log(phoneNumber);
        // console.log(accountType);
        // console.log(email);
        // console.log(alternativePhoneNumber);
        // console.log(workingEmail);
        // console.log(countryCode);
        // console.log(avatars);       
       

        let currentUser = localStorage.getItem(
          'currentUser',
        );
        if (currentUser) {
          let user = service.decryption(
            currentUser,
            'secret-c-u',
          );
          user.firstName = firstName;
          user.lastName = lastName;
          // user.fullName = firstName + ' ' + lastName;
          user.fullName = fullName
          user.phoneNumber = phoneNumber;
          user.countryCode = countryCode;
          user.lang = lang;

          user.avatars = avatars;
          user.accountType = accountType;
          user.workingEmail = workingEmail
          user.alternativePhoneNumber = alternativePhoneNumber
          user.companyId = companyId
          user.email = email


          localStorage.setItem(
            'currentUser',
            service.encryption(user, 'secret-c-u'),
          );
        }

        await service.updateProfile(
          // firstName,
          // lastName,
          fullName,
          phoneNumber,
          countryCode,
          lang,
          avatars,
          accountType,
          workingEmail,
          alternativePhoneNumber,
          companyId,
          email
        );

        commit('UPDATE_PROFILE_SUCCESS');
        await dispatch('doRefreshCurrentUser');
        Message.success(i18n('auth.profile.success'));
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_PROFILE_ERROR');
      }
    },


    async doCreateUser(
      { commit, dispatch },
      {
        fullName,
        firstName,
        lastName,
        phoneNumber,
        countryCode,
        avatars,
        lang,
        accountType,
        workingEmail,
        alternativePhoneNumber,
        companyId,
        email
      },
    ) {
      try {
        commit('UPDATE_PROFILE_START');
        // console.log(companyId);
        // console.log(fullName);
        // console.log(phoneNumber);
        // console.log(accountType);
        // console.log(email);
        

        

        let currentUser = localStorage.getItem(
          'currentUser',
        );
        if (currentUser) {
          let user = service.decryption(
            currentUser,
            'secret-c-u',
          );
          user.firstName = firstName;
          user.lastName = lastName;
          user.fullName = fullName
          user.phoneNumber = phoneNumber;
          user.countryCode = countryCode;
          user.lang = lang;

          user.avatars = avatars;
          user.accountType = accountType;
          user.workingEmail = workingEmail
          user.alternativePhoneNumber = alternativePhoneNumber
          user.companyId = companyId
          user.email = email


          localStorage.setItem(
            'currentUser',
            service.encryption(user, 'secret-c-u'),
          );
        }

        await service.authCreateUserAndCompany(
          fullName,
          phoneNumber,
          countryCode,
          lang,
          avatars,
          accountType,
          workingEmail,
          alternativePhoneNumber,
          companyId,
          email
        );

        commit('UPDATE_PROFILE_SUCCESS');
        await dispatch('doRefreshCurrentUser');
        Message.success(i18n('auth.profile.success'));
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_PROFILE_ERROR');
      }
    },

    async doCreateCompany({commit},data){
      console.log(data);
      commit('CREATE_COMPANY_STARTED')
      const { id } = await service.createCompany(data)      
      commit('CREATE_COMPANY_SUCCESS', id)
      return id
    },
    async doUpdateCompany({commit},data){
      commit('UPDATE_COMPANY_SUCCESS')
      await service.updateCompany(data.id, data.values)      
      commit('UPDATE_COMPANY_SUCCESS')      
    }
  },
};
