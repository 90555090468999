import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/database';
// import FirebaseRepository from '@/shared/firebase/firebase-repository';
import {
  firestoreAction,
  vuexfireMutations,
} from 'vuexfire';

export default {
  namespaced: true,

  state: {
    loading: false,
    completedTrips: null,
    profit: null,
    offers: null,
    newAdmins: null,
    newClients: null,
    newTourguides: null,
    statistics: null,
    inProgressTrips: [],
    tripCity: {},
    tripCompany: {},
    notifications: []
  },

  getters: {
    loading: (state) => state.loading,
    completedTrips: (state) => state.completedTrips,
    profit: (state) => state.profit,
    offers: (state) => state.offers,
    newAdmins: (state) => state.newAdmins,
    newClients: (state) => state.newClients,
    newTourguides: (state) => state.newTourguides,
    statistics: (state) => state.statistics,
    inProgressTrips: (state) => state.inProgressTrips,
    tripCity: (state) => state.tripCity,
    tripCompany: (state) => state.tripCompany,
    notifications: (state) => state.notifications,
  },

  mutations: {
    ...vuexfireMutations,

    FETCH_STARTED(state) {
      state.loading = true;
    },
    FETCH_FINISHED(state) {
      state.loading = false;
    },
    SET_COMPLETED_TRIPS(state, payload) {
      state.completedTrips = payload;
    },
    SET_PROFIT(state, payload) {
      state.profit = payload;
    },
    SET_OFFERS(state, payload) {
      state.offers = payload;
    },
    SET_NEW_ADMINS(state, payload) {
      state.newAdmins = payload;
    },
    SET_NEW_CLIENTS(state, payload) {
      state.newClients = payload;
    },
    SET_NEW_TOURGUIDES(state, payload) {
      state.newTourguides = payload;
    },
    SET_STATISTICS(state, payload) {
      state.statistics = payload;
    },
  },

  actions: {
    async doFetchDashboard({ commit,rootGetters }) {
      commit('FETCH_STARTED');
      const companyId = rootGetters['auth/currentUser'].companyId
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth()+1;
      var ref = firebase
        .database()
        .ref(`company-dashboard/${companyId}/${currentYear}/${currentMonth}`);
      await ref.on('value', (snapshot) => {
        console.log(snapshot);
        var data = snapshot.val();
        debugger
        commit('SET_COMPLETED_TRIPS', data.completedTrips);
        commit('SET_PROFIT', data.profit);
        commit('SET_OFFERS', data.offers);
        commit('SET_NEW_ADMINS', data.newAdmins);
        commit('SET_NEW_CLIENTS', data.newClients);
        commit('SET_NEW_TOURGUIDES', data.newTourGuides);
        commit('SET_STATISTICS', data.statistics);
        commit('FETCH_FINISHED');
        debugger
      });
    },
    doFetchInProgressTrips: firestoreAction(
      async (context) => {
        // const customSerializer = (doc) => {
        //   const data = FirebaseRepository.mapDocument(doc);
        //   Object.defineProperty(data, '_doc', {
        //     value: doc,
        //   });
        //   return data;
        // };

        const db = firebase.firestore();
        const query = db
          .collection('trip')
          .where('status', '==', 'started');

        await context.bindFirestoreRef(
          'inProgressTrips',
          query,
        //   { serialize: customSerializer },
        );
        await context.dispatch('doFetchTripInfo');
      },
    ),
    doFetchTripInfo: firestoreAction(async (context) => {
      var db = firebase.firestore();
      for (var trip of context.getters.inProgressTrips) {
        var query = db
          .collection('city')
          .doc(trip.startCityId);
        await context.bindFirestoreRef('tripCity', query);
        trip.startCityId = context.getters.tripCity.name;

        query = db
          .collection('company')
          .doc(trip.companyId);
        await context.bindFirestoreRef('tripCompany', query);
        trip.companyId = context.getters.tripCompany.name;

        // query = db
        //   .collection('city')
        //   .doc(trip.guidesId[0]);
        // await context.bindFirestoreRef('tripGuide', query);
        // trip.guidesId[0] = context.getters.tripGuide.name;
        // console.log(trip);
      }
    }),
    doFetchNotifications: firestoreAction(
      async (context,id) => {
        // const customSerializer = (doc) => {
        //   const data = FirebaseRepository.mapDocument(doc);
        //   Object.defineProperty(data, '_doc', {
        //     value: doc,
        //   });
        //   return data;
        // };

        const db = firebase.firestore();
        const query = db
          .collection('company').doc(id).collection('notification')

        await context.bindFirestoreRef(
          'notifications',
          query,
        //   { serialize: customSerializer },
        );
      },
    ),
  },
};
