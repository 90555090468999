import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/iam/permission-checker';

export class PlacePermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.placeRead,
    );
    this.import = permissionChecker.match(
      Permissions.values.placeImport,
    );
    this.placeAutocomplete = permissionChecker.match(
      Permissions.values.placeAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.placeCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.placeEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.placeDestroy,
    );
  }
}
