import { NotificationService } from '@/modules/notification/notification-service';
import { PlaceService } from '@/modules/place/place-service';
import Errors from '@/shared/error/errors';
import { routerAsync } from '@/app-module';
import Message from '@/shared/message/message';
import { i18n } from '@/i18n';

export default {
  namespaced: true,

  state: {
    findLoading: false,
    saveLoading: false,
    record: null,
    listPlaces:[],
    PlacesCount:0,
  },

  getters: {
    record: (state) => state.record,
    findLoading: (state) => !!state.findLoading,
    saveLoading: (state) => !!state.saveLoading,
    listPlaces: (state) => state.listPlaces,
  },

  mutations: {
    RESET(state) {
      state.findLoading = false;
      state.saveLoading = false;
      state.record = null;
    },

    FIND_STARTED(state) {
      state.record = null;
      state.findLoading = true;
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.findLoading = false;
    },
    
    FIND_PLACES_START(state) {
      state.findLoading = true;
    },
    FIND_PLACES_SUCCESS(state,payload) {
      state.listPlaces = payload.rows;
      state.PlacesCount = payload.conut;
      state.findLoading = false;
    },
    FIND_ERROR(state) {
      state.record = null;
      state.findLoading = false;
    },

    CREATE_STARTED(state) {
      state.saveLoading = true;
    },

    CREATE_SUCCESS(state) {
      state.saveLoading = false;
    },

    CREATE_ERROR(state) {
      state.saveLoading = false;
    },

    UPDATE_STARTED(state) {
      state.saveLoading = true;
    },

    UPDATE_SUCCESS(state) {
      state.saveLoading = false;
    },

    UPDATE_ERROR(state) {
      state.saveLoading = false;
    },
  },

  actions: {
    doNew({ commit }) {
      commit('RESET');
    },

    async doFind({ commit }, id) {
      try {
        commit('FIND_STARTED');

        const record = await NotificationService.find(id);

        commit('FIND_SUCCESS', record);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        routerAsync().push('/notification');
      }
    },

    async doFindPlaces({ commit }) {
      try {
        commit('FIND_PLACES_START');
        const filter ={}
        const response = await PlaceService.list(filter);

        commit('FIND_PLACES_SUCCESS', {
          rows: response.rows,
          count: response.count
        });
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        routerAsync().push('/notification');
      }
    },


    async doFindPlaceByPosition({commit},filter) {
      try {
        // commit('FIND_PLACES_START');
        // const filter ={}
        // //debugger
        const response = await PlaceService.list(filter);

        return response.rows;
        // commit('FIND_PLACES_SUCCESS', {
        //   rows: response.rows,
        //   count: response.count
        // });
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        routerAsync().push('/notification');
      }
    },

    async doCreate({ commit }, values) {
      try {
        commit('CREATE_STARTED');
        await NotificationService.create(values);
        commit('CREATE_SUCCESS');
        Message.success(i18n('entities.notification.create.success'));
        // //debugger
        routerAsync().push('/place');
      } catch (error) {
        Errors.handle(error);
        commit('CREATE_ERROR');
      }
    },

    async doUpdate({ commit }, { id, values }) {
      try {
        commit('UPDATE_STARTED');

        await NotificationService.update(id, values);

        commit('UPDATE_SUCCESS');
        Message.success(i18n('entities.notification.update.success'));
        routerAsync().push('/notification');
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },
  },
};
