import { CompanyService } from '@/modules/company/company-service';
import Errors from '@/shared/error/errors';

export default {
  namespaced: true,

  state: {
    loading: false,
    record: null,
    oldRecord: {}
  },

  getters: {
    record: (state) => state.record,
    loading: (state) => !!state.loading,
    oldRecord: (state) => state.oldRecord
  },

  mutations: {
    FIND_STARTED(state) {
      state.record = null;
      state.loading = true;
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.loading = false;
    },

    FIND_ERROR(state) {
      state.record = null;
      state.loading = false;
    },
    SET_OLD_RECORD(state, payload) {
      state.oldRecord = payload
    }
  },

  actions: {
    async doFind({ commit }, id) {
      try {
        commit('FIND_STARTED');
        const record = await CompanyService.find(id);
        // const temp = record
        const temp = Object.assign({}, record)
        console.log(temp);
        const temp1 = Object.assign({}, record)

        commit('FIND_SUCCESS', temp);
        commit('SET_OLD_RECORD',  temp1)
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
      }
    },
  },
};

