import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-company';
// import { storeAsync } from '@/app-module';
// import firebase from 'firebase/app'
// import 'firebase/firestore';

export class CompanyService {

static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query COMPANY_FIND($id: String!) {
          companyFind(id: $id) {
            id
            name {
              en
              ar
            }
            description {
              en
              ar
            }
            logo{
                publicUrl
            }
            license{
                publicUrl
            }
            commercialRegister{
              publicUrl
            }
            email
            websiteLink
            facebookLink
            twitterLink
            instagramLink
            phoneNumber
            countryCode
            languages
            status
            rejectionReason
            disabled
            address{
              en
              ar
            }
            countryId
            cityId
            countryName
            rate


            bankName,
            bankSwiftCode,
            bankIban,
            bankCountry,
            bankCity,
    
            createdBy
            updatedBy
            createdAt
            updatedAt
          }
        }
      `,

      variables: {
        id,
      },
    });

    return response.data.companyFind;
  }

static async update(id,data) {
  debugger
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation COMPANY_UPDATE($id: String!, $data: CompanyInput!) {
          companyUpdate(id: $id,data:$data) {
            id
            name {
              en
              ar
            }
            description {
              en
              ar
            }
            logo{
                publicUrl
            }
            license{
                publicUrl
            }
            commercialRegister{
              publicUrl
            }
            email
            websiteLink
            facebookLink
            twitterLink
            instagramLink
            phoneNumber
            countryCode
            languages
            status
            rejectionReason
            disabled
            address{
              en
              ar
            }
            countryId
            cityId
            countryName
            rate


            bankName,
            bankSwiftCode,
            bankIban,
            bankCountry,
            bankCity,
    
            createdBy
            updatedBy
            createdAt
            updatedAt
          }
        }
      `,

      variables: {
        id,
        data
      },
    });
    debugger
    return response.data.companyUpdate;
  }
}