import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-guide';

export class TourGuideService {
  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql `
        mutation GUIDE_CREATE($data: GuideInput!) {
          guideCreate(data: $data) {
            id
            authenticationUid
            fullName
            phoneNumber
            email
            password
          }
        }`,
      variables: {
        data,
      },
    });
    debugger
    return response.data.guideCreate
  }

  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql `
            mutation GUIDE_UPDATE($id: String!, $data: GuideInput!) {
              guideUpdate(id: $id, data: $data){
                  id
                  authenticationUid
                  fullName
                  phoneNumber
                  email
                  password
              }
            }`,
      variables: {
        id,
        data,
      },
    });

    return response.data.guideUpdate;
  }

  static async list(companyId, orderBy, pagination) {
    const response = await graphqlClient.query({
      query: gql `
        query GUIDE_COMPANY_LIST_PAGINATION (
          $companyId: String!
          $orderBy: String
          $pagination: PaginationInput
        ) {
          guideCompanyListPagination (
            companyId: $companyId
            orderBy: $orderBy
            pagination: $pagination
          ) {
            pagination {
              isFirstPage
              isLastPage
            }
            count
            rows {
              id
              authenticationUid
              fullName
              disabled
              email
              password
              phoneNumber 
              avatar {
                name
                publicUrl
              }
              accountType
              rate
              isMale
              languages
              reviews {
                rate
                description
                reviewer {
                  id 
                  fullName
                  email
                  phoneNumber
                }
                createdBy
                createdAt
              }

              location {
                latitude
                longitude
              }
              deviceTokens
              status
              rejectionReason
              license {
                name
                publicUrl
              }

              companyId
              branchId
              cityId
              bankName
              bankIban
              bankSwiftCode
              bankCity
              bankCountry

              company {
                id
                logo {
                  name
                  publicUrl
                }
                name {
                  en
                  ar
                }
                description {
                  en
                  ar
                }
              }
              branch {
                id
                name {
                  en
                  ar
                }
                address {
                  en
                  ar
                }
                cityId 
                phoneNumber 
              }  
              tripsCount
              trips {
                id
                name {
                  en
                  ar
                }
                tripTime
              }

              createdAt
              updatedAt
              createdBy
              updatedBy
            }
          }
        }
      `,

      variables: {
        companyId,
        orderBy,
        pagination
      },
    });

    return response.data.guideCompanyListPagination;
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql `
            query GUIDE_FIND($id: String!) {
              guideFind(id: $id) {
                id
                authenticationUid
                fullName
                disabled
                email
                password
                phoneNumber 
                avatar {
                  name
                  publicUrl
                }
                accountType
                rate
                isMale
                languages
                reviews {
                  rate
                  description
                  reviewer {
                    id 
                    fullName
                    email
                    phoneNumber
                  }
                  createdBy
                  createdAt
                }
                location {
                  latitude
                  longitude
                }
                deviceTokens
                status
                rejectionReason
                license {
                  name
                  publicUrl
                }

                companyId
                branchId
                cityId

                bankName
                bankIban
                bankSwiftCode
                bankCity
                bankCountry

                company {
                  id
                  logo {
                    name
                    publicUrl
                  }
                  name {
                    en
                    ar
                  }
                  description {
                    en
                    ar
                  }
                }
                branch {
                  id
                  name {
                    en
                    ar
                  }
                  address {
                    en
                    ar
                  }
                  cityId 
                  phoneNumber 
                }  
                tripsCount
                trips {
                  id
                  name {
                    en
                    ar
                  }
                  tripTime
                }
                createdAt
                updatedAt
                createdBy
                updatedBy
              }
            }
          `,

      variables: {
        id,
      },
    });

    return response.data.guideFind;
  }

  static async disable(ids) {
    return this._changeStatus(ids, true);
  }

  static async _changeStatus(ids, disabled) {
    const response = await graphqlClient.mutate({
      mutation: gql `
            mutation IAM_CHANGE_STATUS(
              $ids: [String!]!
              $disabled: Boolean
            ) {
              iamChangeStatus(ids: $ids, disabled: $disabled)
            }
          `,

      variables: {
        ids,
        disabled: !!disabled,
      },
    });

    return response.data.iamChangeStatus;
  }

  static async destroyAll(ids) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation CITY_DESTROY($ids: [String!]!) {
          cityDestroy(ids: $ids)
        }
      `,

      variables: {
        ids,
      },
    });

    return response.data.cityDestroy;
  }
}
