import { i18n } from '@/i18n';
// import { Notification } from 'element-ui';
import { Notify } from 'quasar'
export default class Message {
  static success(payload) {
    // Notification({
    //   showClose: true,
    //   message: payload,
    //   type: 'success',
    //   duration: 6000,
    // });
  //debugger
   const not =  Notify.create({
      message: payload,
      color: 'white',
      textColor: 'green',
      timeout: 6000,
      position:'top-right',
      icon:'fas fa-thumbs-up' 
      // actions:[
      //   { label: 'Reply', color: 'cyan', handler: () => { /* console.log('wooow') */ } },
      //   { label: 'Dismiss', color: 'cyan', handler: () => { /* console.log('wooow') */ } }
      // ]
    })
    return not
  }

  static error(payload) {
    let message = payload;

    if (!message) {
      message = i18n('errors.defaultErrorMessage');
    }
    // eslint-disable-next-line

    // Notification({
    //   showClose: true,
    //   message,

    //   type: 'error',
    //   duration: 6000,
    // });
    Notify.create({
      message: payload,
      color: 'white',
      textColor: 'red',
      timeout: 6000,
      position:'top-right',
      icon:'fas fa-thumbs-down'
    })
  }
}
