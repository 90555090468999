import Roles from '@/security/roles';
const roles = Roles.values;

class Permissions {
  static get values() {
    return {
      iamEdit: {
        id: 'iamEdit',
        allowedRoles: [
          roles.owner,
          roles.iamSecurityReviewer,
          roles.editor,
        ],
        allowedStorageFolders: ['user'],
      },
      iamCreate: {
        id: 'iamCreate',
        allowedRoles: [
          roles.owner,
          roles.iamSecurityReviewer,
          roles.editor,
        ],
      },
      iamImport: {
        id: 'iamImport',
        allowedRoles: [
          roles.owner,
          roles.iamSecurityReviewer,
          roles.editor,
        ],
      },
      iamRead: {
        id: 'iamRead',
        allowedRoles: [
          roles.owner,
          roles.iamSecurityReviewer,
          roles.editor,
          roles.viewer,
        ],
      },
      iamUserAutocomplete: {
        id: 'iamUserAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,


        ],
      },
      auditLogRead: {
        id: 'auditLogRead',
        allowedRoles: [roles.owner, roles.auditLogViewer, roles.viewer],
      },
      settingsEdit: {
        id: 'settingsEdit',
        allowedRoles: [roles.owner],
      },
      placeImport: {
        id: 'placeImport',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.placeEditor,
        ],
      },
      placeCreate: {
        id: 'placeCreate',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.placeEditor,
        ],
        allowedStorageFolders: ['place'],
      },
      placeEdit: {
        id: 'placeEdit',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.placeEditor,
        ],
        allowedStorageFolders: ['place'],
      },
      placeDestroy: {
        id: 'placeDestroy',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.placeEditor,
        ],
        allowedStorageFolders: ['place'],
      },
      placeRead: {
        id: 'placeRead',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.placeEditor,
          roles.placeViewer,
        ],
      },
      placeAutocomplete: {
        id: 'placeAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.placeEditor,
          roles.placeViewer,

        ],
      },

      notificationImport: {
        id: 'notificationImport',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.notificationEditor,
        ],
      },
      notificationCreate: {
        id: 'notificationCreate',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.notificationEditor,
        ],
        allowedStorageFolders: ['notification'],
      },
      notificationEdit: {
        id: 'notificationEdit',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.notificationEditor,
        ],
        allowedStorageFolders: ['notification'],
      },
      notificationDestroy: {
        id: 'notificationDestroy',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.notificationEditor,
        ],
        allowedStorageFolders: ['notification'],
      },
      notificationRead: {
        id: 'notificationRead',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.notificationEditor,
          roles.notificationViewer,
        ],
      },
      notificationAutocomplete: {
        id: 'notificationAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.notificationEditor,
          roles.notificationViewer,

        ],
      },
    };
  }

  static get asArray() {
    return Object.keys(this.values).map((value) => {
      return this.values[value];
    });
  }
}

export default Permissions;
