// import Layout from '@/modules/layout/layout/dashboard/DashboardLayout';
import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';
import { i18n } from '@/i18n';

const TripListPage = () =>
  import('@/modules/trip/components/trip-list-page.vue');
const TripListHistoryPage = () =>
  import('@/modules/trip/components/trip-list-history-page.vue');
const TripFormPage = () =>
  import('@/modules/trip/components/trip-form-page.vue');
const TripViewPage = () =>
  import('@/modules/trip/components/trip-view-page.vue');
// const TripFormPage = () =>
//   import('@/modules/trip/components/trip-form-page.vue');
// const TripEditPage = () =>
//   import('@/modules/trip/components/trip-edit-page.vue');
// const TripReorderPage = () =>
//   import('@/modules/trip/components/trip-reorder-page.vue');
// const TripViewPage = () =>
//   import('@/modules/trip/components/trip-view-page.vue');

export default [
  {
    name: '',
    path: '/:lang?',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'trip',
        path: 'trip',
        component: TripListPage,
        meta: {
          auth: true,
          permission: Permissions.values.tripView,
          title: i18n('metaTitle.trip')

        },
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },

      {
        name: 'trip/:id',
        path: 'trip/:id',
        component: TripViewPage,
        meta: {
          auth: true,
          permission: Permissions.values.tripView,
          title: i18n('metaTitle.trip')

        },
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },

      {
        name: 'historyTrip',
        path: 'history-trip',
        component: TripListHistoryPage,
        meta: {
          auth: true,
          permission: Permissions.values.tripView,
          title: i18n('metaTitle.tripHistory')

        },
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },


      {
        name: 'tripNew',
        path: 'tripNew',
        component: TripFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.tripCreate,
          title: i18n('metaTitle.newTrip') 
        },
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },






      // {
      //   name: 'tripNew',
      //   path: '/trip/new',
      //   component: TripFormPage,
      //   meta: {
      //     auth: true,
      //     permission: Permissions.values.tripCreate,
      //     title: i18n('metaTitle.newTrip') 
      //   },
      //   beforeEnter: (to, from, next) => {
      //     document.title = to.meta.title
      //     next()
      //   }
      // },
      // {
      //   name: 'tripEdit',
      //   path: '/trip/:id/edit',
      //   component: TripFormPage,
      //   meta: {
      //     auth: true,
      //     permission: Permissions.values.tripEdit,          
      //     title: i18n('metaTitle.editTrip') 
      //   },
      //   props: true,
      //   beforeEnter: (to, from, next) => {
      //     document.title = to.meta.title
      //     next()
      //   }
      // },
      // {
      //   name: 'tripReorder',
      //   path: '/trip/:id/reorder',
      //   component: TripReorderPage,
      //   meta: {
      //     auth: true,
      //     permission: Permissions.values.tripEdit,          
      //     title: i18n('metaTitle.tripReorder') 
      //   },
      //   props: true,
      //   beforeEnter: (to, from, next) => {
      //     document.title = to.meta.title
      //     next()
      //   }
      // },
      // {
      //   name: 'tripView',
      //   path: '/trip/:id',
      //   component: TripViewPage,
      //   meta: {
      //     auth: true,
      //     permission: Permissions.values.tripView,
      //     title: i18n('metaTitle.viewTrip') 
      //   },
      //   props: true,
      //   beforeEnter: (to, from, next) => {
      //     document.title = to.meta.title
      //     next()
      //   }
      // },
    ],
  },
];
