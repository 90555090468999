// import { i18n } from '@/i18n';
import { i18n } from '@/vueI18n'
import IdField from '@/shared/fields/id-field';
// import DateTimeRangeField from '@/shared/fields/date-time-range-field';
// import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
// import IntegerField from '@/shared/fields/integer-field';
// import IntegerRangeField from '@/shared/fields/integer-range-field';
import StringField from '@/shared/fields/string-field';
import ImagesField from '@/shared/fields/images-field';
import DecimalField from '@/shared/fields/decimal-field';
import JsonField from '@/shared/fields/json-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
// import JsonField from '@/shared/fields/json-field';
// import { PlaceTypeField } from '@/modules/placeType/placeType-field';
// import { CityField } from '@/modules/city/city-field';

function label(name) {
  return i18n(`entities.place.fields.${name}`);
}

// function enumeratorLabel(name, value) {
//   return i18n(`entities.placeType.enumerators.${name}.${value}`);
// }


const fields = {
  // id: new IdField('id', label('id')),
  // placeName: new JsonField('placeName', label('placeName'), {}),
  // description: new JsonField('description', label('description'), {}),
  // cityId: new StringField('cityId', label('city'), {}),
  // placeType: new EnumeratorField('placeType', label('placeType'), [
  //   { value: 'mosque', label: enumeratorLabel('placesType', 'mosque') },
  //   { value: 'museum', label: enumeratorLabel('placesType', 'museum') },
  //   { value: 'playground', label: enumeratorLabel('placesType', 'playground') },
  //   { value: 'event', label: enumeratorLabel('placesType', 'events') },
  //   { value: 'mountain', label: enumeratorLabel('placesType', 'mountains') },
  //   { value: 'market', label: enumeratorLabel('placesType', 'market') },
  //   { value: 'cafe', label: enumeratorLabel('placesType', 'cafe') },
  // ],{}),
  // placeTypeAsEnum: new EnumeratorField('placeType', label('placeType'), [
  //   { value: 'mosque', icon: '/images/placeType/1.svg', label: enumeratorLabel('placesType', 'mosque') },
  //   { value: 'museum', icon: '/images/placeType/2.svg', label: enumeratorLabel('placesType', 'museum') },
  //   { value: 'playground', icon: '/images/placeType/3.svg', label: enumeratorLabel('placesType', 'playground') },
  //   { value: 'event', icon: '/images/placeType/4.svg', label: enumeratorLabel('placesType', 'events') },
  //   { value: 'mountain', icon: '/images/placeType/5.svg', label: enumeratorLabel('placesType', 'mountains') },
  //   { value: 'market', icon: '/images/placeType/6.svg', label: enumeratorLabel('placesType', 'market') },
  //   { value: 'cafe', icon: '/images/placeType/7.png', label: enumeratorLabel('placesType', 'cafe') },
  // ],{}),
  
  // visitCount: new IntegerField('visitCount', label('visitCount'), {}),
  // placePictures: new ImagesField('placePictures', label('placePictures'),
  //   '/place/image',
  // ),

  // lat: new DecimalField('lat', label('lat'), {
  //   "required": true,
  //   "min": -90,
  //   "max": 90
  // }),
  // lng: new DecimalField('lng', label('lng'), {
  //   "required": true,
  //   "min": -180,
  //   "max": 180
  // }),
  // radius: new DecimalField('radius', label('radius'), {}),
  // strokeWidth: new DecimalField('strokeWidth', label('strokeWidth'), {}),
  // strokeColor: new StringField('strokeColor', label('strokeColor'), {}),
  // fillColor: new StringField('fillColor', label('fillColor'), {}),
  // address: new JsonField('address', label('address'), {}),
  // notifications: new JsonField('notifications', label('notifications'), {}),
  // title: new StringField('title', label('title')),
  // message: new StringField('message', label('message')),

  // createdAt: new DateTimeField(
  //   'createdAt',
  //   label('createdAt'),
  // ),
  // updatedAt: new DateTimeField(
  //   'updatedAt',
  //   label('updatedAt'),
  // ),
  // radiusRange: new IntegerRangeField(
  //   'radiusRange',
  //   label('radiusRange'),
  // ),
  // strokeWidthRange: new IntegerRangeField(
  //   'strokeWidthRange',
  //   label('strokeWidthRange'),
  // ),
  // createdAtRange: new DateTimeRangeField(
  //   'createdAtRange',
  //   label('createdAtRange'),
  // ),

  id: new IdField('id', label('id')),
  placeName: new JsonField(
    'placeName',
    label('placeName'),
    {},
  ),
  cityId: new StringField('cityId', label('cityId'), {}),
  city: new StringField('city', label('city'), {}),
  lat: new DecimalField('lat', label('lat'), {}),
  lng: new DecimalField('lng', label('lng'), {}),
  address: new JsonField('address', label('address')),
  radius: new DecimalField('radius', label('radius'), {}),
  fillColor: new StringField(
    'fillColor',
    label('fillColor'),
    {},
  ),
  strokeWidth: new DecimalField(
    'strokeWidth',
    label('strokeWidth'),
    {},
  ),
  strokeColor: new StringField(
    'strokeColor',
    label('strokeColor'),
    {},
  ),

  // numberOfDays: new IntegerField('numberOfDays', label('numberOfDays'), {}),
  placeType: new EnumeratorField(
    'placeType',
    label('placeType'),
    [
      {
        value: i18n('entities.place.fields.mountain'),
        label: i18n('entities.place.fields.mountain'),
      },
      {
        value: i18n('entities.place.fields.mosque'),
        label: i18n('entities.place.fields.mosque'),
      },
      {
        value: i18n('entities.place.fields.market'),
        label: i18n('entities.place.fields.market'),
      },
      {
        value: i18n('entities.place.fields.playground'),
        label: i18n('entities.place.fields.playground'),
      },
      {
        value: i18n('entities.place.fields.museum'),
        label: i18n('entities.place.fields.museum'),
      },
      {
        value: i18n('entities.place.fields.event'),
        label: i18n('entities.place.fields.event'),
      },
    ],
  ),
  description: new JsonField(
    'description',
    label('description'),
    {},
  ),
  placePictures: new ImagesField(
    'placePictures',
    label('placePictures'),
    `place/images`,
    { max: 5 },
  ),
  notifications: new JsonField('notifications', label('notifications'), {}),
  // notificationImage: new ImagesField(
  //   'notificationImage',
  //   label('notificationImage'),
  //   `place/notificationImage`,
  // ),
  // notificationName: new StringField(
  //   'notificationName',
  //   label('notificationName'),
  //   {},
  // ),
  // notificationLanguage: new StringField(
  //   'notificationLanguage',
  //   label('notificationLanguage'),
  //   {},
  // ),
  // notificationTitle: new StringField(
  //   'notificationTitle',
  //   label('notificationTitle'),
  //   {},
  // ),
  // notificationText: new StringField(
  //   'notificationText',
  //   label('notificationText'),
  //   {},
  // ),
};

export class PlaceModel extends GenericModel {
  static get fields() {
    return fields;
  }
}