import importerStore from '@/shared/importer/importer-store';
import { PlaceService } from '@/modules/place/place-service';
import placeImporterFields from '@/modules/place/place-importer-fields';
import { i18n } from '@/i18n';

export default importerStore(
  PlaceService.import,
  placeImporterFields,
  i18n('entities.place.importer.fileName'),
  i18n('entities.place.importer.hint'),
);
