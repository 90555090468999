import branchListStore from '@/modules/branch/branch-list-store';
import branchViewStore from '@/modules/branch/branch-view-store';
import branchFormStore from '@/modules/branch/branch-form-store';
import branchDestroyStore from '@/modules/branch/branch-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: branchDestroyStore,
    form: branchFormStore,
    list: branchListStore,
    view: branchViewStore,
  },
};
