import gql from 'graphql-tag';
import graphqlClientWallet from '@/shared/graphql/client-wallet';
import graphqlClientPayment from '@/shared/graphql/client-payment';
import graphqlClientRequest from '@/shared/graphql/client-request';
import graphqlClientTransaction from '@/shared/graphql/client-transaction';



// import firebase from 'firebase/app';
import 'firebase/firestore';
// import { storeAsync } from '@/app-module';

export class PaymentService {
  static async walletAddCredit(amount) {
    const response = await graphqlClientWallet.mutate({
      mutation: gql`
        mutation WALLET_ADD_CREDIT($amount: Float!) {
          walletAddCredit(amount: $amount) 
        }
      `,
      variables: {
        amount,
      },
    });

    return response.data.walletAddCredit;
  }
  static async withdrawRequestCreate(data) {
    // {
    //   id
    //   amount
    // }
    const response = await graphqlClientRequest.mutate({
      mutation: gql`
        mutation REQUEST_CREATE($data: RequestInput!) {
          requestCreate(data: $data) 
        }
      `,
      variables: {
        data,
      },
    });

    return response.data.requestCreate;
  }

  static async findWallet(id) {
    const response = await graphqlClientWallet.query({
      query: gql`
        query WALLET_FIND($id: String!) {
          walletFind(id: $id) {
            id
            balance
            holdedBalance
            createdBy
            updatedBy
            createdAt
            updatedAt
          }
        }
      `,

      variables: {
        id,
      },
    });

    return response.data.walletFind;
  }


  static async listOwnTransactions(filter, orderBy, pagination) {
    const response = await graphqlClientTransaction.query({
      query: gql`
        query LIST_OWN_TRANSACTIONS($filter: OwnTransactionFilterInput, $orderBy: String, $pagination: PaginationInput) {
          listOwnTransactions(filter: $filter, orderBy: $orderBy, pagination: $pagination) {     
            count
            pagination {
                pageSize
                pagesNumber
                isFirstPage
                isLastPage
            }
            rows{            
              id     
              amount
              paymentId
              paymentPageUrl
              currencyCode
              trackId
              transId
          
              userId
              companyId
              payerId
              payeeId
              payerType
              payeeType
              guide {
                id
                fullName
                avatar {
                    name
                    publicUrl
                }
            }
            payer {
                avatar
                name {
                    en
                    ar
                }
                email
                phoneNumber
                type
                
            }
          
              paymentMethod
              status
              operation
              operationId
              type
          
              description {
                en
                ar
              }
              saryahPercentage
              vatPercentage
              dueDate
              
              trip{
                id
                name {
                  en
                  ar
                }
                status
                tripTime
               
              }
          
              createdBy
              updatedBy
              createdAt
              updatedAt
            }
          }
        }
      `,

      variables: {
        filter,
        orderBy,
        pagination
      },
    });
    debugger;
    return response.data.listOwnTransactions;
  }

  static async listMyRecentTransactions() {
    const response = await graphqlClientTransaction.query({
      query: gql`
        query LIST_MY_RECENT_TRANSACTIONS {
          listMyRecentTransactions {     
            id
            tranNum
            amount
            userId 
            createdAt
            operation
            type
            payee {
              avatar
              name {
                  en
                  ar
              }
              email
              phoneNumber
              type 
            }
          }
        }
      `,
    });
    debugger;
    return response.data.listMyRecentTransactions;
  }

  // listWalletTransactions(filter: WalletTransactionFilterInput, orderBy: String, pagination: PaginationInput): TransactionPage!
  static async listWalletTransactions(filter, orderBy, pagination) {
    const response = await graphqlClientTransaction.query({
      query: gql`
        query LIST_WALLET_TRANSACTIONS($filter: WalletTransactionFilterInput, $orderBy: String, $pagination: PaginationInput) {
          listWalletTransactions(filter: $filter, orderBy: $orderBy, pagination: $pagination) {     
            count
            pagination {
              isFirstPage
              isLastPage
            }
            rows{            
              id     
              amount
              paymentId
              paymentPageUrl
              currencyCode
              trackId
              transId
              tranNum
              userId
              companyId
              payerId
              payeeId
              payerType
              payeeType
          
              paymentMethod
              status
              type
              operation
              operationId
          
              description {
                en
                ar
              }
              saryahPercentage
              vatPercentage
              dueDate
              
              
          
              createdBy
              updatedBy
              createdAt
              updatedAt
            }
          }
        }
      `,

      variables: {
        filter,
        orderBy,
        pagination
      },
    });

    return response.data.listWalletTransactions;
  }

  static async decryptData(message) {
    const response = await graphqlClientPayment.mutate({
      mutation: gql`
        mutation DECRYPT_DATA($message: String!) {
          decryptData(message: $message) 
        }
      `,
      variables: {
        message,
      },
    });
    return response.data.decryptData;
  }

  static async requestFindPending(walletId){
    const response = await graphqlClientRequest.query({
      query: gql`
        query REQUEST_FIND_PENDING($walletId: String!) {
          requestFindPending(walletId: $walletId)
        }
      `,

      variables: {
        walletId,
      },
    });

    return response.data.requestFindPending;
  }
 
}
