import Layout from '@/modules/layout/components/layout';
// import Permissions from '@/security/permissions';
import { i18n } from '@/i18n';

const PaymentTransactionListPage = () =>
  import('@/modules/payment/components/payment-transaction-list-page.vue');
const PaymentWalletListPage = () =>
  import('@/modules/payment/components/payment-wallet-list-page.vue');
// const WalletListPage = () =>
//   import('@/modules/payment/components/wallet-list-page.vue');


export default [
  {
    name: '',
    path: '/:lang?',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'transaction',
        path: 'transaction',
        component: PaymentTransactionListPage,
        meta: {
          auth: true,
          title: i18n('metaTitle.payment')

        },
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },

      {
        name: 'wallet',
        path: 'wallet',
        component: PaymentWalletListPage,
        meta: {
          auth: true,
          title: i18n('metaTitle.payment')

        },
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },
    ],
  },
];
