// import iamListStore from '@/modules/iam/iam-list-store';
import adminFormStore from '@/modules/admins/admin-form-store';
import adminListStore from '@/modules/admins/admin-list-store';

// import iamViewStore from '@/modules/iam/iam-view-store';
// import iamImporterStore from '@/modules/iam/iam-importer-store';

export default {
  namespaced: true,

  modules: {
    list: adminListStore,
    form: adminFormStore,
    // view: iamViewStore,
    // importer: iamImporterStore,
  },
};
