import Vue from 'vue';
import Vuex from 'vuex';
import { vuexfireMutations } from 'vuexfire'
import ProgressBar from '@/shared/progress-bar/progress-bar';
import Router from 'vue-router';
import shared from '@/shared/shared-module';
import auth from '@/modules/auth/auth-module';
import layout from '@/modules/layout/layout-module';
import home from '@/modules/home/home-module';
import iam from '@/modules/iam/iam-module';
// import Users from '@/modules/Users/iam-module';
import tourGuide from '@/modules/tour-guide/tour-guide-module';
// import companyProfile from '@/modules/company-profile/company-profile-module'
// import settings from '@/modules/settings-old/settings-module';
import auditLog from '@/modules/audit-log/audit-log-module';
import notification from '@/modules/notification/notification-module';
// import place from '@/modules/place-old/place-module';
import city from '@/modules/city/city-module';

import plan from '@/modules/plan/plan-module';
import trip from '@/modules/trip/trip-module';
// import transaction from '@/modules/transaction/transaction-module';
import activeTrip from '@/modules/active-trip/active-trip-module';
import branch from '@/modules/branch/branch-module';
import reviews from '@/modules/reviews/reviews-module';
// import wallet from '@/modules/wallet/wallet-module';
import reservation from '@/modules/reservation/reservation-module';
import admins from '@/modules/admins/admins-module';

import notifications from '@/modules/notifications/notifications-module';
// import companyProfile from '@/modules/company-profile-new/company-profile-module'; //NEW COMPANY PROFILE
import company from '@/modules/company/company-module'; //NEW COMPANY PROFILE
import ownerProfile from '@/modules/owner-profile/owner-profile-module';
import settingsNew from '@/modules/settings/settings-module'; // NEW SETTINGS
import place from '@/modules/place/place-module'; 
import cycle from '@/modules/cycle/cycle-module';
import payment from '@/modules/payment/payment-module'
import complaint from '@/modules/complaint/complaint-module';
import invoice from '@/modules/invoice/invoice-module';


const modules = {
  shared,
  home,
  auth,
  iam,
  tourGuide,
  // companyProfile,
  company,
  auditLog,
  layout,
  notification,
  // place,
  city,
  // Users,
  plan,
  trip,

  // transaction,
  branch,
  reviews,
  // wallet,
  reservation,
  admins,
  notifications,
  // profile,
  ownerProfile,
  settingsNew,
  activeTrip,
  cycle,
  place,
  payment,
  complaint,
  invoice
};

// start - boilerplate code

const exists = (el) => !!el;

function setupComponentsFiltersDirectivesAndMixins() {
  Object.keys(modules)
    .map((key) => modules[key].components)
    .filter(exists)
    .forEach((components) => {
      components.forEach((component) => {
        Vue.component(component.name, component);
      });
    });

  Object.keys(modules)
    .map((key) => modules[key].filters)
    .filter(exists)
    .forEach((components) => {
      components.forEach((filter) => {
        Vue.filter(filter.name, filter.implementation);
      });
    });

  Object.keys(modules)
    .map((key) => modules[key].directives)
    .filter(exists)
    .forEach((directives) => {
      directives.forEach((directive) => {
        Vue.directive(
          directive.name,
          directive.implementation,
        );
      });
    });

  Object.keys(modules)
    .map((key) => modules[key].mixins)
    .filter(exists)
    .forEach((mixins) => {
      mixins.forEach((mixin) => {
        Vue.mixin(mixin);
      });
    });
}

const routes = [
  ...Object.keys(modules)
    .filter((key) => !!modules[key].routes)
    .map((key) => modules[key].routes)
    .reduce((a, b) => a.concat(b), []),
  { path: '*', redirect: '/404' },
];

let router = null;

const routerAsync = () => {
  if (!router) {
    router = new Router({
      mode: 'history',
      hash: false,
      routes,
      scrollBehavior: (to) => {
        if (to.hash) {
          return {selector: to.hash}
        } else {
          return { x: 0, y: 0 }
        }
      }
    });

    router.beforeEach((to, from, next) => {
      if (to.name) {
        ProgressBar.start();
      }

      next();
    });

    router.afterEach(() => {
      ProgressBar.done();
    });
  }

  return router;
};

const buildStores = () => {
  const output = {};

  Object.keys(modules)
    .filter((key) => !!modules[key].store)
    .forEach((key) => {
      output[key] = modules[key].store;
    });

  return output;
};

let store = null;

const storeAsync = () => {
  if (!store) {
    store = new Vuex.Store({ 
      modules: buildStores(),
      mutations: {
        // other mutations
        ...vuexfireMutations,
      }, 
    });
  }

  return store;
};

export {
  setupComponentsFiltersDirectivesAndMixins,
  routerAsync,
  storeAsync,
};

// end - boilerplate code
