// import Layout from '@/modules/layout/layout/dashboard/DashboardLayout';
import Layout from '@/modules/layout/components/layout';
import { i18n } from '@/i18n';
// ========================================================================================================== //
//                                             Sign In Routes                                                 //
// ========================================================================================================== //
const SigninPage = () =>
  import('@/modules/auth/components/signin-page.vue');
const SigninWithPhonePage = () =>
  import('@/modules/auth/components/signin-with-phone-page.vue');
const SigninWithEmailPage = () =>
  import('@/modules/auth/components/signin-with-email-page.vue'); 
const ForgotPasswordPage = () =>
  import('@/modules/auth/components/forgot-password-page.vue');

// ========================================================================================================== //
//                                             Sign Up Routes                                                 //
// ========================================================================================================== //
const SignupPage = () =>
  import('@/modules/auth/components/signup-page.vue');
const EmailUnverifiedPage = () =>
  import('@/modules/auth/components/email-unverified-page.vue');
const EmptyPermissionsPage = () =>
  import('@/modules/auth/components/empty-permissions-page.vue');
const PhoneUnverifiedPage = () =>
  import('@/modules/auth/components/phone-unverified-page.vue');  
const CompanyFormPage = () =>
  import('@/modules/auth/components/company-profile/company-form-page.vue');   
// ========================================================================================================== //
//                                             Outher Routes                                                  //
// ========================================================================================================== //  
const ProfileFormPage = () =>
  import('@/modules/auth/components/profile-form-page.vue');


// verification-contact-page.vue
// const setBranchLocation = () =>
//   import('@/modules/auth/components/setLocation-map-page.vue');
// const verificationContact = () =>
//   import('@/modules/auth/components/verification-contact-page.vue');
// const CompleteProfile = () =>
//   import('@/modules/auth/components/complete-profile-page.vue');
// const CompanyBranches = () =>
//   import('@/modules/auth/components/add-companyBranches-page.vue');

 
const emailPasswordPage = () =>
  import('@/modules/auth/components/create-emailPassword-page.vue');


// const Dashboard = () =>
//   import(/* webpackChunkName: "dashboard" */ '../layout/pages/Dashboard.vue');
// const Setting = () =>
//   import(/* webpackChunkName: "common" */ '../layout/pages/Setting.vue');

// const Dashboard = () =>
//   import('@/modules/home/components/Dashboard.vue');
// const Setting = () =>
//   import('@/modules/settings/components/Setting.vue');  

export default [
  {
    name: 'signin',
    path: '/:lang?/auth/signin',
    component: SigninPage,
    meta: { unauth: true, title: i18n('metaTitle.signIn') },
    beforeEnter: (to, from, next) => {
      document.title = to.meta.title;
      next();
    },
  },
  {
    name: 'signinWithPhone',
    path: '/:lang?/auth/signin/phone-number',
    props: true,
    component: SigninWithPhonePage,
    meta: { unauth: true, title: i18n('metaTitle.signIn') },
    beforeEnter: (to, from, next) => {
      document.title = to.meta.title;
      next();
    },
  },
  {
    name: 'signinWithEmail',
    path: '/:lang?/auth/signin/email-adress',
    props: true,
    component: SigninWithEmailPage,
    meta: { unauth: true, title: i18n('metaTitle.signIn') },
    beforeEnter: (to, from, next) => {
      document.title = to.meta.title;
      next();
    },
  },
  {
    name: 'signup',
    path: '/:lang?/auth/signup',
    component: SignupPage,
    meta: { unauth: true, title: i18n('metaTitle.signUp') },
    beforeEnter: (to, from, next) => {
      document.title = to.meta.title;
      next();
    },
  },
  {
    name: 'signup',
    path: '/:lang?/auth/email-password',
    component: emailPasswordPage,
    meta: { unauth: true, title: i18n('metaTitle.signUp') },
    beforeEnter: (to, from, next) => {
      document.title = to.meta.title;
      next();
    },
  },
  {
    name: 'forgotPassword',
    path: '/:lang?/auth/forgot-password',
    component: ForgotPasswordPage,
    meta: {
      unauth: true,
      title: i18n('metaTitle.forgetPass'),
    },
    beforeEnter: (to, from, next) => {
      document.title = to.meta.title;
      next();
    },
  },
  {
    name: 'emailUnverified',
    path: '/:lang?/auth/email-unverified',
    component: EmailUnverifiedPage,
    meta: {
      auth: true,
      emailAlreadyVerified: true,
      title: i18n('metaTitle.emailUnverified'),
    },
    beforeEnter: (to, from, next) => {
      document.title = to.meta.title;
      next();
    },
  },
  {
    name: 'phoneUnverified',
    path: '/:lang?/auth/phone-unverified',
    component: PhoneUnverifiedPage,
    meta: {
      unauth: true,
      title: i18n('metaTitle.emailUnverified'),
    },
    beforeEnter: (to, from, next) => {
      document.title = to.meta.title;
      next();
    },
  },
  {
    name: 'CompleteProfile',
    path: '/:lang?/auth/complete-profile',
    component: CompanyFormPage,
    meta: {
      auth: true,
      title: i18n('metaTitle.emailUnverified'),
    },
    beforeEnter: (to, from, next) => {
      document.title = to.meta.title;
      next();
    },
  },
  {
    name: 'emptyPermissions',
    path: '/:lang?/auth/empty-permissions',
    component: EmptyPermissionsPage,
    meta: {
      auth: true,
      notEmptyPermissions: true,
      title: i18n('metaTitle.emptyPermissions'),
    },
    beforeEnter: (to, from, next) => {
      document.title = to.meta.title;
      next();
    },
  },
  {
    name: '',
    path: '/:lang?',
    // redirect: Dashboard,
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'editProfile',
        path: '/auth/edit-profile',
        component: ProfileFormPage,
        meta: { auth: true , title: i18n('metaTitle.authEdit')},
        beforeEnter: (to, from, next) => {
          document.title = to.meta.title
          next()
        }
      },
    ],
  },
];
