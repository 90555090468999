//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import 'firebase/firestore';
import { mapGetters } from 'vuex';
import { EventBus } from '@/event-bus.js';
// const { setScrollPosition } = scroll;

// import UploadImages from "vue-upload-drop-images"
export default {
    name:"app-custom-image-upload",
    // props:['fileId', 'imageId', 'src', 'imageName', ''],
    props:['imgSrc', 'imgId', 'fileId'],
    data(){
        return{
            rawFile:null,
            fileName:'',
            path:'',
            downloadURL:'',
            file: null,
            fileExist:false,
            progress:null,
            fileSizeExceeded:false,
            fileSizeExceededIndicies: [],
            notFound: false


        }
    },
    components:{
        // UploadImages,
    },
    computed:{
        ...mapGetters({
            currentUser:'auth/currentUser'
        }),
        progressBar(){
            console.log(this.progress);//:buffer="progress"
            // this.progress = this.progress / 100
            return this.progress / 100
        }
    },
    watch:{
        fileSizeExceeded(newval){
            if(newval){
                this.showNotif('top-right')
            }
        }
    },
    created(){
        EventBus.$on('imageIsRequired', () =>{
            this.notFound = true
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        })
    },
    methods:{
        i18n(key, args) {
            return this.$t(key, args);
        }, 
        deleteImage(imageName){
            const profilePicture = document.getElementById(this.imgId)
            profilePicture.src = 'https://firebasestorage.googleapis.com/v0/b/drug-deal-6e05f.appspot.com/o/public%2Fupload-prescription.png?alt=media&token=b6292983-ca16-41be-a9d9-3458088d3581'
            const fileInput = document.getElementById(this.fileId)
            fileInput.value = "";
            this.$emit('deleteImage',imageName)
        },
        
   
    openImageUpload(){
        const fileUpload = document.getElementById(this.fileId)
        // const fileUpload = document.getElementById('file-upload')
        console.log('file upload',fileUpload)
        fileUpload.click()
    }, 
    async readUrl(event){
        console.log('input',event.target.files)
        const profilePicture = document.getElementById(this.imgId)
        // const profilePicture = document.getElementById('profile-pic')
        if (event.target.files && event.target.files[0]) {
            this.rawFile = event.target.files[0]
            this.fileName = event.target.files[0].name
            var reader = new FileReader();
            console.log('reader',reader)
            
            this.fileExist = true
            var totalBytes = this.rawFile.size
            var _size = Math.floor(totalBytes/1000000)
            if(_size > 10){
                console.log('cannot add file')
                this.fileExist = false
                this.fileSizeExceeded = true
                profilePicture.src = 'https://firebasestorage.googleapis.com/v0/b/drug-deal-6e05f.appspot.com/o/public%2Fupload-prescription.png?alt=media&token=b6292983-ca16-41be-a9d9-3458088d3581'
                console.log('profilePicture.src',profilePicture.src);
                // debugger
                const fileInput = document.getElementById(this.fileId)
                // const fileInput = document.getElementById('file-upload')
                fileInput.value = "";
            }
            else{
                reader.onload = function (e) {                
                    this.file = e.target.result
                    profilePicture.src =  e.target.result;
                }
                reader.readAsDataURL(event.target.files[0]);
                this.fileExist = true
                this.fileSizeExceeded = false
                this.notFound = false
                this.$emit('uploadImage',this.rawFile)
            }
            
        }
    },
    showNotif (position) {
        

        this.$q.notify({
          color:"negative",
        //   textColor,
          icon:"report_problem",
          message:"File is too large",
          position,
        })
      }
  
    
},

    
}
