import notificationListStore from '@/modules/notification/notification-list-store';
import notificationViewStore from '@/modules/notification/notification-view-store';
import notificationImporterStore from '@/modules/notification/notification-importer-store';
import notificationFormStore from '@/modules/notification/notification-form-store';
import notificationDestroyStore from '@/modules/notification/notification-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: notificationDestroyStore,
    form: notificationFormStore,
    list: notificationListStore,
    view: notificationViewStore,
    importer: notificationImporterStore,
  },
};
