// import planListStore from '@/modules/plan/plan-list-store';
import tourGuideViewStore from '@/modules/tour-guide/tour-guide-view-store';
import tourGuideFormStore from '@/modules/tour-guide/tour-guide-form-store';
import tourGuideListStore from '@/modules/tour-guide/tour-guide-list-store';
// import planDestroyStore from '@/modules/plan/plan-destroy-store';

export default {
  namespaced: true,

  modules: {
    // destroy: planDestroyStore,
    form: tourGuideFormStore,
    list: tourGuideListStore,
    // list: planListStore,
    view: tourGuideViewStore,
  },
};
