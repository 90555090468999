import * as firebase from 'firebase/app';
import 'firebase/auth';

import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-auth';
import companyGraphqlClient from '@/shared/graphql/client-company';

import firebaseInit from '@/shared/firebase/firebase-init';

export default class AuthService {
  static init() {
    return firebaseInit();
  }

  static onAuthStateChanged(
    callbackSuccess,
    callbackError,
  ) {
    return firebase
      .auth()
      .onAuthStateChanged(callbackSuccess, callbackError);
   }

  static async sendEmailVerification(/*authenticationUser*/) {
    console.log(this.isEmailConfigured());
    debugger
    if (await this.isEmailConfigured()) {
      debugger
      return this.sendEmailVerificationFromBackend();
    }
    return this.sendEmailVerificationFromBackend();
    // debugger
    // return this.sendEmailVerificationFromClient(
    //   authenticationUser,
    // );
  }

  static async sendEmailVerificationFromBackend() {
    debugger
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation AUTH_SEND_EMAIL_ADDRESS_VERIFICATION_EMAIL {
          authSendEmailAddressVerificationEmail
        }
      `,
    });

    return response.data
      .authSendEmailAddressVerificationEmail;
  }

  static async sendEmailVerificationFromClient(authenticationUser) {
    debugger
    return authenticationUser.sendEmailVerification();
  }

  static async sendPasswordResetEmail(email) {
    // if (await this.isEmailConfigured()) {
    //   return this.sendPasswordResetEmailFromBackend(email);
    // }

    return this.sendPasswordResetEmailFromClient(email);
  }

  static async sendPasswordResetEmailFromBackend(email) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation AUTH_SEND_PASSWORD_RESET_EMAIL(
          $email: String!
        ) {
          authSendPasswordResetEmail(email: $email)
        }
      `,
      variables: {
        email,
      },
    });

    return response.data.authSendPasswordResetEmail;
  }

  static async sendPasswordResetEmailFromClient(email) {
    return firebase.auth().sendPasswordResetEmail(email);
  }

  static async registerWithEmailAndPassword(
    email,
    password,
  ) {
    const credentials = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password);
    this.sendEmailVerification(credentials.user);
    return credentials.user;
  }

  static async signinWithSocial(
    provider,
    rememberMe = false,
  ) {
    
    const persistence = rememberMe
      ? firebase.auth.Auth.Persistence.LOCAL
      : firebase.auth.Auth.Persistence.SESSION;

    await firebase.auth().setPersistence(persistence);

    const providers = {
      google: firebase.auth.GoogleAuthProvider,
      facebook: firebase.auth.FacebookAuthProvider,
      twitter: firebase.auth.TwitterAuthProvider,
    };

    return firebase
      .auth()
      .signInWithPopup(new providers[provider]());


  }

  static async signinWithEmailAndPassword(
    email,
    password,
    rememberMe = false,
  ) {
    const persistence = rememberMe
      ? firebase.auth.Auth.Persistence.LOCAL
      : firebase.auth.Auth.Persistence.SESSION;

    await firebase.auth().setPersistence(persistence);

    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password);
  }

  static async fetchMe() {
    console.log('in fetch me')
    // workingEmail
    // alternativePhoneNumber
    const response = await graphqlClient.query({
      query: gql`
        {
          authMe {
            id
            authenticationUid
            fullName
            firstName
            lastName
            phoneNumber
            countryCode
            accountType
            companyId
            lang
            email
            roles
            avatars {
              id
              name
              publicUrl
            }
            workingEmail
            alternativePhoneNumber            
          }
        }
      `,
    });
    console.log('fetch me',response.data.authMe)
    return response.data.authMe;
  }



  static async isEmailConfigured() {
    const response = await graphqlClient.query({
      query: gql`
        {
          authIsEmailConfigured
        }
      `,
    });

    return response.data.authIsEmailConfigured;
  }



  static async reauthenticateWithStorageToken() {
    try {
      let token = localStorage.getItem('token');
      if (!token) {
        const response = await graphqlClient.query({
          query: gql`
            {
              authStorageToken
            }
          `,
        });
        token = response.data.authStorageToken;
        localStorage.setItem(
          'token',
          this.encryptString(token, 'secret token'),
        );
      } else {
        token = this.decryptString(token, 'secret token');
      }
      return firebase.auth().signInWithCustomToken(token);
    } catch (error) {
      console.error(error);
    }
  }

  static signout() {
    const lang = localStorage.getItem('language');
    localStorage.clear();
    localStorage.setItem('language', lang);
    return firebase.auth().signOut();
  }

  static async deleteCurrentUserAccount() {
    await firebase.auth().currentUser.delete().then(() => {
      console.log('User deleted.')
    }).catch((error) => {
      console.log('An error happened : ', error)
      throw error
    });
  }

  static async updateProfile(
    // firstName,
    // lastName,
    fullName,
    phoneNumber,
    countryCode,
    lang,
    avatars,
    accountType,
    workingEmail,
    alternativePhoneNumber,
    companyId,
    email
  ) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation AUTH_UPDATE_PROFILE(
          $profile: UserProfileInput!
        ) {
          authUpdateProfile(profile: $profile)
        }
      `,

      variables: {
        profile: {
          // firstName,
          // lastName,
          fullName,
          phoneNumber,
          countryCode,
          lang,
          avatars,
          accountType,
          workingEmail,
          alternativePhoneNumber,
          companyId,
          email          
        },
      },
    });

    return response.data.authUpdateProfile;
  }
  static async authCreateUserAndCompany(
    fullName,
    phoneNumber,
    countryCode,
    lang,
    avatars,
    accountType,
    workingEmail,
    alternativePhoneNumber,
    companyId,
    email
    ){
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation AUTH_UPDATE_PROFILE(
          $profile: UserProfileInput!
        ) {
          authCreateUserAndCompany(profile: $profile)
        }
      `,

      variables: {
        profile: {
          // firstName,
          // lastName,
          fullName,
          phoneNumber,
          countryCode,
          lang,
          avatars,
          accountType,
          workingEmail,
          alternativePhoneNumber,
          companyId,
          email          
        },
      },
    });

    return response.data.authCreateUserAndCompany;

  }


  static decryptString(ciphertext, key) {
    const CryptoJS = require('crypto-js');
    // Decrypt
    var bytes = CryptoJS.AES.decrypt(
      ciphertext.toString(),
      key,
    );
    var plaintext = bytes.toString(CryptoJS.enc.Utf8);
    return plaintext;
  }
 
 static encryptString(message, key) {
    const CryptoJS = require('crypto-js');
    // Encrypt
    var ciphertext = CryptoJS.AES.encrypt(message, key);
    return ciphertext;
  }

  static encryption(data, key) {
    const CryptoJS = require('crypto-js');
    // Encrypt
    var ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      key,
    );
    return ciphertext;
  }
  // ================================================================= //
  //                      Object DECRYPTION OTHER WAY                  //
  // ================================================================= //
  static decryption(cipherData, key) {
    var CryptoJS = require('crypto-js');
    // Decrypt
    var bytes = CryptoJS.AES.decrypt(
      cipherData.toString(),
      key,
    );
    var decryptedData = JSON.parse(
      bytes.toString(CryptoJS.enc.Utf8),
    );
    return decryptedData;
  }

  // ================================================================= //
  //                      Create Company in complete profile           //
  // ================================================================= //
  static async createCompany(data) {
    const response = await companyGraphqlClient.mutate({
      mutation: gql`
        mutation COMPANY_CREATE($data: CompanyInput!) {
          companyCreate(data: $data) {
            id
          }
        }
      `,
      variables: {
        data,
      },
    });

    return response.data.companyCreate;
  }

  static async updateCompany(id, data) {
    const response = await companyGraphqlClient.mutate({
      mutation: gql`
        mutation COMPANY_UPDATE($id: String!, $data: CompanyInput!) {
          companyUpdate(id: $id, data: $data) {
            id
          }
        }
      `,
      variables: {
        id,
        data
      },
    });

    return response.data.companyUpdate;
  }
}
