import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';
import ImagesField from '@/shared/fields/images-field';

function label(name) {
  return i18n(`entities.notification.fields.${name}`);
}

const fields = {
  id: new IdField('id', label('id')),
  title: new StringField('title', label('title'),{required:true}),
  message: new StringField('message', label('message'), {required:true}),
  image: new ImagesField('image', label('image'), 'notification/image',{}),
  name: new StringField('name', label('name'), {}),
  placeId: new StringField('placeId', label('placeId'), {}),
  createdAt: new DateTimeField(
    'createdAt',
    label('createdAt'),
  ),
  updatedAt: new DateTimeField(
    'updatedAt',
    label('updatedAt'),
  ),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),

};

export class NotificationModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
