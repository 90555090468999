//
//
//
//
//
//
//
//
//
//
//
//

import { i18n } from '@/i18n';
import { mapGetters } from 'vuex';


export default {
  name: 'app-add-new-card',
  props: ['title'],

  data() {
    return {

    };
  },
  computed: {
    ...mapGetters({
      settings:'settings/settings',
    }),
  },

  methods: {
    i18n(text) {
      return i18n(text);
    },
    async doSubmit() {
      await this.$emit('submit')
    },
  },
};
