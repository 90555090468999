const en = {
  common: {
    or: 'or',
    cancel: 'Cancel',
    reset: 'Cancel',
    save: 'Save',
    search: 'Search',
    edit: 'Edit',
    remove: 'Remove',
    new: 'New',
    export: 'Export to Excel',
    noDataToExport: 'No data to export',
    import: 'Import',
    discard: 'Discard',
    yes: 'Yes',
    no: 'No',
    pause: 'Pause',
    areYouSure: 'Are you sure to delete?',
    view: 'View',
    destroy: 'Delete',
    mustSelectARow: 'Must select a row',
    confirm: 'Confirm',
    submit: 'Submit',
    verify: 'Verify',
    phoneNumber: 'Phone Number',
    emailAddress: 'Email Address',
    create: 'Create',
    next: 'Next',
    add: 'Add',
    searchByRegion: 'Search by region name',
    FilterByRegion: 'Filter by region name',
    noResults: 'No results',
    back: 'Back',
    daysNumber: 'Days number',
    viewMore: 'View more',
    delete: 'Delete',
    setTime: 'Set Time',
    selectImage: 'Select Image',
    createdBy: 'Created By',
    updatedBy: 'Updated By',
    createdAt: 'Created At',
    updatedAt: 'Updated At',
    createdAtRange: 'Created At',
    all: 'All',
    noImgInPlace: 'There are no photos for this place',
    accept: 'Accept',
    ignore: 'Ignore',
    reject: 'Reject',
    done: 'Done',
  },
  app: {
    title: 'Rahal Company',
    ConfirmEmailAddress: 'Confirm Email Address',
  },
  sideMenu: {
    dashboard: 'Dashboard',
    database: 'Database',
    users: 'Users',
    companyProfile: 'Company profile',
    TourGuides: 'Tour Guides',
    admins: 'Admins',
    addLocationZone: 'Add location zone',
    RegisterPlaces: 'Register places',
    Setting: 'Setting',
    changeLanguage: 'Change Language',
    changeTheme: 'Change theme color',
    trips: 'Trips',
    plans: 'Plans',
    branches: 'Branches',
    activeTrips: 'Active Trips',
    reservations: 'Reservations',
    transactions: 'Transactions',
    cycle: 'Cycle',
    wallet: 'Wallet',
    reviews: 'Reviews',
  },
  empty: {
    reviews: 'Reviews is empty',
    trip: 'Trips is empty',
    activeTrip: 'Active trips is empty',
    branch: 'Branches is empty',
    guide: 'Tour Guides is empty',
    admin: 'Admins is empty',
    reservation: 'Reservations is empty',
    transaction: 'Transactions is empty',
    notification: 'Notifications is empty',
    cycle: 'Cycle is empty',
    wallet: 'Wallet is empty',
  },
  companyProfile: {
    // branches: {
    //   title: 'My Branches',
    //   addNewBranch: 'Add New Branch',
    //   branchName: 'Branch Name',
    //   onlineTour: 'Online tour guides',
    //   offlineTour: 'Offline tour guides',
    //   allTourGuidesInTheBranch:'all tour guides in the branch',
    //   onlineTourGuidesInTheBranch:'online tour guides in the branch',
    //   offlineTourGuidesInTheBranch:'offline tour guides in the branch',
    //   CompanyProgrames: 'Company Programes',
    //   createNewProgram: 'Create new programe',
    //   ViewPrograme: 'View programe details',
    //   editProgram: 'Edit programe',
    //   ProgrameDetials: 'Programe detials',
    //   ReorderPlaces: 'Re-order this places',
    //   AddNewPlaces: 'Add New Places',
    //   SaveChanges: 'Save changes',
    //   areSureDelete: 'Are you sure do you want to remove this place from your programe?',
    //   yesDelete: 'Yes, delete',
    //   noCancel: 'No, Cancel',
    //   city: 'City',
    //   PleaseSelectcity: 'Please Select city',
    //   date: 'Date',
    //   PleaseSelectDate: 'Please Select Date',
    //   DorepeatTrip: 'Do yu want to repeat this trip',
    //   repeatTrip: 'Repeat this trip ',
    //   Next: 'Next',
    //   Save: 'Save',
    //   GREAT: 'GREAT!',
    //   duration: 'Duration',
    //   TripDuration: 'Trip Duration:',
    //   DateAndTime: 'Date and time:',
    //   Successfullycreated: 'Successfully created your new programe',
    //   pleaseEnterTheFollowingInfo:'Please enter the following information below to create your new branch',
    //   branchNumber:'Branch Number'
    // }
  },
  metaTitle: {
    mainTitle: 'Rahal Company | Home',
    signIn: 'Rahal | Sign In',
    signUp: 'Rahal | Sign Up',
    forgetPass: 'Rahal | Forget Password',
    emailUnverified: 'Rahal | Email Unverified',
    emptyPermissions: 'Rahal | Empty Permissions',
    authDahsboard: 'Rahal | Dashboard',
    authEdit: 'Rahal | EditProfile',
    authSetting: 'Rahal | Setting',
    admins: 'Rahal | Database | Admins',
    newAdmins: 'Rahal | Database | Admins | New Admins',
    editAdmin: 'Rahal | Database | Admins | Edit',
    viewAdmin: 'Rahal | Database | Admins | View',
    users: 'Rahal | Database | Users',
    viewUser: 'Rahal | Database | Users | View',
    place: 'Rahal | Place',
    newPlace: 'Rahal | Place | Add New Place',
    editPlace: 'Rahal | Place | Edit',
    viewPlace: 'Rahal | Place | View',
    newNotification: 'Rahal | Place | Notification',

    plan: 'Rahal | Plan',
    newPlan: 'Rahal | Plan | Create New Plan',
    trip: 'Rahal | Trip',
    newTrip: 'Rahal | Trip | Create New Trip',
    branch: 'Rahal | Branch',
    viewBranch: 'Rahal | Branch | View Branch',
    reviews: 'Rahal | Reviews',

    transaction: 'Rahal | Transaction',
    wallet: 'Rahal | Wallet',
  },

  entities: {
    dashboard: {
      projection: 'Projection',
      visitors: 'Tour guides',
      AdminsChart: 'Admins',
      usersTable: 'Users Table',
      adminsTable: 'Admins table',
      addNewAdmin: 'Add New Admin',
      tourGuideTable: 'Tour guides table',
      addNewtourGuide: 'Add New tour guide',
      registerPlaces: 'Register places',
      chooseLanguage: 'Choose Language',
      profile: 'Profile',
      Fname: 'First name',
      Lname: 'Last name',
      phoneNumber: 'Phone number',
      email: 'Email Address',
    },
    splash: {
      welcome: 'Welcome to Rahal ADMIN PANEL',
      getStarted: 'Let’s get started',
      LOGIN: 'Sign in',
      verifyPhone: 'VERIFY PHONE NUMBER',
      enterPin: 'Please enter the pin you have recieved on',
      signIn:
        'Please login to your account by phone number or email address',
      phoneSign:
        'Please enter your phone number to sign in your account',
      emailSign:
        'Please enter your email address to sign in your account',
      welcBack: 'Welcome Back! ',
      loginAccount: 'Login your account',
      pleaseValid:
        'Please enter your Email address and password to login',
      orLoginWith: 'Or login with',
      dontHaveAccount: 'Don’t have account ? ',
    },
    signup: {
      linkeEmail: 'Email Address',
      createNew: 'Create New Account',
      pleaseEnter: 'What’s your email address?',
      signUpUp: 'Sign up',
      enterphoneUp:
        'Please enter your phone number to sign up your account',
      loginAccount: 'LOGIN YOUR ACCOUNT',
      signUp: 'Sign up',
      alreadyMember: 'Already a member ? ',
      signIn: ' SignIn',
      join: 'Join',
      password: 'Password',
      passwordHint: 'Please enter your password',
    },

    place: {
      name: 'place',
      label: 'Places',
      menu: 'Places',
      placeholder: 'enter your location',
      exporterFileName: 'place_export',
      ADD: 'ADD',
      Next: 'NEXT',
      list: {
        menu: 'Places',
        title: 'Places',
      },
      create: {
        success: 'Place saved successfully',
      },
      update: {
        success: 'Place saved successfully',
      },
      destroy: {
        success: 'Place deleted successfully',
      },
      destroyAll: {
        success: 'Place(s) deleted successfully',
      },
      edit: {
        title: 'Edit Place',
      },
      fields: {
        id: 'Id',
        lat: 'Latitude',
        lng: 'Longitude',
        radiusRange: 'Radius',
        radius: 'Radius',
        color: 'Color',
        strokeColor: 'Stroke Color',
        strokeWidthRange: 'StrokeWidth',
        strokeWidth: 'Stroke width',
        fillColor: 'Fill Color',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
        placeName: 'Place Name',
        city: 'city',
        country: 'country',
      },
      enumerators: {},
      new: {
        title: 'Add Location zone',
      },
      view: {
        title: 'View Place',
      },
      importer: {
        title: 'Import Places',
        fileName: 'place_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    notification: {
      name: 'notification',
      label: 'Notifications',
      menu: 'Notifications',
      exporterFileName: 'notification_export',
      list: {
        menu: 'Notifications',
        title: 'Notifications',
      },
      create: {
        success: 'Notification saved successfully',
      },
      update: {
        success: 'Notification saved successfully',
      },
      destroy: {
        success: 'Notification deleted successfully',
      },
      destroyAll: {
        success: 'Notification(s) deleted successfully',
      },
      edit: {
        title: 'Edit Notification',
      },
      fields: {
        id: 'Id',
        title: 'Notification Title',
        message: 'Notification Text',
        image: 'Notification Image ( optional )',
        name: 'Notification Name ( optional )',
        placeId: 'PlaceId',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: ' Notifications',
      },
      view: {
        title: 'View Notification',
      },
      importer: {
        title: 'Import Notifications',
        fileName: 'notification_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    plan: {
      name: 'Plan',
      label: 'plans',
      menu: 'plans',
      exporterFileName: 'plan_export',
      list: {
        title: 'plans',
        menu: 'plans',
        hint: 'Rahal offers plans A and B you can choose them or you can  customize your own plan. ',
        saryahPlans: 'Rahal Plans',
        myPlans: 'My Plans',
      },
      card: {
        duration: 'Duration',
        days: 'Days',
        day: 'Day',
      },
      days: {
        dayOne: 'Day One',
        dayTwo: 'Day Two',
        dayThree: 'Day Three',
        dayFour: 'Day Four',
        dayFive: 'Day Five',
        daySix: 'Day Six',
        daySeven: 'Day Seven',
      },
      tripDays: {
        oneDay: 'One Day',
        twoDays: 'Two Days',
        ThreeDays: 'Three Days',
        FourDays: 'Four Days',
        FiveDays: 'Five Days',
        SixDays: 'Six Days',
        SevenDays: 'Seven Days',
        moreThanDay: 'More than day',
      },
      form: {
        enterInformation:
          'Enter the following information to create your plan',
        durationTitle: 'please select the trip duration',
        pleaseDragThePlaces:
          'Please drag the places that you want to add in your plan',
        placesInThisCity: 'Places in this region',
        yourPlan: 'Your plan',
        dragHere: 'Drag places here',
        pleaseSelectPhotoToEachPlace:
          'Please select photos and time to each place',
        selectPhotoToThisPlace:
          'Select photo to this place',
        editPlan: 'Edit this plan',
        deletePlan: 'Delete this plan',
        reorderPlan: 'Reorder places',
        disablePlan: 'Disable this plan',
        enablePlan: 'Enable this plan',
        placesInThisPlan: 'Places in this plan',
        reordersPlaces: 'Reorders places in this plan',
        setTimeToThisPlace: 'Set time to this place',
        pleaseSetTimeToThisPlace:
          'Please set time by mintues to this place',
      },
      disable: {
        success: 'plan disabled successfully',
      },
      enable: {
        success: 'plan enabled successfully',
      },
      create: {
        success: 'plan saved successfully',
      },
      update: {
        success: 'plan saved successfully',
      },
      destroy: {
        success: 'plan deleted successfully',
      },
      destroyAll: {
        success: 'plan(s) deleted successfully',
      },
      new: {
        title: 'Create new plan',
        shortTitle: 'Create Plan',
      },
      edit: {
        title: 'Edit this plan',
      },
      fields: {
        id: 'Id',
        name: 'Plan Name',
        description: 'Description',
        startCityId: 'Start City',
        startCity: 'Start City',
        region: 'City',
        city: 'City',
        type: 'Plan Type',
        programs: 'Programs',
        numberOfDays: 'Days number',

        numberOfPlans: 'Number of plans',
        dateOfCreation: 'Date of creation',

        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        planType: {
          default: 'Default Plan',
          custom: 'Custom Plan',
        },
      },
      placeholder: {
        chooseRegions: 'Choose Regions',
        chooseRegion: 'Choose Region',
        chooseCity: 'Choose City',
        planNameEn: 'Enter plan name in English',
        planNameAr: 'Enter plan name in Arabic',
        descriptionEn: 'Enter description in English',
        descriptionAr: 'Enter description in Arabic',
        chooseStartCity: 'Choose start city',
        chooseCities: 'Choose cities',
      },
      view: {
        title: 'View Plan',
        title2:
          'You can edit, delete and reorder your plan',
        moreThan: 'More than +{0} places in this plan',
        visitorStayMins: 'Visitors typically stay {0} mins',
        visitorStayHour: 'Visitors typically stay {0} hour',
        visitorStayHourMins:
          'Visitors typically stay {0} hour and {1} mins',
      },
    },

    trip: {
      name: 'Trips',
      label: 'Trips',
      menu: 'Trips',
      exporterFileName: 'Trip_export',
      list: {
        title: 'Trips',
        menu: 'Trips',
        hint: 'To create your own trips please select Trip and capacity and trips date',
      },
      card: {
        duration: 'Duration',
        days: 'Days',
        day: 'Day',
      },
      form: {
        enterInformation:
          'Enter the following information to create your Trip',
        durationTitle: 'please select the trip duration',
        pleaseDragThePlaces:
          'Please drag the places that you want to add in your Trip',
        placesInThisCity: 'Places in this region',
        yourPlan: 'Your Trip',
        dragHere: 'Drag places here',
        pleaseSelectPhotoToEachPlace:
          'Please select photos and time to each place',
        selectPhotoToThisPlace:
          'Select photo to this place',
        editPlan: 'Edit this Trip',
        deletePlan: 'Delete this Trip',
        reorderPlan: 'Reorder places',
        disablePlan: 'Disable this Trip',
        enablePlan: 'Enable this Trip',
        placesInThisPlan: 'Places in this Trip',
        reordersPlaces: 'Reorders places in this Trip',
        setTimeToThisPlace: 'Set time to this place',
        pleaseSetTimeToThisPlace:
          'Please set time by mintues to this place',
      },
      disable: {
        success: 'Trip disabled successfully',
      },
      enable: {
        success: 'Trip enabled successfully',
      },
      create: {
        success: 'Trip saved successfully',
      },
      update: {
        success: 'Trip saved successfully',
      },
      destroy: {
        success: 'Trip deleted successfully',
      },
      destroyAll: {
        success: 'Trip(s) deleted successfully',
      },
      new: {
        title: 'Create new Trip',
        shortTitle: 'Create Trip',
      },
      edit: {
        title: 'Edit this Trip',
      },
      fields: {
        id: 'Id',
        name: 'Trip Name',

        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        TripType: {
          default: 'Default Trip',
          custom: 'Custom Trip',
        },
      },
      placeholder: {},
      view: {
        title: 'View Trip',
        title2:
          'You can edit, delete and reorder your Trip',
        moreThan: 'More than +{0} places in this Trip',
        visitorStayMins: 'Visitors typically stay {0} mins',
        visitorStayHour: 'Visitors typically stay {0} hour',
        visitorStayHourMins:
          'Visitors typically stay {0} hour and {1} mins',
      },
    },

    transactions: {
      name: 'Transactions',
      label: 'Transactions',
      menu: 'Transactions',
      list: {
        title: 'Transactions',
        menu: 'Transactions',
        hint: 'You will find here all trips transactions',
      },
    },

    branch: {
      name: 'Branches',
      label: 'Branches',
      menu: 'Branches',
      exporterFileName: 'Branch_export',
      list: {
        title: 'Branches',
        menu: 'Branches',
        hint: 'To add new branch please choose city',
      },
      card: {
        duration: 'Duration',
        days: 'Days',
        day: 'Day',
      },
      form: {},
      create: {
        success: 'Branch saved successfully',
      },
      update: {
        success: 'Branch saved successfully',
      },
      destroy: {
        success: 'Branch deleted successfully',
      },
      destroyAll: {
        success: 'Branch(s) deleted successfully',
      },
      new: {
        title: 'Create new branch',
        create: 'Create new branch',
      },
      edit: {
        title: 'Edit this Branch',
      },
      view: {
        title: 'View branch',
        trips: 'Trips in this branch',
        allGuides: 'All tourguides',
        onlineGuides: 'Online tour guides',
        offlineGuides: 'Offline tour Guides',
      },
      fields: {
        id: 'Id',
        name: 'Plan Name',
        description: 'Description',
        startCityId: 'Start City',
        startCity: 'Start City',
        region: 'City',
        city: 'City',
        type: 'Plan Type',
        programs: 'Programs',
        numberOfDays: 'Days number',

        numberOfPlans: 'Number of Branches',
        dateOfCreation: 'Date of creation',

        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        BranchType: {
          default: 'Default Plan',
          custom: 'Custom Plan',
        },
      },
      placeholder: {
        chooseRegions: 'Choose Regions',
        chooseRegion: 'Choose Region',
        chooseCity: 'Choose City',
        BranchNameEn: 'Enter Branch name in English',
        BranchNameAr: 'Enter Branch name in Arabic',
        descriptionEn: 'Enter description in English',
        descriptionAr: 'Enter description in Arabic',
        chooseStartCity: 'Choose start city',
        chooseCities: 'Choose cities',
      },
    },

    reviews: {
      name: 'Review',
      label: 'Reviews',
      menu: 'Reviews',
      list: {
        title: 'Reviews',
        menu: 'Reviews',
        hint: 'You will find here all clients reviews',
        totalRating: 'Total rating',
      },
      fields: {
        id: 'Id',
        rate: 'Rate',
        description: 'Description',
        userAvatar: 'Client Avatar',
        userName: 'Client Name',

        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      placeholder: {},
      view: {
        title: 'View Plan',
        title2:
          'You can edit, delete and reorder your plan',
        moreThan: 'More than +{0} places in this plan',
        visitorStayMins: 'Visitors typically stay {0} mins',
        visitorStayHour: 'Visitors typically stay {0} hour',
        visitorStayHourMins:
          'Visitors typically stay {0} hour and {1} mins',
      },
    },
  },

  auth: {
    profile: {
      title: 'Edit Profile',
      success: 'Profile updated successfully',
    },
    fields: {
      confirmPassword: 'Confirm your password',
    },

    viewTour: {
      Completed: 'Completed',
      NotCompleted: 'Not Completed',
      Total: 'Total',
      Accepted: 'Accepted',
      Missed: 'Missed',
    },

    completeProfile: {
      title: 'Complete your profile',
      subTitle: 'Please complete your profile',
      CompanyName: 'Company Name',
      Languages: 'Languages',
      Description: 'Description',
      Authority: 'Authority to sign legal agreements',
      confirmAuthority:
        'Confirm that you have the authority to sign legal agreements on behalf of your company',
      firstchoose:
        'i AM THE OWNER AND HAVE THE AUTHORITY TO BIND MY COMPANY TO LEGAL AGREEMENTS',
      secondchoose:
        'My company has given me the authority to bind it to legal agreements',
      Country: 'Country',
      City: 'City',
      HeadquatersPhone: 'Headquaters Phone',
      WebsiteLink: 'Website Link',
      Yourworkemailaddress: 'Your work email address',
      FacebookLink: 'Facebook Link',
      TwitterLink: 'Twitter Link',
      InstagramLink: 'Instagram Link',
      NEXT: 'NEXT',
      chooseCountry: 'Choose Country',
      chooseCity: 'Choose City',
      chooseLanguages: 'Choose Languages',
      companyNamePlaceholder: 'Enter your company name',
      descriptionPlaceholder: 'Tell us about your company',
      FirstName: 'Fist name',
      LastName: 'Last name',
      pleaseUploadYourCommercialRegister:
        'Please upload your commercial register to continue',
    },
    companyBranch: {
      title: 'Complete your profile',
      subTitle: 'Please add your company branches',
      branchMap: 'Set branch location on map',
      Country: 'Country',
      verificationContact:
        'Please Tell us more about your Verification Contact',
      City: 'City',
      Accept: 'Accept',
      Decline: 'Decline',
      BranchNumber: 'Branch Number',
      BranchSize: 'Branch Size',
      BranchFile: 'Import branch files',
      saveAddNew: 'Save and add new branch',
      NEXT: 'NEXT',
    },
    createAnAccount: 'Create an account',
    rememberMe: 'Remember me',
    forgotPassword: {
      label: 'Forgot password?',
      title: 'Forget your password?',
      message: `Enter your email address you are using for your account,
                and we will send you a password reset link`,
      emailAddress: 'Enter your email address here',
    },
    signin: 'LOGIN',
    signinPhone: 'Enter your phone number',
    signinEmailPlaceholder:
      'Please enter your email address',
    passwordPlaceholder: 'Enter your password',
    signup: 'Sign up',
    signout: 'Sign out',
    alreadyHaveAnAccount:
      'Already have an account? Sign in.',
    signinWithAnotherAccount:
      'Sign in with another account',
    emailUnverified: {
      message: `Please confirm your email at <strong>{0}</strong> to continue.`,
      submit: `Resend email`,
      verifyAccount: 'Verify your account',
    },
    emptyPermissions: {
      message: `You have no permissions yet. Wait for the admin to grant you privileges.`,
      message2:
        'please wait confirmation from Rahal admin',
    },

    AccountPermission: 'Pending request',
    forgetPassword: 'Forget your password?',
    forgetText:
      'Enter your email address you are using for your account, and we will send you a password reset link',
    cancel: 'cancel',

    passwordResetEmail: {
      message: 'Request Reset link',
      error: `Email not recognized`,
    },
    passwordReset: {
      message: 'Reset password',
    },
    emailAddressVerificationEmail: {
      error: `Email not recognized`,
    },
    verificationEmailSuccess: `Verification email sent successfully`,
    passwordResetEmailSuccess: `Password reset email sent successfully`,
    passwordResetSuccess: `Password changed successfully`,
    verifyEmail: {
      success: 'Email successfully verified',
      message:
        'Just a moment, your email is being verified...',
    },
  },
  roles: {
    owner: {
      label: 'Owner',
      description: 'Full access to all resources',
    },
    editor: {
      label: 'Editor',
      description: 'Edit access to all resources',
    },
    viewer: {
      label: 'Viewer',
      description: 'View access to all resources',
    },
    auditLogViewer: {
      label: 'Audit Log Viewer',
      description: 'Access to view audit logs',
    },
    iamSecurityReviewer: {
      label: 'Security Reviewer',
      description: `Full access to manage users roles`,
    },
    entityEditor: {
      label: 'Entity Editor',
      description: 'Edit access to all entities',
    },
    entityViewer: {
      label: 'Entity Viewer',
      description: 'View access to all entities',
    },
    placeEditor: {
      label: 'Place Editor',
      description: 'Edit access to Places',
    },
    placeViewer: {
      label: 'Place Viewer',
      description: 'View access to Places',
    },
    notificationEditor: {
      label: 'Notification Editor',
      description: 'Edit access to Notifications',
    },
    notificationViewer: {
      label: 'Notification Viewer',
      description: 'View access to Notifications',
    },
  },
  iam: {
    title: 'Identity and Access Management',
    menu: 'IAM',
    disable: 'Disable',
    disabled: 'Disabled',
    enabled: 'Enabled',
    enable: 'Enable',
    doEnableSuccess: 'User enabled successfully',
    doDisableSuccess: 'User disabled successfully',
    doDisableAllSuccess: 'User(s) disabled successfully',
    doEnableAllSuccess: 'User(s) enabled successfully',
    doAddSuccess: 'User(s) saved successfully',
    doUpdateSuccess: 'User saved successfully',
    viewBy: 'View By',
    users: {
      name: 'users',
      label: 'Users',
      exporterFileName: 'users_export',
      doRemoveAllSelectedSuccess:
        'Permissions removed successfully',
    },
    roles: {
      label: 'Roles',
      doRemoveAllSelectedSuccess:
        'Permissions removed successfully',
    },
    edit: {
      title: 'Edit User',
      titleGuide: 'Edit Tour Guide',
    },
    new: {
      title: 'New Admin ',
      titleGuide: 'New Tour Guide',
      titleUser: 'New User',

      emailsHint:
        'Separate multiple email addresses using the comma character.',
    },
    view: {
      title: 'View User',
      titleGuide: 'View Tour Guide',
      activity: 'Activity',
    },
    importer: {
      title: 'Import Users',
      fileName: 'users_import_template',
      hint: 'Files/Images columns must be the URLs of the files separated by space. Relationships must be the ID of the referenced records separated by space. Roles must be the role ids separated by space.',
    },
    errors: {
      userAlreadyExists:
        'User with this email already exists',
      userNotFound: 'User not found',
      disablingHimself: `You can't disable yourself`,
      revokingOwnPermission: `You can't revoke your own owner permission`,
    },
  },
  user: {
    fields: {
      id: 'Id',
      authenticationUid: 'Authentication Uid',
      avatars: 'Avatar',
      email: 'Email',
      emails: 'Email(s)',
      fullName: 'Name',
      firstName: 'First Name',
      lastName: 'Last Name',
      status: 'Status',
      disabled: 'Disabled',
      phoneNumber: 'Phone Number',
      countryCode: 'countryCode',
      lang: 'lang',
      role: 'Role',
      createdAt: 'Created at',
      updatedAt: 'Updated at',
      roleUser: 'Role/User',
      roles: 'Roles',
      accountType: 'User Type',
      createdAtRange: 'Created at',
      password: 'Password',
      rememberMe: 'Remember me',
    },
    enabled: 'Enabled',
    disabled: 'Disabled',
    validations: {
      // eslint-disable-next-line
      email: 'Email ${value} is invalid',
    },
  },
  auditLog: {
    menu: 'Audit Logs',
    title: 'Audit Logs',
    exporterFileName: 'audit_log_export',
    entityNamesHint:
      'Separate multiple entities using the comma character.',
    fields: {
      id: 'Id',
      timestampRange: 'Period',
      entityName: 'Entity',
      entityNames: 'Entities',
      entityId: 'Entity ID',
      action: 'Action',
      values: 'Values',
      timestamp: 'Date',
      createdByEmail: 'User Email',
    },
  },
  settings: {
    title: 'Settings',
    menu: 'Settings',
    save: {
      success:
        'Settings saved successfully. The page will reload in {0} seconds for changes to take effect.',
    },
    fields: {
      theme: 'Theme',
    },
    colors: {
      default: 'Default',
      cyan: 'Cyan',
      'geek-blue': 'Geek Blue',
      gold: 'Gold',
      lime: 'Lime',
      magenta: 'Magenta',
      orange: 'Orange',
      'polar-green': 'Polar Green',
      purple: 'Purple',
      red: 'Red',
      volcano: 'Volcano',
      yellow: 'Yellow',
    },
  },
  home: {
    menu: 'Home',
    activeTrips: 'Active Trips',
    branches: 'Branches',
    transaction: 'Transaction history',
    activeGuides: 'Active tour guides',
    fields: {
      trip: 'Trip',
      plan: 'Plan',
      startCity: 'Start City',
      captain: 'Trip Captain',
      duration: 'Trip Duration',
      paymentMethod: 'Payment method',
      date: 'Date',
      amount: 'Amount',
      branchName: 'Branch Name',
    },
    charts: {
      day: 'Day',
      red: 'Red',
      green: 'Green',
      yellow: 'Yellow',
      grey: 'Grey',
      blue: 'Blue',
      orange: 'Orange',
      months: {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
      },
      eating: 'Eating',
      drinking: 'Drinking',
      sleeping: 'Sleeping',
      designing: 'Designing',
      coding: 'Coding',
      cycling: 'Cycling',
      running: 'Running',
      customer: 'Customer',
    },
  },
  errors: {
    required: 'This field is required',
    emailRequired: 'Email is required',
    passwordRequired: 'Password is required',
    invalidEmail: 'Please enter valid email',

    rolesRequired: 'Roles is Required',
    vehicleTypeRequired: 'Vehicle Type is required',
    vehicleNumberRequired: 'Vehicle Number is required',
    vehicleNumberIsInteger:
      'Vehicle Number should be integer',
    SeatsNumberRequired: 'Seats Number is required',
    SeatsNumberIsInteger: 'Seats Number should be integer',
    vehiclePlateRequired: 'Vehicle Plate is required',
    threeEntriesMustBeText:
      'the first three entries must be text',
    phoneRequired: 'Phone Number is required',
    regionRequired: 'Region is required',
    busRequired: 'Bus is required',
    nameRequired: 'Name is required',
    numOfPointsRequired: 'Number of Points is required',
    numOfTripsRequired: 'Number of Trips is required',
    percentageOfTripsRequired:
      'Percentage of Trips is required',
    maxNumIs_1: 'Please use maximum Number is 1',
    expirationDateRequired: 'Expiration Date is required',
    lngRequired: 'Lng is required',
    latRequired: 'Lat is required',
    lngMustBeNum: 'Lng should be Number only',
    latMustBeNum: 'Lat should be Number only',
    startDateRequired: 'Start Date is required',
    endDateRequired: 'End Date is required',
    publishDateRequired: 'Publish Date is required',

    backToHome: 'Back to home',
    403: `Sorry, you don't have access to this page`,
    404: 'Sorry, the page you visited does not exist',
    500: 'Sorry, the server is reporting an error',
    forbidden: {
      message: 'Forbidden',
    },
    validation: {
      message: 'An error occurred',
    },
    defaultErrorMessage: 'Ops, an error occurred',
  },
  // See https://github.com/jquense/yup#using-a-custom-locale-dictionary
  /* eslint-disable */
  validation: {
    mixed: {
      default: '${path} is invalid',
      required: '${path} is required',
      oneOf:
        '${path} must be one of the following values: ${values}',
      notOneOf:
        '${path} must not be one of the following values: ${values}',
      notType: ({ path, type, value, originalValue }) => {
        return `${path} must be a ${type}`;
      },
    },
    string: {
      length:
        '${path} must be exactly ${length} characters',
      min: '${path} must be at least ${min} characters',
      max: '${path} must be at most ${max} characters',
      matches:
        '${path} must match the following: "${regex}"',
      email: '${path} must be a valid email',
      url: '${path} must be a valid URL',
      trim: '${path} must be a trimmed string',
      lowercase: '${path} must be a lowercase string',
      uppercase: '${path} must be a upper case string',
      selected: '${path} must be selected',
    },
    number: {
      min: '${path} must be greater than or equal to ${min}',
      max: '${path} must be less than or equal to ${max}',
      lessThan: '${path} must be less than ${less}',
      moreThan: '${path} must be greater than ${more}',
      notEqual: '${path} must be not equal to ${notEqual}',
      positive: '${path} must be a positive number',
      negative: '${path} must be a negative number',
      integer: '${path} must be an integer',
      invalid: '${path} must be a number',
    },
    date: {
      min: '${path} field must be later than ${min}',
      max: '${path} field must be at earlier than ${max}',
    },
    boolean: {},
    object: {
      noUnknown:
        '${path} field cannot have keys not specified in the object shape',
    },
    array: {
      min: '${path} field must have at least ${min} items',
      max: '${path} field must have less than or equal to ${max} items',
    },
  },
  /* eslint-disable */
  fileUploader: {
    upload: 'Upload',
    image: 'You must upload an image',
    size: 'File is too big. Max allowed size is {0}',
    formats: `Invalid format. Must be '{0}'.`,
  },
  importer: {
    line: 'Line',
    status: 'Status',
    pending: 'Pending',
    imported: 'Imported',
    error: 'Error',
    total: `{0} imported, {1} pending and {2} with error`,
    importedMessage: `Processed {0} of {1}.`,
    noNavigateAwayMessage:
      'Do not navigate away from this page or import will be stopped.',
    completed: {
      success:
        'Import completed. All rows were successfully imported.',
      someErrors:
        'Processing completed, but some rows were unable to be imported.',
      allErrors: 'Import failed. There are no valid rows.',
    },
    form: {
      downloadTemplate: 'Download the template',
      hint: 'Click or drag the file to this area to continue',
    },
    list: {
      discardConfirm:
        'Are you sure? Non-imported data will be lost.',
    },
    errors: {
      invalidFileEmpty: 'The file is empty',
      invalidFileExcel:
        'Only excel (.xlsx) files are allowed',
      invalidFileUpload:
        'Invalid file. Make sure you are using the last version of the template.',
      importHashRequired: 'Import hash is required',
      importHashExistent: 'Data has already been imported',
    },
  },
  autocomplete: {
    loading: 'Loading...',
  },
  imagesViewer: {
    noImage: 'No image',
  },
  firebaseErrors: {
    'auth/user-disabled': 'Your account is disabled',
    'auth/user-not-found': `Sorry, we don't recognize your credentials`,
    'auth/wrong-password': `Sorry, we don't recognize your credentials`,
    'auth/weak-password': 'This password is too weak',
    'auth/email-already-in-use': 'Email is already in use',
    'auth/invalid-email': 'Please provide a valid email',
    'auth/account-exists-with-different-credential':
      'Email is already in use for a different authentication method.',
    'auth/credential-already-in-use':
      'Credentials are already in use',
  },
};

export default en;
