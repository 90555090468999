import importerStore from '@/shared/importer/importer-store';
import { NotificationService } from '@/modules/notification/notification-service';
import notificationImporterFields from '@/modules/notification/notification-importer-fields';
import { i18n } from '@/i18n';

export default importerStore(
  NotificationService.import,
  notificationImporterFields,
  i18n('entities.notification.importer.fileName'),
  i18n('entities.notification.importer.hint'),
);
