import { PlaceService } from '@/modules/place/place-service';
import Errors from '@/shared/error/errors';
import { routerAsync } from '@/app-module';
import Message from '@/shared/message/message';
import { i18n } from '@/i18n';

export default {
  namespaced: true,

  state: {
    findLoading: false,
    saveLoading: false,
    record: null,
    placeData:{}
  },

  getters: {
    record: (state) => state.record,
    findLoading: (state) => !!state.findLoading,
    saveLoading: (state) => !!state.saveLoading,
    placeData:(state) => state.placeData
  },

  mutations: {
    RESET(state) {
      state.findLoading = false;
      state.saveLoading = false;
      state.record = null;
    },

    LIST_PLACES(state , payload)
    {
      state.placeData = payload
    },

    FIND_STARTED(state) {
      state.record = null;
      state.findLoading = true;
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.findLoading = false;
    },

    FIND_ERROR(state) {
      state.record = null;
      state.findLoading = false;
    },

    CREATE_STARTED(state) {
      state.saveLoading = true;
    },

    CREATE_SUCCESS(state) {
      state.saveLoading = false;
    },

    CREATE_ERROR(state) {
      state.saveLoading = false;
    },

    UPDATE_STARTED(state) {
      state.saveLoading = true;
    },

    UPDATE_SUCCESS(state) {
      state.saveLoading = false;
    },

    UPDATE_ERROR(state) {
      state.saveLoading = false;
    },
  },

  actions: {
    doNew({ commit }) {
      commit('RESET');
    },

    setPlaces({ commit },value ){
      commit('LIST_PLACES',value)
    },

    async doFind({ commit }, id) {
      try {
        commit('FIND_STARTED');

        const record = await PlaceService.find(id);

        commit('FIND_SUCCESS', record);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        routerAsync().push('/place');
      }
    },

    async doCreate({ commit }, values) {
      try {
        commit('CREATE_STARTED');
        const record = await PlaceService.create(values);
        // console.log(record);        
        commit('CREATE_SUCCESS');
        Message.success(i18n('entities.place.create.success'));
        return record
        // routerAsync().push('/place');
        // routerAsync().push(`/place/notification/${response.id}/new`);
      } catch (error) {
        Errors.handle(error);
        commit('CREATE_ERROR');
      }
    },

    async doUpdate({ commit }, { id, values }) {
      try {
        commit('UPDATE_STARTED');
        await PlaceService.update(id, values);
        commit('UPDATE_SUCCESS');
        Message.success(i18n('entities.place.update.success'));
        // routerAsync().push('/place');
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },

    async doCreateOrUpdatePlaceNotification({ commit }, { id, values }) {
      try {
        commit('UPDATE_STARTED');

        await PlaceService.createOrUpdatePlaceNotification(id, values);
        
        commit('UPDATE_SUCCESS');
        Message.success(i18n('entities.notification.create.success'));
        routerAsync().push('/place');
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },
  },
};
