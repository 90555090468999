//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
// import { SettingsPermissions } from '@/modules/settings/settings-permissions';
import { AuditLogPermissions } from '@/modules/audit-log/audit-log-permissions';
import { IamPermissions } from '@/modules/iam/iam-permissions';
import { PlacePermissions } from '@/modules/place/place-permissions';
import { NotificationPermissions } from '@/modules/notification/notification-permissions';
import { PlanPermissions } from '@/modules/plan/plan-permissions';
// import { i18n } from '@/i18n';
import i18n from '@/vueI18n'
import MenuItem from '@/modules/layout/components/menu-item.vue';
export default {
  name: 'app-menu',
  computed: {
    ...mapGetters({
      collapsed: 'layout/menuCollapsed',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
    }),
    // hasPermissionToSettings() {
    //   return new SettingsPermissions(this.currentUser).edit;
    // },
    hasPermissionToAuditLog() {
      return new AuditLogPermissions(this.currentUser).read;
    },
    hasPermissionToIam() {
      return new IamPermissions(this.currentUser).read;
    },
    hasPermissionToPlace() {
      return new PlacePermissions(this.currentUser).read;
    },
    hasPermissionToNotification() {
      return new NotificationPermissions(this.currentUser)
        .read;
    },
    hasPermissionToPlan() {
      return new PlanPermissions(this.currentUser).read;
    },
    asideWidth() {
      if (!this.collapsed) {
        return '200px';
      }
      return '0px';
    },
    lang(){
      return i18n.locale
    },
     isOpened() {
      return (type) => {
        const routePath = this.$route.path;
        const routeName = this.$route.name  
       
        if (type == 'users') {
          const active = 
            routeName === 'admin' ||
            routeName === 'tourGuide'
          return active ? 'users' : null
        } else if (type == 'trips') {
          const active = 
            routeName === 'trip' ||
            routeName === 'plan' ||
            routeName === 'activeTrip'
          return active ? 'trips' : null
        } else if (type == 'places') {
          const active = 
            routePath === '/region' || routePath.startsWith('/region' + '/') ||
            routePath === '/city' || routePath.startsWith('/city' + '/') ||
            routePath === '/add-new-place' || routePath.startsWith('/add-new-place' + '/') ||
            routePath === '/place' || routePath.startsWith('/place' + '/') 
          return active ? 'places' : null
        } else if (type == 'payment') {
          const active = 
            routeName === 'transaction' ||
            routeName === 'wallet' ||
            routeName === 'cycle' || 
            routeName === 'invoice' 
          return active ? 'payment' : null
        }
      }
    },
  },
  components: {
    [MenuItem.name]: MenuItem,
  },
  methods: {
    ...mapActions({
      collapseMenu: 'layout/collapseMenu',
    }),
    // i18n(code) {
    //   return i18n(code);
    // },
    i18n(key, args) {
        return this.$t(key, args);
      },
    collapseMenuIfMobile() {
      if (this.isMobile) {
        this.collapseMenu();
      }
    },
    classFor(path, exact = false) {
      if (exact) {
        return {
          active: this.$route.path === path,
        };
      }
      return {
        active: this.$route.path.startsWith(path),
      };
    },
    goToAddNewPlace() {
      this.$router.push(`/${this.lang}/place/new`)
    }
  },
};
