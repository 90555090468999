//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { i18n } from '@/i18n';

export default {
  name: 'app-layout',
  
  data() {
    return {
      drawer: false,
      left: false,
      miniState: false,
    };
  },
  mounted() {},
  computed: {},
  methods: {
    drawerClick (e) {
      // if in "mini" state and user
      // click on drawer, we switch it to "normal" mode
      if (this.miniState) {
        this.miniState = false
        // notice we have registered an event with capture flag;
        // we need to stop further propagation as this click is
        // intended for switching drawer to "normal" mode only
        e.stopPropagation()
      }
    },
    // i18n for localization
    i18n(code) {
      return i18n(code);
    },
  },
};
